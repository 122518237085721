import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";

const PaaswordInput = ({ label, name, type, placeholder, maxLength, autocomplete, onClick, show }) => {
  const { errors, touched } = useFormikContext();
  return (
    <React.Fragment>
      <div className="ps-form__group form-group">
        {label ? <label className="ps-form__label">{label}</label> : ""}
        <div className="input-group">
          <Field
            className={
              errors.name && touched.name ? "input-error form-control ps-form__input" : "form-control ps-form__input"
            }
            type={type}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            autocomplete={autocomplete ? autocomplete : "off"}
          />
          <div className="input-group-append">
            <a id="passtoogle" className={show ? "toogle-password " : "toogle-password "} onClick={onClick}>
              <i className={show ? "fa fa-eye-slash" : "fa fa-eye"}></i>
            </a>
          </div>
        </div>

        <ErrorMessage name={name} component="span" className="color-red" />
      </div>
    </React.Fragment>
  );
};
export default PaaswordInput;
