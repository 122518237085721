import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import ModuleEcomerceCartItems from "../../components/ecomerce/modules/ModuleEcomerceCartItems";
import ModuleEcomerceCartSummary from "../../components/ecomerce/modules/ModuleEcomerceCartSummary";
import BreadCrumb from "../../components/elements/BreadCrumb";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Container from "../../components/layouts/Container";
import useEcomerce from "../../hooks/useEcomerce";
import { calculateCartQuantity } from "../../utilities/ecomerce-helpers";
import LocalstorageParam from "../common/LocalstorageParam";
import CartParameter from "./CartParameter";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Shop",
    url: "/shop",
  },
  {
    text: "Shopping cart",
  },
];

const ShoppingCart = ({ ecomerce }) => {
  const { loading, cart_list, getCartlist, setCartlist } = useEcomerce();
  const [applyCoupon, setapplyCoupon] = useState("");
  const [proceedFlag, setProceedFlag] = useState(true);
  const [pecialPrice, setSpecialPrice] = useState(0);
  const { currencyrate, getcurrencyRate } = useEcomerce();

  const navigate = useNavigate();
  var cartitems = cart_list;
  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {
    if (!searchparam.urlsameshipping) {
      localStorage.removeItem("billingdata");
    }
    localStorage.removeItem("note");
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getcurrencyRate();
      if (ecomerce.cartItems) {
        getCartlist(ecomerce.cartItems, "cart");
      }
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  useEffect(() => {
    setProceedFlag(true);
    var count = 0;
    if (cartitems && cartitems.length > 0) {
      cartitems.forEach((item) => {
        if (Number(item.stock) < Number(item.quantity)) {
          count = count + 1;
        }
      });
    }
    if (count > 0) {
      setProceedFlag(false);
    }
  }, [cartitems]);

  return (
    <Container title="Shopping Cart">
      <GoogleCaptcha />
      <div className="ps-page ps-page--shopping form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header cart-title-header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">
              Shopping cart
              {cartitems && cartitems.length > 0 && <sup>({calculateCartQuantity(cartitems)})</sup>}
            </h1>
          </div>
          <div className="ps-page__content">
            {cartitems && cartitems.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-8 pt-40">
                    <div className="ps-cart__content mt-20">
                      <div className="ps-cart__items card-box-shadow bn p-20 mb-30 card-mobile-pending">
                        <ModuleEcomerceCartItems
                          cartItems={cartitems}
                          cartevent="shoppingcart"
                          pecialPrice={pecialPrice}
                          handelcoupon={applyCoupon}
                          currencyrate={currencyrate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="ps-cart__order-summary">
                      <ModuleEcomerceCartSummary
                        cartItems={cartitems}
                        cartevent={"cart"}
                        sameshippingdata={searchparam.urlsameshipping}
                        setapplyCoupon={setapplyCoupon}
                        setSpecialPrice={setSpecialPrice}
                        currencyrate={currencyrate}
                        onSetCartlist={setCartlist}
                        proceedFlag={proceedFlag}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {loading ? (
                  <div className="ps-cart--primary">
                    <div className="ps-cart__content">
                      <div className="ps-cart__items">
                        <SkeletonTable rows={2} />
                      </div>
                    </div>
                    <div className="ps-cart__order-summary">
                      <ModuleEcomerceCartSummary
                        cartItems={cart_list}
                        cartevent={"cart"}
                        sameshippingdata={searchparam.urlsameshipping}
                        setapplyCoupon={setapplyCoupon}
                        setSpecialPrice={setSpecialPrice}
                        currencyrate={currencyrate}
                        onSetCartlist={setCartlist}
                      />
                    </div>
                  </div>
                ) : (
                  <NoDataFound component={"cart"} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(ShoppingCart);
