import parse from "html-react-parser";
import React from "react";

const ModuleDetailDescription = ({ product }) => {
  /*
    To replace spacial char from string
  */
  function Description() {
    let html = product.description;
    if (html) {
      html = html.replace("&lt;", "<").replace("&gt;", ">");
    }
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "remove") {
          return <></>;
        }
      },
    };
    return parse(html, options);
  }

  return (
    <div className="ps-product__description ps-document card-box-shadow bn">
      <div className="row">
        <div className="col-12 title-font productdescription word-break-all">
          <Description />
        </div>
      </div>
    </div>
  );
};

export default ModuleDetailDescription;
