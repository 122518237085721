import React from "react";

const Filters = ({ filter, onchangecheck }) => {
  let filtergroup = [];

  /*
    To checked filter after page load
  */
  const isChecked = (id) => {
    if (id) {
      if (document.getElementById("filter_" + id)) {
        document.getElementById("filter_" + id).checked = true;
        document.getElementById("filter_" + id).setAttribute("checked", true);
      }
    }
  };

  return (
    <div>
      {filter.filterlist &&
        filter.filterlist.map((item, index) => (
          <div className="widget__content" key={item.id + `_` + index}>
            <div className="form-group">
              <div className="ps-checkbox">
                <input
                  key={item.id}
                  name={item.id}
                  id={"filter_" + item.id}
                  className="form-control"
                  type="checkbox"
                  value={item.filtername}
                  onChange={(e) => onchangecheck(item.id, e.target.checked)}
                  {...(filtergroup
                    ? filtergroup.map((data) => {
                        isChecked(data);
                      })
                    : "")}
                />
                <label htmlFor={"filter_" + item.id} className="font-size-16 title-font filterlable pt-0">
                  {item.filtername}
                </label>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Filters;
