import React from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UrlProduct } from "../../../pages/product/LoadProduct";
import WidgetShopByRating from "../../shared/widgets/WidgetShopByRating";
import WidgetShopCategories from "../../shared/widgets/WidgetShopCategories";
import WidgetShopFilterByBrands from "../../shared/widgets/WidgetShopFilterByBrands";
import WidgetShopFilterByPriceRange from "../widgets/WidgetShopFilterByPriceRange";

const SidebarShop = ({ filtergroup, currencyrate, category }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let sale = searchParams.get("sale");
  const navigate = useNavigate();

  return (
    <div className="ps-sidebar--shop">
      <WidgetShopCategories category={category} />
      <WidgetShopFilterByPriceRange currencyrate={currencyrate} />
      {filtergroup && <WidgetShopFilterByBrands filtergroup={filtergroup} />}
      <WidgetShopByRating />
      <div className="form-group">
        <div className="ps-checkbox">
          <input
            className="form-control"
            type="checkbox"
            id={"offerproduct"}
            value={sale ? "1" : "0"}
            name={"offerproduct"}
            checked={sale === "1" ? true : false}
            onChange={(e) => {
              if (sale === "1") {
                navigate(UrlProduct(searchParams, "sale") + "&sale=0");
              } else {
                navigate(UrlProduct(searchParams, "sale") + "&sale=1");
              }
              window.scrollTo(0, 0);
            }}
          />
          <label htmlFor={"offerproduct"}>
            <span className="">Show Product with offer</span>
          </label>
        </div>
      </div>
    </div>
  );
};
export default connect((state) => state)(SidebarShop);
