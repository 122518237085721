import React from "react";

const ModuleDetailMaterial = ({ simple = false, array, setname, name, lable }) => {
  /*
    To set varient name
  */
  function handleSetActiveMaterial(payload) {
    setname(payload);
  }

  return (
    <figure className="ps-product__sizes">
      <figcaption className="ps-product__label font-size-16">{lable}:</figcaption>
      <div className="radio_container">
        {array &&
          array.map((item, index) => (
            <>
              <input type="radio" name={name} id={item} value={item} onClick={(e) => handleSetActiveMaterial(item)} />
              <label for={item} className="title-font" key={index}>
                {item}
              </label>
            </>
          ))}
      </div>
    </figure>
  );
};

export default ModuleDetailMaterial;
