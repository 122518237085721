import React from "react";

import NoDataFound from "../../components/common/NoDataFound";
import config from "../../config/config.json";
import OrderListItem from "./OrderListItem";

const OrderItemList = ({ orderItem }) => {
  /*
    To current order list views
  */
  let imagepath = config.apiUrl + config.image_path;
  let items = [];

  if (orderItem && orderItem !== null && orderItem !== "" && orderItem.length > 0) {
    orderItem.map((item) => {
      if (
        item.status === "Placed" ||
        item.status === "Payment Confirmed" ||
        item.status === "Processing" ||
        item.status === "Partially Shipped" ||
        item.status === "Shipped" ||
        item.status === "Canceled" ||
        item.status === "Return Requested" ||
        item.status === "Return approved" ||
        item.status === "Return Rejected" ||
        item.status === "Refund Failed"
      ) {
        items.push(<OrderListItem item={item} />);
      }
    });
  } else {
    items.push(<NoDataFound component={"order"} />);
  }

  return <>{items}</>;
};

export default OrderItemList;
