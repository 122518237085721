import React from "react";

const Security = () => {
  return (
    <div className="ps-page ps-page--product">
      <div className="ps-layout__right p-20 bt-1">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 text-center whyus-card">
              <p className="font-size-30 color-blue">
                <i className="fa fa-cc-mastercard"></i>
              </p>
              <span className="color-blue secure-detail">110% Secure Payments</span>
            </div>
            <div className="col-12 col-md-4 text-center whyus-card">
              <p className="font-size-30 color-blue">
                <i className="fa fa fa-check"></i>
              </p>
              <span className="color-blue secure-detail">premium QUALITY PRODUCTS</span>
            </div>
            <div className="col-12 col-md-4 text-center">
              <p className="font-size-30 color-blue">
                <i className="fa fa-truck "></i>
              </p>
              <span className="color-blue secure-detail">110% Fast Delivery</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Security;
