import { Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import SelectBox from "../../components/elements/form/SelectBox";

const BillingAddress = ({ countrylist, searchparam, localparam, sameasshipping }) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [selectedcountry, setSelectedcountry] = useState();
  const [selectedstate, setSelectedstate] = useState();
  const [selectedcountrycode, setSelectedCountryCode] = useState();
  const [countryname, setCountryname] = useState([]);
  const [statename, setStatename] = useState([]);
  const [state, setState] = useState([]);
  const [iscountrylist, setIsCountryList] = useState(countrylist);
  const [CountryArray, setCountryArray] = useState([]);
  const [StateArray, setStateArray] = useState([]);
  const [CountryCodeArray, setCountryCodeArray] = useState([]);

  var countryarray = [];
  var countrycodearray = [];
  var statearray = [];

  /*
    To initial values json
  */
  var initialValues = {
    id: "",
    billingname: "",
    billingmobilecountry: "",
    billingmobile: "",
    billingcountry: "",
    billingstate: "",
    billingcity: "",
    billingpincode: "",
    billingcompanyname: "",
    billingaddress: "",
    billinggst: "",
    billingcountryname: "",
    billingstatename: "",
  };

  useEffect(() => {
    setIsCountryList(countrylist);
    setCountryListInArray();
  }, [countrylist, iscountrylist]);

  useEffect(() => {
    if (localparam) {
      setBillingForm(localparam);
    }
  }, [localparam, iscountrylist]);

  /*
    To redirect to order summary
  */
  const redirectToOrderSummary = () => {
    navigate("/ordersummary?coupon=" + searchparam.urlcoupon + "&sameshipping=" + sameasshipping);
  };

  /*
    To set billing address data on local storage
  */
  const setBillingdata = (billingdata) => {
    if (billingdata !== null && billingdata !== "") {
      billingdata.billingcountryname = countryname;
      billingdata.billingstatename = statename;
      var object = new Object();
      object["billingdata"] = billingdata;
      localStorage.setItem("billingdata", JSON.stringify(billingdata));
    }
  };

  /*
    To form validation using yup
  */
  const validationSchema = Yup.object().shape({
    billingname: Yup.string()
      .required("Please enter value for Fullname")
      .matches(/^[a-zA-Z '.-]*$/, "Please enter valid Fullname"),
    billingmobile: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid mobile no")
      .required("Please enter value for Mobile No")
      .min(10, "Please enter valid Mobile no"),
    billingmobilecountry: Yup.string().required("Please enter value for Country Code"),
    billingstate: Yup.string().required("Please enter value for State"),
    billingcity: Yup.string().required("Please enter value for City"),
    billingpincode: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid Pincode")
      .min(6, "Please enter valid Pincode")
      .required("Please enter value for Pincode"),
    billingaddress: Yup.string().required("Please enter value for Address"),
    billingcountry: Yup.string().required("Please enter value for Country "),
  });

  /*
    To set in billing form
  */
  const setBillingForm = (params) => {
    if (params) {
      if (params.billingcountry) {
        var list = CountryArray.find((item) => Number(item.value) === Number(params.billingcountry));
        if (list) {
          setSelectedcountry(list);
        }
      }
      if (params.billingmobilecountry) {
        var list = CountryCodeArray.find((item) => Number(item.value) === Number(params.billingmobilecountry));
        if (list) {
          setSelectedCountryCode(list);
        }
      }
      setCountryname(params.billingcountryname);
      setStatename(params.billingstatename);
      getState(params.billingcountry, params);
    }
  };

  /*
    To get state list by countryid
  */
  const getState = async (countryid, billing) => {
    if (countryid && countryid !== null && countryid !== "") {
      setSelectedstate();
      const res = await user.getState(countryid);
      if (res.data.status === 200) {
        setState(res.data.payload.states);
        statearray = [];
        res.data.payload.states.map((item, index) => {
          var stateobject = new Object();
          stateobject["value"] = item.id;
          stateobject["label"] = item.name;
          statearray.push(stateobject);
        });
        setStateArray(statearray);

        if (billing) {
          var list = statearray.find((item) => Number(item.value) === Number(billing.billingstate));
          if (list) {
            setSelectedstate(list);
          }
        } else {
          initialValues.billingstate = "";
          localparam.billingstate = "";
        }
      }
    }
  };

  /*
    To set country in array and object
  */
  const setCountryListInArray = () => {
    if (countrylist && countrylist.length > 0) {
      countryarray = [];
      countrycodearray = [];
      countrylist.map((item) => {
        var countryobject = new Object();
        countryobject["value"] = item.id;
        countryobject["label"] = item.name;
        countryarray.push(countryobject);

        var countrycodeobject = new Object();
        countrycodeobject["value"] = item.extension;
        countrycodeobject["label"] = item.extension;
        countrycodearray.push(countrycodeobject);
      });
      setCountryArray(countryarray);
      setCountryCodeArray(countrycodearray);
    }
  };

  return (
    <FormikFrom
      initialValues={localparam ? localparam : initialValues}
      keys={localparam ? localparam : initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        redirectToOrderSummary();
        setBillingdata(values);
      }}
    >
      <Form>
        <div className="row mt-20">
          <div className="col-12 col-md-6">
            <InputField
              label={"Full Name *"}
              name={"billingname"}
              type={"text"}
              placeholder={"Full Name"}
              maxLength={"50"}
            />
          </div>
          <div className="col-12 col-md-6">
            <SelectBox
              label={"Country *"}
              name={"billingcountry"}
              options={CountryArray}
              placeholder={"Country"}
              setSelectedCountry={setSelectedcountry}
              initialValues={initialValues}
              value={selectedcountry}
              getState={(countryid) => getState(countryid)}
              setCountryname={setCountryname}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <SelectBox
              label={"State *"}
              name={"billingstate"}
              options={StateArray}
              placeholder={"State"}
              setSelectedCountry={setSelectedstate}
              initialValues={initialValues}
              value={selectedstate}
              setStatename={setStatename}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputField label={"City *"} name={"billingcity"} type={"text"} placeholder={"City"} maxLength={"50"} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputField
              label={"Pincode *"}
              name={"billingpincode"}
              type={"text"}
              placeholder={"Pincode"}
              maxLength={"6"}
            />
          </div>
          <div className="col-12 col-md-6">
            <InputField
              label={"Company Name"}
              name={"billingcompanyname"}
              type={"text"}
              placeholder={"Company Name"}
              maxLength={"50"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <InputField
              label={"Address *"}
              name={"billingaddress"}
              placeholder={"Address"}
              maxLength={"500"}
              component="textarea"
              rows="3"
              classname="ps-form__textarea"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-md-5">
                <SelectBox
                  label={"Country *"}
                  name={"billingmobilecountry"}
                  options={CountryCodeArray}
                  placeholder={"Country"}
                  setSelectedCountry={setSelectedCountryCode}
                  initialValues={initialValues}
                  value={selectedcountrycode}
                />
              </div>
              <div className="col-12 col-md-7">
                <InputField
                  label={"Mobile No *"}
                  name={"billingmobile"}
                  type={"text"}
                  placeholder={"Mobile No"}
                  maxLength={"10"}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <InputField label={"GST"} name={"billinggst"} type={"text"} placeholder={"Gst"} maxLength={"50"} />
          </div>
        </div>
        <span className="color-red hide pl-15 pt-10" id="errormsg"></span>
        <button className={"ps-btn ps-btn--warning width-100 hidden"} id="savebillingbtn" ref={ref}>
          Submit
        </button>
      </Form>
    </FormikFrom>
  );
};

export default connect((state) => state)(BillingAddress);
