import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import ModuleEcomerceCartItems from "../../components/ecomerce/modules/ModuleEcomerceCartItems";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import useEcomerce from "../../hooks/useEcomerce";
import { calculateCartQuantity } from "../../utilities/ecomerce-helpers";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "My Account",
    url: "/profile",
  },
  {
    text: "My cart",
  },
];

const MyCart = ({ ecomerce }) => {
  const { loading, cart_list, getCartlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();

  const navigate = useNavigate();
  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getcurrencyRate();
      if (ecomerce.cartItems) {
        getCartlist(ecomerce.cartItems, "cart");
      }
      window.scrollTo(0, 0);
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  /*
    To set cart list
  */
  let cartContentView, totalView;
  if (cart_list && cart_list.length > 0) {
    totalView = calculateCartQuantity(cart_list);
    cartContentView = (
      <div className="ps-cart__content ">
        <div className="ps-cart__items pt-30">
          <ModuleEcomerceCartItems cartItems={cart_list} cartevent={"mycart"} currencyrate={currencyrate} />
        </div>
      </div>
    );
  } else {
    if (loading) {
      cartContentView = (
        <div className="ps-cart__content">
          <div className="ps-cart__items  pt-30">
            <SkeletonTable rows={2} />
          </div>
        </div>
      );
    } else {
      cartContentView = <NoDataFound component={"cart"} />;
    }
  }

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Cart"} />
                </div>
                <div className="ps-layout__right">
                  <div className="row">
                    <div className="col-12 col-md-12 ps-form--review">
                      <h2 className="ps-form__title mb-20">My Cart</h2>
                      <div className="box-rounded width-100">{cartContentView}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(MyCart);
