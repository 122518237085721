import React from "react";

import NoDataFound from "../../components/common/NoDataFound";
import OrderListItem from "./OrderListItem";

const PastOrderItemList = ({ orderItem }) => {
  /*
    To past order list views
  */

  let items = [];

  if (orderItem && orderItem !== null && orderItem !== "" && orderItem.length > 0) {
    orderItem.map((item) => {
      if (item.status === "Completed" || item.status === "Refunded") {
        items.push(<OrderListItem item={item} />);
      }
    });
  } else {
    items.push(<NoDataFound component={"order"} />);
  }

  return <>{items}</>;
};

export default PastOrderItemList;
