import React from "react";

import shopcofig from "../../../../config/shopconfig.json";

const ModuleFooterContact = (classname) => {
  if (classname) {
    classname = classname.classname;
  }

  return (
    <div className="ps-footer--contact">
      {classname ? "" : <h5 className="ps-footer__title">Customer Care</h5>}

      <div className="ps-footer__fax">
        <i className="icon-telephone"></i>
        <a href="tel:" {...shopcofig.shop_contactno}>
          {shopcofig.shop_contactno}
        </a>
      </div>
      <p className="ps-footer__work footer-font">
        Monday – Saturday: 10:00 AM - 06:00 PM
        <br />
      </p>
      <hr />
      <p>
        <a
          className={
            classname && classname !== null && classname !== "" ? "ps-footer__email title-font" : "ps-footer__email"
          }
          href="mailto:info@baijay.com"
        >
          <i className="icon-envelope"></i>
          {shopcofig.email}
        </a>
      </p>
    </div>
  );
};

export default ModuleFooterContact;
