import { Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import order from "../../api/order";
import NoDataFound from "../../components/common/NoDataFound";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import OrderHistoryStatus from "./OrderHistoryStatus";

const TrackMyOrder = () => {
  const [disable, handelDisable] = useState(false);
  const [history, handelhistory] = useState(false);
  const [orderhistory, setOrderHistory] = useState([]);
  const [ordershipment, setOrderShipment] = useState([]);
  useEffect(() => {
    handelDisable(true);
    setTimeout(() => {
      handelDisable(false);
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Track My Order",
    },
  ];

  /*
    To initial values json
  */
  const initialValues = {
    orderno: "",
    customerphoneno: "",
  };

  /*
    To form validation
  */
  const validationSchema = Yup.object().shape({
    orderno: Yup.string()
      .required("Please enter value for Order No")
      .matches(/^[0-9]+$/, "Please enter valid Order No"),
    customerphoneno: Yup.string()
      .required("Please enter value for Mobile No")
      .matches(/^[0-9]+$/, "Please enter valid mobile No")
      .min(10, "Please enter valid Mobile No"),
  });

  /*
    To show order history and track order status history by order id & mobile no for track order
  */
  const trackOrder = async (data) => {
    handelhistory(true);
    const responseData = await order.getTrackOrderHistory(data);
    if (responseData.data.status === 200) {
      setOrderShipment(responseData.data.payload.shippingdata);
      setOrderHistory(responseData.data.payload.orderhistorylist);
    }
  };

  var shipmentid = "";
  var i = 1;
  let items = [];
  let orderdetail = [];
  if (ordershipment && ordershipment.length > 0) {
    for (var ordershippment of ordershipment) {
      if (shipmentid !== ordershippment.id) {
        shipmentid = ordershippment.id;
        items.push(
          <a
            href={
              ordershippment.trackingurl && ordershippment.trackingid
                ? ordershippment.trackingurl + ordershippment.trackingid
                : "javascript:;"
            }
            className="ps-btn ps-btn--warning mr-10 mb-10"
            target="_blank"
          >
            Track Shipment {ordershipment.length > 1 && i}
          </a>
        );
        i++;
      }
    }
  }
  if (orderhistory && orderhistory.length > 0) {
    var object = new Object();
    object["orderhistorylist"] = orderhistory;

    orderdetail.push(object);
  }

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="row ps-form--review mb-30">
                <div className="col-12 col-md-12">
                  <h2 className="ps-form__title text-center">Track My Order</h2>
                </div>
              </div>
              <div className="ps-layout--with-sidebar track-order">
                <div className="ps-layout__right width-100">
                  <div className="row">
                    <div className="col-12 col-md-12 ps-form--review">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items">
                          <div className="box-rounded width-100 p-20">
                            <FormikFrom
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={(values) => {
                                trackOrder(values);
                              }}
                            >
                              <Form>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <InputField
                                      label={"Order No *"}
                                      name={"orderno"}
                                      type={"text"}
                                      placeholder={"Order No"}
                                      maxLength={"50"}
                                    />
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <InputField
                                      label={"Mobile No *"}
                                      name={"customerphoneno"}
                                      type={"text"}
                                      placeholder={"Mobile No"}
                                      maxLength={"10"}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <button
                                      className={
                                        disable
                                          ? "disabled disable-btn ps-btn ps-btn--warning max-width-245 "
                                          : "ps-btn ps-btn--warning max-width-245 "
                                      }
                                    >
                                      Track
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </FormikFrom>
                          </div>
                          <div
                            className={
                              history ? "box-rounded width-100 p-20 mt-30" : "box-rounded width-100 p-20 mt-30 hide"
                            }
                          >
                            <div className="row">
                              <div className="col-12 mb-30">
                                <h2 className="ps-form__title">Status</h2>
                              </div>
                              <div className="col-12 mb-20">{items}</div>
                              <div className="col-12">
                                {orderdetail && orderdetail.length > 0 ? (
                                  <ul className="timeline">
                                    <OrderHistoryStatus orderdetail={orderdetail} />
                                  </ul>
                                ) : (
                                  <NoDataFound component={"Order History"} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default TrackMyOrder;
