import { Modal } from "antd";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import auth from "../api/auth";
import cart from "../api/cart";
import currency from "../api/currency";
import order from "../api/order";
import user from "../api/user";
import wishlist from "../api/wishlist";
import LocalstorageParam from "../pages/common/LocalstorageParam";
import { toggleDrawer } from "../store/app/action";
import { setCartItems, setWishlistTtems } from "../store/ecomerce/action";
import useLocalStorage from "./useLocalStorage";

export default function useEcomerce() {
  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const navigate = useNavigate();
  const { blankLocalStorage } = useLocalStorage();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [cartItemsOnCookie] = useState(null);
  const [cookies, setCookie] = useCookies(["cart"]);
  const [products, setProducts] = useState([]);
  const [wish_list, setWishlist] = useState([]);
  const [cart_list, setCartlist] = useState([]);
  const [wishlistandcartCount, setWishlistAndCartCount] = useState([]);
  const [countrylist, setCountryList] = useState([]);
  const [PaymentGateway, setPaymentGateway] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  const [orderdetail, setOrderDetail] = useState([]);
  const [ordershippment, setOrderShippment] = useState([]);
  const [returnproduct, setReturnProduct] = useState();
  const [returnproductlist, setReturnProductList] = useState();
  const [currencyrate, setCurrencyrate] = useState();

  return {
    loading,
    cartItemsOnCookie,
    products,
    wish_list,
    cart_list,
    wishlistandcartCount,
    countrylist,
    PaymentGateway,
    orderlist,
    orderdetail,
    ordershippment,
    returnproduct,
    returnproductlist,
    currencyrate,
    setCartlist,

    /*
      To get wishlist and cart count
    */
    getWishlistAndCartCount: async () => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await cart.getWishlistAndCartCount();
        if (responseData.data.status === 200) {
          setWishlistAndCartCount(responseData.data.payload);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setWishlistAndCartCount([]);
        }
      } else {
        setLoading(false);
        setWishlistAndCartCount([]);
      }
    },
    /*
      To get wishlist data
    */
    getWishlist: async () => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await wishlist.getWishlist();
        if (responseData.data.status === 200) {
          setWishlist(responseData.data.payload.data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setWishlist([]);
        }
      } else {
        setLoading(false);
        setWishlist([]);
      }
    },
    /*
      To get cart data
    */
    getCartlist: async (payload, group = "") => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await cart.getCartlist();
        if (responseData.data.status === 200) {
          setCartlist(responseData.data.payload.data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    /*
      To get product data
    */
    getProducts: async (payload, group = "") => {
      setLoading(true);
      if (payload && payload.length > 0) {
        let queries = "";
        payload.forEach((item) => {
          if (queries === "") {
            queries = `id_in=${item.id}`;
          } else {
            queries = queries + `&id_in=${item.id}`;
          }
        });

        // const responseData = await ProductRepository.getProductsByIds(queries);
        // if (responseData && responseData.length > 0) {
        //   if (group === "cart") {
        //     let cartItems = responseData;
        //     payload.forEach((item, index) => {
        //       if (item.id === cartItems[index].id) {
        //         cartItems[index].quantity = item.quantity;
        //       }
        //     });
        //     setProducts(cartItems);
        //   } else {
        //     setProducts(responseData);
        //   }
        //   setTimeout(
        //     function () {
        //       setLoading(false);
        //     }.bind(this),
        //     250
        //   );
        // }
        setProducts([]);
      } else {
        setLoading(false);
        setProducts([]);
      }
    },
    /*
      To increse product quantity
    */
    increaseQty: async (payload, currentCart) => {
      let cartdata = [];
      if (currentCart) {
        cartdata = currentCart;
        var i = 0;
        let totalstock = 0;
        cartdata.forEach(async (item) => {
          if (Number(item.id) === Number(payload.id)) {
            totalstock = Number(item.quantity) + 1;
            item.quantity = totalstock;
            cartdata[i].stockevent = "";
            var data = new Object();
            data["id"] = item.id;
            data["productid"] = item.productid;
            if (item.productvariantdetailid) {
              data["productvariantid"] = item.productvariantdetailid;
            }
            data["quantity"] = item.quantity;
            const res = await cart.updateCart(data);
            if (res.data.status === 200) {
              if (Number(res.data.payload) === 0) {
                cartdata[i].stockevent = "outofstock";
              }
            } else {
              const modal = Modal.error({
                centered: true,
                title: "Error!",
                content: `Error while updating quantity`,
              });
            }
            dispatch(setCartItems(currentCart));
          }
          i++;
        });
      }

      return cartdata;
    },
    /*
      To descrease product quantity
    */
    decreaseQty: async (payload, currentCart) => {
      let cartdata = [];
      if (currentCart) {
        cartdata = currentCart;
        var i = 0;
        let totalstock = 0;
        cartdata.forEach(async (item) => {
          if (Number(item.id) === Number(payload.id)) {
            totalstock = Number(item.quantity) - 1;
            if (totalstock >= 1) {
              item.quantity = totalstock;
            }
            cartdata[i].stockevent = "";
            var data = new Object();
            data["id"] = item.id;
            data["productid"] = item.productid;
            if (item.productvariantdetailid) {
              data["productvariantid"] = item.productvariantdetailid;
            }
            data["quantity"] = item.quantity;
            const res = await cart.updateCart(data);
            if (res.data.status === 200) {
              if (Number(res.data.payload) === 0) {
                cartdata[i].stockevent = "outofstock";
              }
            } else {
              const modal = Modal.error({
                centered: true,
                title: "Error!",
                content: `Error while updating quantity`,
              });
            }

            setCookie("cart", cartdata, { path: "/" });
            dispatch(setCartItems(currentCart));
          }
          i++;
        });
      }
      return cartdata;
    },
    /*
      To add to cart or wishlist
    */
    addItem: async (newItem, items, group, product) => {
      let newItems = [];
      /*
        To add to cart
      */
      if (group === "cart") {
        if (items.length > 0) {
          newItems = items;
          const existItem = items.find((item) => {
            if (
              Number(item.productid) === Number(newItem.productid) &&
              item.productvariantid === newItem.productvariantid
            ) {
              return true;
            }
          });
          if (existItem) {
            if (group === "cart") {
              existItem.quantity += newItem.quantity;
            }
          } else {
            newItems.push(newItem);
          }
        } else {
          newItems.push(newItem);
        }

        const res = await cart.addToCart(newItem);
        if (res.data.status === 200) {
          setCookie("cart", newItems, { path: "/" });

          dispatch(setCartItems(newItems));
          dispatch(toggleDrawer(true));
        } else {
          const modal = Modal.error({
            centered: true,
            title: "Error!",
            content: `Error while adding cart`,
          });
        }
      }
      /*
        To add to wishlist
      */
      if (group === "wishlist") {
        if (items.length > 0) {
          newItems = items;
          const existItem = items.find((item) => {
            if (Number(item.id) === Number(newItem.id) && item.productvariantid === newItem.productvariantid) {
              return true;
            }
          });
          if (existItem) {
            if (group === "cart") {
              existItem.quantity += newItem.quantity;
            }
          } else {
            newItems.push(newItem);
          }
        } else {
          newItems.push(newItem);
        }
        const res = await wishlist.addWishlist(newItem);
        if (res.data.status === 200) {
          setCookie("wishlist", newItems, { path: "/" });
          dispatch(setWishlistTtems(newItems));
          const modal = Modal.success({
            centered: true,
            title: "Success!",
            content: `This item has been added to your wishlist`,
          });
        } else {
          const modal = Modal.error({
            centered: true,
            title: "Error!",
            content: `Error while adding wishlist`,
          });
        }
      }
      return newItems;
    },
    /*
      To add to wishlist from product list page    
    */
    addWishlistItem: async (newItem, items, group) => {
      let newItems = [];
      var wishlistid = 0;

      const res = await wishlist.addWishlist(newItem);
      if (res.data.status === 200) {
        wishlistid = res.data.payload;
        setCookie("wishlist", newItems, { path: "/" });
        dispatch(setWishlistTtems(newItems));
        const modal = Modal.success({
          centered: true,
          title: "Success!",
          content: `This item has been added to your wishlist`,
        });
      } else {
        const modal = Modal.error({
          centered: true,
          title: "Error!",
          content: `Error while adding wishlist`,
        });
      }
      return wishlistid;
    },
    /*
      To remove  item from cart or wishlist
    */
    removeItem: async (selectedItem, items, group) => {
      /*
        To remove item from cart
      */
      let currentItems = "";
      if (items && items.length > 0) {
        let currentItems = items;
        if (currentItems.length > 0) {
          const index = currentItems.findIndex((item) => item.id === selectedItem.id);
          currentItems.splice(index, 1);
        }
      }
      if (group === "cart") {
        const res = await cart.removeCart(selectedItem);
        if (res.data.status === 200) {
          setCookie("cart", items, { path: "/" });
          dispatch(setCartItems(items));
        } else {
          const modal = Modal.error({
            centered: true,
            title: "Error!",
            content: `Error while deleting cart`,
          });
        }
      }

      /*
        To remove item from wishlist
      */
      if (group === "wishlist") {
        const res = await wishlist.removeWishlist(selectedItem);
        if (res.data.status === 200) {
          setCookie("wishlist", currentItems, { path: "/" });
          dispatch(setWishlistTtems(currentItems));
          const modal = Modal.success({
            centered: true,
            title: "Success!",
            content: `This item has been removed from your wishlist`,
          });
        } else {
          const modal = Modal.error({
            centered: true,
            title: "Error!",
            content: `Error while deleting wishlist`,
          });
        }
      }
    },
    /*
      To remove all item from cart or wishlist
    */
    removeItems: async (group) => {
      if (group === "wishlist") {
        setCookie("wishlist", [], { path: "/" });
        dispatch(setWishlistTtems([]));
      }
      if (group === "cart") {
        const res = await cart.removeAllCart();
        if (res.data.status === 200) {
          setCookie("cart", [], { path: "/" });
          dispatch(setCartItems([]));
        } else {
          const modal = Modal.error({
            centered: true,
            title: "Error!",
            content: `Error while deleting all cart`,
          });
        }
      }
    },
    /*
      To get country list
    */
    getCountryList: async () => {
      setLoading(true);
      const responseData = await user.getCountry();
      if (responseData.data.status === 200) {
        setCountryList(responseData.data.payload.data);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      } else {
        setLoading(false);
        setCountryList([]);
      }
    },
    /*
      To check token expire or not 
    */
    ckeckLoginToken: async () => {
      const expires_in = localStorage.getItem("expires_in");
      const login_date = localStorage.getItem("logindate");
      if (login_date) {
        var currentdate = new Date();
        var token_date = new Date(login_date);
        var secoundBetween = Math.abs(currentdate - token_date) / 1000;

        if (Number(expires_in) < Number(secoundBetween)) {
          const res = await auth.refreshtoken();
          if (res.status === 200) {
            localStorage.setItem("authToken", res.data.access_token);
            localStorage.setItem("logindate", new Date());
            localStorage.setItem("expires_in", res.data.expires_in);
            localStorage.setItem("refresh_token", res.data.refresh_token);
          } else {
            if (res.data.errors && res.data.errors.length > 0) {
              var error = res.data.errors[0];
              if (error === "invalid_request") {
                blankLocalStorage();
                navigate("/login");
              }
            }
          }
        }
      }
    },
    /*
      To get payment gateway list
    */
    getpaymentgateway: async () => {
      setLoading(true);
      const responseData = await cart.getPaymentGatewayList();
      if (responseData.data.status === 200) {
        setPaymentGateway(responseData.data.payload.data[0]);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      } else {
        setLoading(false);
        setPaymentGateway([]);
      }
    },
    /*
      To get order list
    */
    getOrderlist: async () => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await order.getOrderlist();
        if (responseData.data.status === 200) {
          setOrderlist(responseData.data.payload.data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setOrderlist([]);
        }
      } else {
        setLoading(false);
        setOrderlist([]);
      }
    },
    /*
      To get order detail data
    */
    getOrderDetail: async (orderid) => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await order.getOrderDetail(orderid);

        if (responseData.data.status === 200) {
          setOrderDetail(responseData.data.payload.orderdetail.data);
          setReturnProductList(responseData.data.payload.orderreturndetail.data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setOrderDetail();
          setReturnProductList("");
        }
      } else {
        setLoading(false);
        setOrderDetail();
        setReturnProductList("");
      }
    },
    /*
      To get order shippment list
    */
    getOrderShippment: async (orderid) => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await order.getOrderShippment(orderid);
        if (responseData.data.status === 200) {
          setOrderShippment(responseData.data.payload);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setOrderShippment([]);
        }
      } else {
        setLoading(false);
        setOrderShippment([]);
      }
    },
    /*
      To get order return product list
    */
    orderReturnProduct: async (orderid) => {
      setLoading(true);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.userid !== null &&
        localparam.decoded.userid !== ""
      ) {
        const responseData = await order.orderReturnProduct(orderid);
        if (responseData.data.status === 200) {
          setReturnProduct(responseData.data.payload);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        } else {
          setLoading(false);
          setReturnProduct([]);
        }
      } else {
        setLoading(false);
        setReturnProduct([]);
      }
    },
    /*
      To get currency list
    */
    getcurrencyRate: async () => {
      setLoading(true);
      var currencyid = "1";
      if (localStorage.getItem("currencyid")) {
        currencyid = localStorage.getItem("currencyid");
      }
      const res = await currency.getCurrencyDetailByID(currencyid);
      if (res.data.status === 200) {
        setCurrencyrate(res.data.payload.data[0]);
        setTimeout(
          function () {
            setLoading(false);
          }.bind(this),
          250
        );
      } else {
        setLoading(false);
        setCurrencyrate([]);
      }
    },
  };
}
