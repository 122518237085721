import { Modal } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import DetailQuickView from "../../../../components/elements/detail/DetailQuickView";
import useEcomerce from "../../../../hooks/useEcomerce";
import LocalstorageParam from "../../../../pages/common/LocalstorageParam";

const ModuleProductActions = ({ product, type = "vertical", ecomerce }) => {
  const [isQuickView, setIsQuickView] = useState(false);
  const { addWishlistItem, removeItem } = useEcomerce();
  const navigate = useNavigate();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To add to wishlist 
  */
  async function handleAddItemToWishlist(e) {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      var wishlistid = await addWishlistItem(
        {
          productid: product.id,
          productvariantid: "0",
        },
        ecomerce.wishlistItems,
        "wishlist"
      );
      product.alreadyinwishlist = 1;
      if (Number(wishlistid) > 0) {
        product.wishlistid = wishlistid;
      }
    } else {
      navigate("/login");
    }
  }

  /*
    To remove wishlist item
  */
  function handleRemoveItem(e, wishlistid) {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      removeItem({ wishlistid: wishlistid }, ecomerce.wishlistItems, "wishlist");
      product.alreadyinwishlist = "0";
    } else {
      navigate("/login");
    }
  }
  /*
    To close model
  */
  function handleHideQuickView(e) {
    e.preventDefault();
    setIsQuickView(false);
  }

  /*
    To view 
  */
  if (type === "vertical") {
    return (
      <>
        <div className="ps-product__actions">
          {product.alreadyinwishlist === null || product.alreadyinwishlist == "0" ? (
            <a href="#" className="ps-product__action" title="Wishlist" onClick={(e) => handleAddItemToWishlist(e)}>
              <i className="fa fa-heart-o pt-5"></i>
            </a>
          ) : (
            <a
              className="ps-product__action background-color-orange-new color-white"
              title="Wishlist"
              onClick={(e) => handleRemoveItem(e, product.wishlistid)}
            >
              <i className="fa fa-heart-o pt-5"></i>
            </a>
          )}
        </div>
        <Modal
          centered
          footer={null}
          width={1024}
          onCancel={(e) => handleHideQuickView(e)}
          visible={isQuickView}
          zIndex={9999}
          closeIcon={<i className="icon icon-cross2"></i>}
        >
          <DetailQuickView product={product} />
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <div className="ps-product__actions-horizontal">
          <div className="ps-product__other-actions">
            <a onClick={(e) => handleAddItemToWishlist(e)}>
              <i className="icon-heart"></i>
            </a>
            <a onClick={(e) => handleAddItemToWishlist(e)}>
              <i className="icon-signal"></i>
            </a>
          </div>
        </div>
      </>
    );
  }
};
export default connect((state) => state)(ModuleProductActions);
