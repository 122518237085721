import React from "react";
import { Rating } from "react-simple-star-rating";

const ModuleDetailTopInformation = ({ product, productReview }) => {
  /*
    To redirect to review
  */
  const redirectToReview = () => {
    if (document.getElementById("productreview-tab-2")) {
      document.getElementById("productreview-tab-2").click();
      document.getElementById("productreview-tab-2").focus();
    }
  };

  return (
    <header className="ps-product__top-info">
      <h1 className="ps-product__title ">{product.productname}</h1>
      <div className="ps-product__rating">
        <Rating allowFraction initialValue={product.rating} readonly size={15} />
        <span className="ml-20">
          <a onClick={redirectToReview}>
            (
            {productReview && productReview.productreviewlist && productReview.productreviewlist.length > 0
              ? productReview.productreviewlist.length
              : "0 "}
            {" review"})
          </a>
        </span>
      </div>
    </header>
  );
};

export default ModuleDetailTopInformation;
