import React from "react";
import { useNavigate } from "react-router-dom";

const MobileSubMenuDropdown = ({ source, setIsMenu }) => {
  const navigate = useNavigate();
  /*
    To close menu
  */
  const redirectPage = (link) => {
    navigate(link);
    setIsMenu(false);
  };

  /*
    To submenu view
  */

  let submenu = [];
  if (source.subcategories) {
    source.subcategories.map((subitem) => {
      submenu.push(
        <li key={subitem.categoryname}>
          <a onClick={(e) => redirectPage(subitem.url)}>
            <span className="menu__text mt-10 mb-10 title-font font-size-20">{subitem.categoryname}</span>
          </a>
        </li>
      );
    });
  }

  return <>{submenu}</>;
};

export default MobileSubMenuDropdown;
