import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import Order from "../../api/order";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import SelectText from "../../components/elements/form/SelectText";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";
import OrderReturnProductList from "./OrderReturnProductList";

const OrderReturn = () => {
  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "My Account",
      url: "/profile",
    },
    {
      id: 3,
      text: "Order",
      url: "/order",
    },
    {
      id: 4,
      text: "Order Detail",
      url: "/order",
    },
    {
      id: 5,
      text: "Return Order",
    },
  ];

  const [disable, handelDisable] = useState(false);
  const [isallChecked, setIsAllChecked] = useState(false);
  const [files, setFiles] = useState([]);
  const [filenames, setFilesnames] = useState([]);

  const { returnproduct, orderReturnProduct } = useEcomerce();
  const { loading, orderdetail, getOrderDetail } = useEcomerce();

  const navigate = useNavigate();

  const { id } = useParams();

  let isflag = false;
  let returnitemlist = "";
  let resonlist = [];

  const formData = new Object();

  /*
    To initial values json
  */
  const initialValues = {
    reason: "",
    description: "",
  };

  /*
    To form validation
  */
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Please enter value for Reason"),
    description: Yup.string().required("Please enter value for Description"),
  });

  useEffect(() => {
    handelDisable(true);
    setTimeout(() => {
      handelDisable(false);
      if (
        localparam !== "" &&
        localparam.decoded !== "" &&
        localparam.decoded.username !== null &&
        localparam.decoded.username !== ""
      ) {
        getOrderDetail(id);
        orderReturnProduct(id);
        window.scrollTo(0, 0);
      } else {
        navigate("/login");
      }
    }, 500);
  }, []);

  /*
    To breadcrumb edit 
  */
  if (id) {
    breadcrumb[3].url = "/order/" + id;
  }

  /*
    To currency set in variable
  */
  let currencyrate = "";
  if (orderdetail && orderdetail.length > 0) {
    currencyrate = orderdetail[0].currencyForm;
  }

  /*
   To view return data set in variable
  */
  if (returnproduct) {
    returnitemlist = (
      <table className="table ps-table ps-table--shopping-cart">
        <thead>
          <tr>
            <th className="text-left">
              <div className="ps-form__group form-group ">
                <div className="form-check ml-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`return`}
                    name={`return`}
                    onChange={(e) => allisChecked(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor={`return`}></label>
                </div>
              </div>
            </th>
            <th className="text-left width-50-per">Product</th>
            <th className="text-center">Qty</th>
            <th className="text-center">Price</th>
            <th className="text-center">Return Qty</th>
          </tr>
        </thead>
        <tbody className="bn">
          <OrderReturnProductList
            orderdetail={returnproduct.orderproducts}
            isallChecked={isallChecked}
            handelDisable={handelDisable}
            currencyrate={currencyrate}
          />
        </tbody>
      </table>
    );

    /*
      To set reson list in array
    */
    if (returnproduct.reasons) {
      for (var reason of returnproduct.reasons) {
        resonlist.push(<option value={reason}>{reason}</option>);
      }
    }
  }

  let productsData = [];
  /*
    To set all check product
  */
  const allisChecked = (isallcheck) => {
    if (isallcheck) {
      setIsAllChecked(true);
      returnproduct.orderproducts.map((item) => {
        item.isproductcheck = 1;
      });
      document.getElementById("errormsg").classList.add("hide");
      document.getElementById("errormsg").innerHTML = "";
    } else {
      setIsAllChecked(false);
      returnproduct.orderproducts.map((item) => {
        item.isproductcheck = 0;
      });
    }
  };

  /*
    To file picker initialize
  */
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file, index) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          setFiles((prevState) => [...prevState, { id: index, src: e.target.result }]);
        };
        reader.readAsDataURL(file);

        return file;
      });
      var filenam = [...filenames];
      var req;
      acceptedFiles.forEach(async (file, index) => {
        req = await Order.uploadOrderReturnImage(file);
        if (req.data.status === 200) {
          filenam.push(req.data.payload.filename[0]);
        }
      });

      setFilesnames(filenam);
    },
  });

  let thumbs = "";
  thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.src}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.src);
          }}
        />
        <a className="image-delete" onClick={() => remove(index)}>
          <i className="fa fa-times color-red"></i>
        </a>
      </div>
    </div>
  ));

  /*
    To remove selected file
  */
  const remove = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);

    filenames.splice(file, 1);
    setFilesnames(filenames);
  };

  /*
    To save order return
  */
  const saveOrderReturn = async (data) => {
    handelDisable(true);
    if (orderdetail && orderdetail.length > 0) {
      if (returnproduct) {
        setProductlist();
      }
      formData["orderid"] = orderdetail[0].id;
      formData["ordersid"] = orderdetail[0].orderid;

      formData["refundgateway"] = orderdetail[0].paymentmode;
      formData["status"] = "Return Requested";
      formData["returnImageName"] = filenames;
    }
    formData["reason"] = data.reason;
    formData["description"] = data.description;

    if (isflag) {
      const res = await Order.saveOrderReturn(formData);
      if (res.data.status === 200) {
        handelDisable(false);
        window.scrollTo(0, 0);
        document.getElementById("returnmsg").classList.remove("hide");
        document.getElementById("returndata").classList.add("hide");
      } else {
        handelDisable(false);
        document.getElementById("errormsg").classList.remove("hide");
        document.getElementById("errormsg").innerHTML = res.data.errors.message;
      }
    } else {
      handelDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = "Please select minimum 1 product";
    }
  };

  /*
    To set product json
  */
  const setProductlist = () => {
    var total = 0;
    returnproduct.orderproducts.map((items) => {
      const orderProductsData = new Object();
      orderProductsData["productid"] = items.productid;
      if (items.isproductcheck === 1) {
        orderProductsData["isproductcheck"] = items.isproductcheck;
      } else {
        orderProductsData["isproductcheck"] = 0;
      }

      orderProductsData["productvariantid"] = items.productvariantdetailid;
      orderProductsData["producttitle"] = items.producttitle;
      orderProductsData["quantity"] = items.quantity;
      if (items.returnedquantity) {
        orderProductsData["returnedquantity"] = items.returnedquantity;
        if (Number(items.orderofferprice) > 0) {
          orderProductsData["total"] = Number(items.orderofferprice) * Number(items.returnedquantity);
        } else {
          orderProductsData["total"] = Number(items.price) * Number(items.returnedquantity);
        }
      } else {
        orderProductsData["returnedquantity"] = items.quantity;
        if (Number(items.orderofferprice) > 0) {
          orderProductsData["total"] = Number(items.orderofferprice) * Number(items.quantity);
        } else {
          orderProductsData["total"] = Number(items.price) * Number(items.quantity);
        }
      }
      orderProductsData["price"] = items.price;
      orderProductsData["offerprice"] = items.orderofferprice;
      orderProductsData["discount"] = items.discount;
      if (items.isproductcheck === 1) {
        total = Number(total) + Number(orderProductsData.total);
      }
      if (!isflag && Number(items.isproductcheck) === 1) {
        isflag = true;
      }
      productsData.push(orderProductsData);
    });
    formData["returnDetailForm"] = productsData;
    formData["refundamount"] = total;
  };

  return (
    <Container>
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Orders"} />
                </div>
                <div className="ps-layout__right width-100">
                  <div className="row">
                    <div className="col-12 col-md-12 ps-form--review">
                      <div className="ps-cart__content">
                        <div className="ps-cart__items">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <h2 className="ps-form__title">
                                Order Number #{" "}
                                {orderdetail && orderdetail.length > 0 && orderdetail[0].orderno
                                  ? orderdetail[0].orderno
                                  : "-"}
                                {" > "}
                                Return Order
                              </h2>
                            </div>
                            <div className="col-12 col-md-6 detail-btn pb-20">
                              <a href={"/order/" + id} title="Back" className={"warningbtn ml-10"}>
                                Back
                              </a>
                            </div>
                          </div>
                          <div className="box-rounded width-100 p-20">
                            <div id="returndata">
                              <FormikFrom
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                  saveOrderReturn(values);
                                }}>
                                <Form>
                                  {returnitemlist}
                                  <span className="color-red hide pt-10" id="errormsg"></span>
                                  <div className="row">
                                    <div className="col-12 col-md-8 mb-20">
                                      <label className="ps-form__label">Images</label>
                                      <div className="row">
                                        <section className="container">
                                          <div
                                            {...getRootProps({
                                              className: "dropzone",
                                            })}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                          </div>
                                          {files && files.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
                                        </section>
                                      </div>
                                      <p className="color-red hide pt-10" id="imageerrormsg"></p>
                                    </div>

                                    <div className="col-12 col-md-8">
                                      <SelectText name="reason" list={resonlist} label={"Reason *"} />
                                    </div>
                                    <div className="col-12 col-md-8 pt-20">
                                      <InputField
                                        label={"Description *"}
                                        name={"description"}
                                        placeholder={"Description"}
                                        maxLength={"500"}
                                        component="textarea"
                                        rows="4"
                                        classname="ps-form__textarea"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-12">
                                      <button
                                        className={
                                          disable
                                            ? "disabled disable-btn ps-btn ps-btn--warning max-width-245 "
                                            : "ps-btn ps-btn--warning max-width-245 "
                                        }>
                                        Submit Return Request
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              </FormikFrom>
                            </div>
                            <div className="ps-cart__items hide" id="returnmsg">
                              <div className="row">
                                <div className="col-md-6">
                                  <div data-show="true" className="ant-alert ant-alert-success" role="alert">
                                    <span
                                      role="img"
                                      aria-label="check-circle"
                                      className="anticon anticon-check-circle ant-alert-icon">
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="check-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true">
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                                      </svg>
                                    </span>
                                    <div className="ant-alert-content">
                                      <div className="ant-alert-message">
                                        Your Return request has been submitted successfully.
                                        <a href={"/order/" + id} className="text-decoration-underline">
                                          View order
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(OrderReturn);
