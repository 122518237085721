import { Form } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import ProductRepository from "../../api/ProductRepository";
import FormikFrom from "../../components/elements/form/FormikFrom";
import { currencyOrdePrice } from "../../hooks/currencyCalculation";
import useProduct from "../../hooks/useProduct";
import { calculateProductPriceOffer } from "../../utilities/product-helper";
import OrderTrackShippment from "./OrderTrackShippment";

const OderDetailProductList = ({ orderItem, ordershippment, producttrackflag, orderdetail }) => {
  const { thumbnailImages } = useProduct();

  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0);
  const [productid, setProductid] = useState(0);
  const [decription, setDecription] = useState("");

  /*
    To close model
  */
  const handleClose = () => setShow(false);

  /*
    To set rate in use state variable and reset error div
  */
  const handleRating = (rate) => {
    setRating(rate);
    document.getElementById("errormsg").innerHTML = "";
  };

  /*
    To open review model
  */
  const handalReview = (product_id) => {
    setShow(true);
    setRating(0);
    setProductid(product_id);
    setDecription("");
    document.body.classList.remove("overflow-auto");
  };

  /*
    To save product review and toast initialize
  */
  const saveRating = async () => {
    var reviewobject = new Object();
    if (Number(rating) > 0) {
      reviewobject["productid"] = productid;
      reviewobject["rating"] = rating;
      reviewobject["review"] = decription;
      const res = await ProductRepository.saveProductReview(reviewobject);
      if (res.data.status === 200) {
        setDecription("");
        toast.success("Your review has been submitted successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          setShow(false);
        }, 500);
      }
      document.getElementById("errormsg").innerHTML = "";
    } else {
      document.getElementById("errormsg").innerHTML = "Please select rating";
    }
  };

  /*
    To order product list views
  */
  let items = [];
  let currencyrate = "";
  if (orderItem !== null && orderItem !== "") {
    orderItem.map((item) => {
      let productvariantjson = "";
      if (item.variantjson && item.variantjson !== null && item.variantjson !== "") {
        productvariantjson = JSON.parse(item.variantjson);
      }
      currencyrate = orderdetail[0].currencyForm;
      if (Number(item.orderofferprice) > 0) {
        item.offerprice = item.orderofferprice;
      }
      items.push(
        <tr key="1">
          <td className="text-left">
            <div className="ps-product--on-cart simple">
              <div className="ps-product__thumbnail list-image pb-5">
                <div className="ps-product__images">{thumbnailImages(item)}</div>
              </div>
              <div className="order-product-title ">
                <h4 className="ps-product__title">
                  <a href={`/product/${item.uniqueid}`}>{item.producttitle}</a>
                </h4>
                <p className="font-size-11">
                  {productvariantjson ? (
                    <span>{item.varianthsn && `HSN: ${item.varianthsn}`}</span>
                  ) : (
                    <span>{item.hsn && `HSN: ${item.hsn}`}</span>
                  )}
                </p>
                <span>
                  {productvariantjson && (
                    <>
                      {productvariantjson.Colour && <p className="font-size-11">Color : {productvariantjson.Colour}</p>}
                      {productvariantjson.Size && <p className="font-size-11">Size : {productvariantjson.Size}</p>}
                      {productvariantjson.Material && (
                        <p className="font-size-11">Material : {productvariantjson.Material}</p>
                      )}
                      {productvariantjson.Weight && (
                        <p className="font-size-11">Weight : {productvariantjson.Weight}</p>
                      )}
                      {productvariantjson.Flavour && (
                        <p className="font-size-11">Flavour : {productvariantjson.Flavour}</p>
                      )}
                    </>
                  )}
                </span>
                <p className=" pt-10">
                  {orderdetail && orderdetail.length > 0 && orderdetail[0].status === "Completed" && (
                    <a className="font-size-13 title-font" onClick={(e) => handalReview(item.productid)}>
                      Write Review
                    </a>
                  )}
                </p>
              </div>
            </div>
          </td>
          <td className="vertical-align-middle color-blue order-price-td">
            <p className="ps-product__price price-font pb-0">
              {item.orderofferprice && Number(item.orderofferprice) > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <span>{currencyOrdePrice(item.orderofferprice, currencyrate)}</span>
                    <del className="ml-10">
                      <span>{currencyOrdePrice(item.price, currencyrate)}</span>
                    </del>
                    <span> x</span>
                    <span>{item.quantity}</span>
                  </div>
                  <div className="col-12">
                    <p className="price-percentage">({calculateProductPriceOffer(item)} %)</p>
                  </div>
                </div>
              ) : (
                <>
                  {currencyOrdePrice(item.price, currencyrate)}x<span>{item.quantity}</span>
                </>
              )}
            </p>
          </td>
          <td className="vertical-align-middle color-blue">
            <div className="row">
              <div className={producttrackflag ? "col-12 text-center" : "col-12 "}>
                {item.orderofferprice && Number(item.orderofferprice) > 0 ? (
                  <p className={"ps-product__price price-font"}>
                    {currencyOrdePrice(Number(item.orderofferprice) * Number(item.quantity), currencyrate)}
                  </p>
                ) : (
                  <>
                    <p className={"ps-product__price price-font"}>
                      {currencyOrdePrice(Number(item.price) * Number(item.quantity), currencyrate)}
                    </p>
                  </>
                )}
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <ToastContainer />
          </td>
          <td className="vertical-align-middle text-right width-10-per ml-0 mr-0">
            {producttrackflag &&
              (orderdetail[0].status === "Partially Shipped" || orderdetail[0].status === "Shipped") && (
                <OrderTrackShippment ordershippment={ordershippment} productdata={item} />
              )}
          </td>
        </tr>
      );
    });
  }

  /*
    To close model
  */
  const handleReset = () => {
    setShow(false);
  };
  return (
    <>
      {items}
      <Modal show={show} onHide={handleClose} className="modal-width">
        <Modal.Header className="pt-15 pb-15" closeButton>
          <h2 className="ps-form__title font-size-24 mb-0">Write a review</h2>
        </Modal.Header>
        <FormikFrom>
          <Form>
            <Modal.Body>
              <div className="ps-form--review padding-left-30 padding-right-30 pb-30">
                <div className="row mt-30">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <label className="ps-form__label mr-2">Your rating *</label>
                      <Rating onClick={handleRating} allowFraction size={25} initialValue={rating} />
                    </div>
                    <span id="errormsg" className="color-red"></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="ps-form__label">Your review </label>
                      <textarea
                        className="form-control ps-form__textarea"
                        placeholder="Your review"
                        value={decription}
                        onChange={(e) => {
                          setDecription(e.target.value);
                        }}
                        maxLength="500"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className={"ps-btn ps-btn--warning"}
                onClick={(e) => {
                  saveRating();
                }}
              >
                Save
              </button>
              <Button variant="secondary" className="ps-btn ps-btn--warning" onClick={() => handleReset()}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </FormikFrom>
      </Modal>
    </>
  );
};

export default OderDetailProductList;
