import React from "react";

const Error404 = () => {
  return (
    <>
      <div className="ps-page__header"></div>
      <div className="ps-page__content ps-account">
        <div className="row">
          <div className="col-12 align-self-center text-center error-padding">
            <p className="error-404 color-blue">404</p>
            <span className="color-blue">Page Not Found !</span>
            <div className="pt-30">
              <a className="ps-btn ps-btn--warning" href="/">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Error404;
