import React from "react";
import { Link } from "react-router-dom";

import useProduct from "../../../../hooks/useProduct";

const ModuleProductImages = ({ product }) => {
  const { thumbnailImages } = useProduct();
  return (
    <div className="ps-product__images">
      <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
      {thumbnailImages(product)}
      <div>
        <img src="/static/img/vegonly.png" alt="" className="vegonlyimg" />
      </div>
    </div>
  );
};

export default ModuleProductImages;
