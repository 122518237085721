import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import Container from "../../components/layouts/Container";
import shopconfig from "../../config/shopconfig.json";

/*
  To initial form value
*/
const initialValues = {
  password: "",
  confirmpassword: "",
  userid: "",
  event: "setpassword",
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter value for Password"),
  confirmpassword: Yup.string().required("Please enter value for Confirm Password"),
});

const PasswordSet = () => {
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [timerCount, setTimer] = useState(30);

  const navigate = useNavigate();

  useEffect(() => {
    if (timerCount <= 0) {
      window.scrollTo(0, 0);
      navigate("/login");
    }
  }, [timerCount]);

  /*
    To set timer
  */
  const countdown = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  };

  /*
    To set password and start timer
  */
  const setPassword = async (data) => {
    setDisable(true);

    data.userid = localStorage.getItem("userid");
    data.event = "changepassword";
    if (data.password === data.confirmpassword) {
      const res = await user.resetpassword(data);
      if (res.data.status === 200) {
        setShow(true);
        setDisable(false);

        setTimer(30);
        countdown();
        window.scrollTo(0, 0);
      } else {
        setDisable(false);
        document.getElementById("errormsg").classList.remove("hide");
        document.getElementById("errormsg").innerHTML = res.data.errors.message;
      }
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = "Password and Confirm Password not match";
    }
  };

  /*
    To set password
  */
  const resetError = () => {
    document.getElementById("errormsg").classList.add("hide");
    document.getElementById("errormsg").innerHTML = "";
  };
  return (
    <Container>
      <div className="ps-page mt-50 form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header"></div>
          <div className="ps-page__content ps-account ">
            <div className="row ml-0 mr-0 justify-content-center">
              <div className="content-card box-rounded-login mb-50 login align-self-center">
                <div className={show ? "hide ps-form--review form-padding" : "show ps-form--review form-padding"}>
                  <h2 className="ps-form__title">Set Password</h2>
                  <FormikFrom
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      setPassword(values);
                    }}>
                    <Form>
                      <div className="row mt-20">
                        <div className="col-12 col-md-12">
                          <p>Great! Just one step away. Set your password of your profile</p>
                        </div>
                      </div>
                      <div className="row mt-20">
                        <div className="col-12 col-md-12">
                          <InputField
                            label={"Password *"}
                            name={"password"}
                            type={"password"}
                            placeholder={"Password"}
                            maxLength={"50"}
                            onKeyUp={resetError}
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <InputField
                            label={"Confirm Password *"}
                            name={"confirmpassword"}
                            type={"password"}
                            placeholder={"Confirm Password"}
                            maxLength={"50"}
                            onKeyUp={resetError}
                          />
                        </div>
                      </div>
                      <span className="color-red hide pt-10" id="errormsg"></span>
                      <div className="ps-form__submit mt-10">
                        <button
                          className={
                            disable ? "ps-btn ps-btn--warning width-100 disabled" : "ps-btn ps-btn--warning width-100"
                          }>
                          Set Password
                        </button>
                      </div>
                    </Form>
                  </FormikFrom>
                </div>
                <div className={show ? "padding-30 show" : "padding-30 hide"} id="setpassworddiv">
                  <div className="row">
                    <div className="col-12 col-md-12 text-left">
                      <h3 className="ps-form__title">Done! Welcome to {shopconfig.shop_name}</h3>
                      <div className="row mt-20">
                        <div className="col-12 col-md-12">
                          <p>Password has been reset successfully. Redirecting to login page in 30 seconds</p>
                        </div>
                      </div>
                      <Link to={"/login"}>
                        <span className="ps-btn ps-btn--warning width-100 mt-20">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PasswordSet;
