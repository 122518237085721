import React from "react";
import { Link, useSearchParams } from "react-router-dom";

import { UrlProduct } from "../../../pages/product/LoadProduct";

const WidgetShopCategories = ({ category }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      {category && category.length > 0 && (
        <aside className="widget widget_shop widget_categories">
          <h3 className="widget-title">Categories</h3>
          <ul className="ps-list--under">
            {category.map((item) => (
              <li key={item.id}>
                <Link
                  to={UrlProduct(searchParams, "category") + "&category=" + `${item.id}`}
                  className={"font-size-16 title-font"}>
                  {item.categoryname}
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      )}
    </>
  );
};

export default WidgetShopCategories;
