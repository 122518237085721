import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import ModuleEcomerceCartItems from "../../components/ecomerce/modules/ModuleEcomerceCartItems";
import ModuleEcomerceCartSummary from "../../components/ecomerce/modules/ModuleEcomerceCartSummary";
import BreadCrumb from "../../components/elements/BreadCrumb";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Container from "../../components/layouts/Container";
import FormCheckout from "../../components/shared/forms/FormCheckout";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";
import CartParameter from "./CartParameter";
import BillingAddress from "./BillingAddress";

const Checkout = ({ ecomerce }) => {
  const ref = useRef(null);

  const { cart_list, getCartlist, setCartlist } = useEcomerce();
  const { currencyrate, getcurrencyRate } = useEcomerce();
  const { countrylist, getCountryList } = useEcomerce();

  const [pecialPrice, setSpecialPrice] = useState(0);
  const [sameshipping, setSameshipping] = useState(["0"]);
  const [isdefaultaddress, sethandelisdefault] = useState();
  const [applyCoupon, setapplyCoupon] = useState("");
  const [proceedFlag, setProceedFlag] = useState(true);
  const [billingInfo, setBillingInfo] = useState();
  const [billingDetails, setBillingDetails] = useState();

  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  let cartdata = cart_list;

  /*
    To bread crumb json
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Shopping cart",
      url: "/cart?coupon=" + searchparam.urlcoupon + "&sameshipping=" + searchparam.urlsameshipping,
    },
    {
      text: "Checkout",
    },
  ];

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {
    getcurrencyRate();
    getCountryList();
    localStorage.setItem("sameshipping", "0");
    setSameshipping("0");
    if (searchparam.urlsameshipping === "1") {
      sameShippingAndBillingAddress(true);
    }
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      if (ecomerce.cartItems) {
        getCartlist(ecomerce.cartItems, "cart");
      }
    }

    if (localStorage.getItem("billingdata")) {
      setBillingInfo(JSON.parse(localStorage.getItem("billingdata")));
    }
  }, [ecomerce, searchparam.urlcoupon, searchparam.urlsameshipping]);

  useEffect(() => {
    setProceedFlag(true);
    var count = 0;
    if (cartdata && cartdata.length > 0) {
      cartdata.forEach((item) => {
        if (Number(item.stock) < Number(item.quantity)) {
          count = count + 1;
        }
      });
    }
    if (count > 0) {
      setProceedFlag(false);
    }
  }, [cartdata]);

  /*
    To set shipping and billing adddress as same
  */
  const sameShippingAndBillingAddress = (val) => {
    var url = new URL(window.location.href);
    var search_params = url.searchParams;

    document.getElementById("sameshippingaddress").checked = false;
    localStorage.setItem("sameshipping", "0");
    setSameshipping("0");
    search_params.set("sameshipping", "0");

    if (val) {
      search_params.set("sameshipping", "1");
      setBillingInfo(billingDetails);
      localStorage.setItem("sameshipping", "1");
      document.getElementById("sameshippingaddress").checked = true;
      setSameshipping("1");

      if (billingDetails) {
        localStorage.setItem("billingdata", JSON.stringify(billingDetails));
      }
    }

    url.search = search_params.toString();
    var new_url = url.toString();
    window.history.pushState(null, "", new_url);
  };

  /*
    To note set in local storage
  */
  const setNote = (value) => {
    localStorage.removeItem("note");
    if (value) {
      localStorage.setItem("note", value);
    }
  };

  const setAddressValues = (addressvalues, flag) => {
    if (addressvalues) {
      var add = JSON.parse(addressvalues);

      setBillingDetails({
        billingname: add.fullname,
        billingcountry: add.country,
        billingstate: add.state,
        billingcity: add.city,
        billingpincode: add.pincode,
        billingaddress: add.address,
        billingmobilecountry: add.addressphonecountry,
        billingmobile: add.addressphonenumber,
        billingcompanyname: add.companyname,
        billingcountryname: add.countryname,
        billingstatename: add.statename,
      });

      if (
        (flag === "CHANGED" && document.getElementById("sameshippingaddress").checked) ||
        searchparam.urlsameshipping === "1"
      ) {
        setBillingInfo({
          billingname: add.fullname,
          billingcountry: add.country,
          billingstate: add.state,
          billingcity: add.city,
          billingpincode: add.pincode,
          billingaddress: add.address,
          billingmobilecountry: add.addressphonecountry,
          billingmobile: add.addressphonenumber,
          billingcompanyname: add.companyname,
          billingcountryname: add.countryname,
          billingstatename: add.statename,
        });
      }
    }
  };

  return (
    <Container title="Checkout">
      <GoogleCaptcha />
      <div className="ps-page ps-page--shopping form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Checkout</h1>
          </div>
          <div className="ps-page__content">
            <div className="ps-cart__items card-box-shadow bn p-20 mb-20 hide">
              <ModuleEcomerceCartItems
                cartItems={cart_list}
                cartevent="shoppingcart"
                setSpecialPrice={setSpecialPrice}
                currencyrate={currencyrate}
              />
            </div>
            <div className="ps-checkout">
              <div className="ps-checkout__wapper"></div>
              <div className="row">
                <div className="col-12 col-md-8">
                  <FormCheckout
                    sethandelisdefault={(value) => sethandelisdefault(value)}
                    setAddressValues={(address, flag) => setAddressValues(address, flag)}
                    sameasshipping={sameshipping}
                  />
                  <div className="form-check ml-0 mb-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sameshippingaddress"
                      onChange={(e) => sameShippingAndBillingAddress(e.target.checked)}
                    />
                    <label className="form-check-label mt-0" htmlFor="sameshippingaddress">
                      Billing address is same as shipping address
                    </label>
                  </div>
                  <div className="ps-form--checkout card-box-shadow bn p-30 mb-30">
                    <div className="ps-form__billings ps-form--review">
                      <h2 className="ps-form__title">Billing Address</h2>
                      <div className="ps-form--review ">
                        <BillingAddress
                          ref={ref}
                          countrylist={countrylist}
                          searchparam={searchparam}
                          localparam={billingInfo}
                          sameasshipping={sameshipping}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ps-form--review card-box-shadow p-30 mb-30">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label className="ps-form__label">Customer Note </label>
                          <textarea
                            className="form-control ps-form__textarea"
                            placeholder="Note"
                            maxLength="500"
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          >
                            {localStorage.getItem("note")}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <ModuleEcomerceCartSummary
                    cartItems={cartdata}
                    cartevent={"summery"}
                    sameshippingdata={sameshipping}
                    setSpecialPrice={setSpecialPrice}
                    currencyrate={currencyrate}
                    isdefaultaddress={isdefaultaddress}
                    onSetCartlist={setCartlist}
                    setapplyCoupon={setapplyCoupon}
                    proceedFlag={proceedFlag}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(Checkout);
