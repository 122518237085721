import React, { useState } from "react";

const ModuleDetailColors = ({ simple = false, colorArray, setColorname }) => {
  const [color, setColor] = useState("");

  function handleSetActiveColor(payload) {
    setColor(payload);
    setColorname(payload);
  }

  const colorView = colorArray.map((item) => (
    <>
      <input
        type="radio"
        name="color"
        id={item}
        value={item}
        onChange={(e) => {
          handleSetActiveColor(item);
        }}
      />
      <label for={item} className="title-font">
        {item}
      </label>
    </>
  ));

  return (
    <figure className="ps-product__sizes">
      <figcaption className="ps-product__label font-size-16">Color:</figcaption>
      <div className="radio_container">{colorView}</div>
    </figure>
  );
};

export default ModuleDetailColors;
