const OrderTrackShippment = ({ ordershippment, productdata }) => {
  /*
    To order tack shippment views
  */
  let items;
  for (var ordershippment of ordershippment) {
    for (var productlist of ordershippment.shippingproductslist) {
      if (Number(productdata.productid) === Number(productlist.productid)) {
        items = (
          <a
            href={
              ordershippment.trackingurl && ordershippment.trackingid
                ? ordershippment.trackingurl + ordershippment.trackingid
                : "javascript:;"
            }
            target="_blank">
            <i className="fa fa-truck color-yellow"></i>
          </a>
        );
      }
    }
  }

  return (
    <>
      {items && (
        <div className="align-self-center mr-10">
          <strong>{items}</strong>
        </div>
      )}
    </>
  );
};
export default OrderTrackShippment;
