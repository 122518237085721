import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ url = "/", type = "default" }) => {
  if (type == "white") {
    return (
      <Link to={"/"}>
        <span className="ps-logo">
          <img src="/static/img/logo/CDS LOGO.png" alt="logo" />
        </span>
      </Link>
    );
  } else {
    return (
      <Link to={"/"}>
        <span className="ps-logo">
          <img src="/static/img/logo/CDS LOGO.png" alt="logo" />
        </span>
      </Link>
    );
  }
};

export default Logo;
