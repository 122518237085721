import { Drawer } from "antd";
import React from "react";
import { connect, useDispatch } from "react-redux";

import EcomerceMiniCart from "../../../components/ecomerce/EcomerceMiniCart";
import { toggleDrawer } from "../../../store/app/action";

const DrawerPrimary = ({ children, placement = "right", title = "Drawer", isDrawerShow }) => {
  const dispatch = useDispatch();

  /*
    To close mini cart 
  */
  function handleCloseDrawer(e) {
    e.preventDefault();
    dispatch(toggleDrawer(false));
    document.body.classList.add("overflow-auto");
  }

  return (
    <Drawer
      className="ps-panel--mobile ps-mobile-cart"
      closable={false}
      placement={placement}
      onClose={(e) => handleCloseDrawer(e)}
      visible={isDrawerShow}
      width={400}>
      <div className="ps-drawer">
        <div className="ps-drawer__header">
          <a href="#" className="ps-drawer__close" onClick={(e) => handleCloseDrawer(e)}>
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="ps-drawer__wrapper">
          <EcomerceMiniCart />
        </div>
      </div>
    </Drawer>
  );
};
export default connect((state) => state.app)(DrawerPrimary);
