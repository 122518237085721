/*
    To set product variant array
*/
export function VarientKeyName(productvariant) {
  let keyarray = [];
  var singlejson = JSON.parse(productvariant[0].variantjson);
  Object.keys(singlejson).forEach(function (key) {
    keyarray.push(key);
  });
  const setData = new Set(keyarray);
  keyarray = Array.from(setData);

  return keyarray;
}
/*
    To set product variant name
*/
export function VarientName(iskeyarray, colorname, sizename, materialname, weightname, flavourname) {
  let variantname = "";
  if (iskeyarray && iskeyarray.length > 0) {
    iskeyarray.map((item) => {
      if (colorname && colorname !== null && colorname !== "") {
        if (item === "Colour") {
          variantname = variantname + colorname + "-";
        }
      }
      if (sizename && sizename !== null && sizename !== "") {
        if (item === "Size") {
          variantname = variantname + sizename + "-";
        }
      }
      if (materialname && materialname !== null && materialname !== "") {
        if (item === "Material") {
          variantname = variantname + materialname + "-";
        }
      }
      if (weightname && weightname !== null && weightname !== "") {
        if (item === "Weight") {
          variantname = variantname + weightname + "-";
        }
      }
      if (flavourname && flavourname !== null && flavourname !== "") {
        if (item === "Flavour") {
          variantname = variantname + flavourname + "-";
        }
      }
    });
  }
  return variantname;
}
