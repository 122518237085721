import { CountUp } from "countup.js";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import scrollMonitor from "scrollmonitor";

import home from "../../api/home";
import BreadCrumb from "../../components/elements/BreadCrumb";
import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Container from "../../components/layouts/Container";
import config from "../../config/config.json";
import CustomerStory from "../home/CustomerStory";
import WhyUs from "../home/WhyUs";

const AboutUs = () => {
  const [homedata, setHomeData] = useState(null);

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "AboutUs",
    },
  ];

  useEffect(() => {
    getHomeData();
    handleStickyHeader();
    window.scrollTo(0, 0);
  }, []);

  /*
    To countdown start when scroll
  */
  function handleStickyHeader() {
    const requireCounter = document.querySelectorAll(".countup");
    requireCounter.forEach((el) => {
      const elementWatcher = scrollMonitor.create(el);
      elementWatcher.fullyEnterViewport(function () {
        let numberOfDecimals = 0;
        let countTo = parseFloat(el.dataset.to);

        if (countTo % 1 > 0) {
          numberOfDecimals = countTo.toString().split(".")[1].length;
        }
        const countUp = new CountUp(el, countTo, {
          decimalPlaces: numberOfDecimals,
        });

        countUp.start();
        el.dataset.counter_done = true;
      });
    });
  }

  /*
    To get home data using api
  */
  const getHomeData = async () => {
    const responseData = await home.getHomeData();
    if (responseData) {
      setHomeData(responseData.data.payload);
    }
  };

  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  var customerreview = "";
  let carouselitem = [];

  /*
    To carousel view
  */
  if (homedata) {
    customerreview = homedata.customerreview;
    if (customerreview && customerreview.length > 0) {
      customerreview.map((item) => {
        let imagepath = config.apiUrl + config.image_path;
        if (item.imagename !== null && item.imagename !== "") {
          imagepath = imagepath + item.imagename;
        } else {
          imagepath = "/static/img/static_image/user_image.png";
        }

        carouselitem.push(
          <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list similar-product">
            <div className="ps-category__item  m-10">
              <div className="ps-layout__item customer-cart card-box-shadow bn" key={item.id}>
                <div className="ps-product ps-product--grid">
                  <div className="ps-product__content">
                    <div className="row justify-content-center">
                      <div>
                        <img src={imagepath} alt="alt" className="img-responsive customer-img customer-home-image" />
                      </div>
                      <div className="col-12 text-center">
                        <h3>
                          {item.firstname} {item.lastname}
                        </h3>
                      </div>
                      <div className="col-12 text-center">
                        <Rating allowFraction initialValue={item.rating} readonly size={15} />
                      </div>
                      <div className="col-12">
                        <span className="title-font review-description">{item.review}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <Container>
      <GoogleCaptcha />
      <div id="aboutusid">
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
          </div>
        </div>
        <div className="">
          <img src="/static/img/about/towfiqu-barbhuiya-4N0dLUmdLAY-unsplash.jpg" />
        </div>
        <div className="ps-page form-loader">
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
          <div className="home-background-image">
            <div className="ps-page ps-page--product home-section-div">
              <div className="container">
                <div className="ps-page__content">
                  <div className=" ps-reverse">
                    <div className="ps-layout__right">
                      <div className="row">
                        <div className="col-12 pt-20">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="ps-section__title pb-20 selection-title"> About Us</h3>
                            </div>
                            <div className="col-12">
                              <span className="title-font">
                                The secrets of our steady growth are our core values, business ethics, simple work
                                principles, quality ingredients and hygienic work culture that we sincerely follow, have
                                made our journey so far remarkable. Our journey began in 1992 when our visionary
                                founders Mr. Shantilal Kalyanji Savla & Mrs. Jayaben Shantilal Savla laid the foundation
                                of the brand “Charliee” Chikki & Snacks with a mission to serve globally traditional
                                Indian snacks loaded with nutrition and great taste. We take great pride in enduring
                                this beautiful journey & aim to even take it higher with adding more happy customers to
                                Charliee family. For the road ahead, we have initiated online and offline marketing
                                plans which revolve around spreading brand awareness thus creating more demand in the
                                market for our products. While there is a consistent demand for our products in the
                                local market, we also export the same products to countries like Canada, USA, Australia,
                                New Zealand, UK, GCC, South Africa & Singapore. We continue to tap into other emerging
                                markets. Our quality control team ensures that our product quality, ingredients,
                                packaging and labelling comply with the food law and regulations in the various
                                countries.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {homedata && (
              <WhyUs
                homedetail={homedata.homedetail}
                aboutus={true}
                title={"Promises"}
                subtitle={"These are the CDS"}
              />
            )}
            {homedata && (
              <CustomerStory
                customerreview={homedata.customerreview}
                homedetail={homedata.homedetail}
                title={"Customers"}
                subtitle={"Happiest Foodie"}
              />
            )}

            <div className="ps-page ps-page--product home-section-div">
              <section
                className="about-store"
                style={{
                  backgroundImage: `url("/static/img/about/aboutus.jpg")`,
                }}
              >
                <div className="row">
                  <div className="col-12 col-md-3 text-center">
                    <p className="about-store-number">
                      <span className="countup" data-to="30"></span>+
                    </p>
                    <p>Years of experience</p>
                  </div>
                  <div className="col-12 col-md-3 text-center">
                    <p className="about-store-number">
                      <span className="countup" data-to="1000"></span>+
                    </p>
                    <p>Happy Customers</p>
                  </div>
                  <div className="col-12 col-md-3 text-center">
                    <p className="about-store-number">
                      <span className="countup" data-to="20"></span>+
                    </p>
                    <p>Product Categories</p>
                  </div>
                  <div className="col-12 col-md-3 text-center">
                    <p className="about-store-number">
                      <span className="countup" data-to="1000"></span>+
                    </p>
                    <p>Quality Products</p>
                  </div>
                </div>
              </section>
            </div>

            <div className="ps-page ps-page--product home-section-div">
              <div className="ps-page ps-page--product mt-20">
                <div className="ps-layout__right p-20">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 text-center">
                        <h3 className="ps-section__title pb-20 selection-title">Why CDS?</h3>
                      </div>
                      <div className="col-12">
                        <div className="row about-store-four">
                          <div className="col-12 col-md-4">
                            <div className="about-secure-div">
                              <div className="row">
                                <div className="col-12 align-self-center text-center">
                                  <p className="font-size-30 color-blue">
                                    <i className="fa fa-cc-mastercard"></i>
                                  </p>
                                </div>
                                <div className="col-12 align-self-center text-center">
                                  <span className="secure-detail about-secure-title">110% Secure Payments</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="about-secure-div">
                              <div className="row">
                                <div className="col-12 align-self-center text-center">
                                  <p className="font-size-30 color-blue">
                                    <i className="fa fa fa-check"></i>
                                  </p>
                                </div>
                                <div className="col-12 align-self-center text-center">
                                  <span className="secure-detail about-secure-title">PREMIUM QUALITY PRODUCTS</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="about-secure-div">
                              <div className="row">
                                <div className="col-12 align-self-center text-center">
                                  <p className="font-size-30 color-blue">
                                    <i className="fa fa-truck"></i>
                                  </p>
                                </div>
                                <div className="col-12 align-self-center text-center">
                                  <span className="secure-detail about-secure-title">110% Fast Delivery</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AboutUs;
