import React from "react";

const ModuleFooterCopyright = () => {
  return (
    <span className="color-blue" style={{ fontSize: 12, fontWeight: "lighter" }}>
      Copyright &copy; 2023. Developed and Managed By{" "}
      <a href="https://iconicussoft.com/" target="_blank">
        Iconicus Softwares Private Limited
      </a>{" "}
      And CDS branding powered by{" "}
      <a href="https://www.infinizostudios.com/" target="_blank">
        Infinizo Studios
      </a>
    </span>
  );
};

export default ModuleFooterCopyright;
