import React, { useState } from "react";

import Logo from "../../../components/elements/basic/Logo";
import FormSearchHeader from "../../../components/shared/forms/FormSearchHeader";
import ModuleHeaderNotice from "../../../components/shared/headers/modules/ModuleHeaderNotice";

const HeaderMobile = () => {
  const [search, setSearch] = useState(false);

  /*
    To handle toggle search
  */
  function handleToggleSearch(e) {
    e.preventDefault();
    setSearch(!search);
  }

  /*
    To view
  */
  let searchView;
  if (search) {
    searchView = (
      <div className="header__searchbox">
        <FormSearchHeader />
      </div>
    );
  }

  return (
    <>
      <ModuleHeaderNotice classes="mobile-only" />
      <header className="header header--mobile" data-sticky="true" id="header-sticky-mobile">
        <div className="header__left">
          <Logo />
        </div>
        <div className="header__right">
          <a className="header__search" href="#" onClick={(e) => handleToggleSearch(e)}>
            <i className="icon-magnifier"></i>
          </a>
        </div>
        {searchView}
      </header>
    </>
  );
};

export default HeaderMobile;
