const OrderHistoryStatus = ({ orderdetail }) => {
  /*
    To order status history views
  */
  let items = [];
  if (orderdetail && orderdetail.length > 0) {
    if (orderdetail[0].orderhistorylist !== null && orderdetail[0].orderhistorylist !== "") {
      orderdetail[0].orderhistorylist.map((item) => {
        if (
          item.eventtitle !== "Order Shipping Added" &&
          item.eventtitle !== "Order Shipping Updated" &&
          item.eventtitle !== "Order Shipping Deleted"
        ) {
          items.push(
            <li>
              <h4 className="ps-product__title font-size-15">{item.eventtitle}</h4>
              <p className="ps-text--order title-font margin-bottom-10">{item.eventmessage}</p>
              <o className="ps-text--order order-status-date">
                <i className="fa fa-calendar font-size-12"></i>
                <span className="pl-10 font-size-12">{item.entrydate}</span>
              </o>
            </li>
          );
        }
      });
    } else {
      items.push(
        <div className="ant-result ant-result-warning">
          <div className="ant-result-icon"></div>
          <div className="ant-result-title">No order history.</div>
        </div>
      );
    }
  }

  return <>{items}</>;
};

export default OrderHistoryStatus;
