import axios from "axios";

import config from "../config/config.json";

const headers = {
  Accept: "*/*",
};

/*
   To get faqs
*/
const getFaqs = async () => {
  const url = config.apiUrl + config.apiurlname + "/faq";
  const res = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

export default {
  getFaqs,
};
