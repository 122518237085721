import React, { useEffect, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";

import NextArrow from "../../../../components/elements/carousel/NextArrow";
import PrevArrow from "../../../../components/elements/carousel/PrevArrow";
import SwiperCarousel from "../../../../components/elements/carousel/SwiperCarousel";
import config from "../../../../config/config.json";

const variantSetting = {
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
        dots: false,
        arrows: false,
        vertical: false,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        dots: false,
        arrows: false,
        vertical: false,
        infinite: false,
      },
    },
  ],
};

const gallerySetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const ModuleDetailThumbnailSingle = ({ product, vertical = true }) => {
  const galleryCarousel = useRef(null);
  const variantCarousel = useRef(null);
  const [gallery, setGallery] = useState(null);
  const [variant, setVariant] = useState(null);
  const [productImages, setProductImages] = useState([]);

  let imagepath = config.apiUrl + config.image_path;

  useEffect(() => {
    let images = [];
    images.push(`${imagepath}${product.imagename}`);
    setProductImages(images);

    setGallery(galleryCarousel.current);
    setVariant(variantCarousel.current);
  }, [product]);

  /*
    To views
  */
  let carousel;
  const swiperParams = {
    slidesPerView: 1,
    navigation: true,
  };

  if (productImages.length > 0) {
    const items = productImages.map((item, index) => (
      <SwiperSlide key={index}>
        <img src={item} alt={item} />
      </SwiperSlide>
    ));

    carousel = <SwiperCarousel setting={swiperParams}>{items}</SwiperCarousel>;
  }

  return (
    <div className="ps-product__thumbnail" data-vertical={vertical ? "true" : "false"}>
      <figure>
        <div className="ps-wrapper">{carousel}</div>
      </figure>
    </div>
  );
};

export default ModuleDetailThumbnailSingle;
