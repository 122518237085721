import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ModuleProductImages from "../../../components/elements/products/modules/ModuleProductImages";
import useEcomerce from "../../../hooks/useEcomerce";
import useProduct from "../../../hooks/useProduct";
import { setCartItems } from "../../../store/ecomerce/action";

const ProductOnCart = ({ ecomerce, product, children, simple = false, cartevent, varientobject, currencyrate }) => {
  const { price } = useProduct();
  const { removeItem } = useEcomerce();
  const dispatch = useDispatch();

  const { cart_list, getCartlist } = useEcomerce();

  /*
    To remove cart item 
  */
  function handleRemoveItem(e, cartid) {
    e.preventDefault();
    removeItem({ cartid: cartid }, ecomerce.cartItems, "cart");
    getCartlist();
    dispatch(setCartItems(cart_list));
  }

  /*
    To view 
  */
  if (simple) {
    return (
      <div className={cartevent === "minicart" ? "ps-product--on-cart" : "ps-product--on-cart pt-15 pb-10"}>
        <div className="ps-product__thumbnail list-image">
          <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
          <ModuleProductImages product={product} />
        </div>
        <div className="ps-product__content">
          <div className="row">
            <div className={cartevent !== "minicart" ? "col-12 text-left" : "col-8 text-left"}>
              <h4 className="ps-product__title">
                <Link to={`/product/${product.uniqueid}`}>{product.productname}</Link>
              </h4>
            </div>
            {varientobject && varientobject.displaystock === "Yes" && cartevent === "varientproduct" && (
              <div className="col-4 text-right ps-product--detail pl-0">
                {Number(product.stock) > 0 ? (
                  <p className="ps-product__log-status text-center">in stock</p>
                ) : (
                  <p className="ps-product__log-status text-center outstock">Sold Out</p>
                )}
              </div>
            )}

            {cartevent === "minicart" && (
              <div className="col-4 text-right pr-30 ">
                <a
                  href="#"
                  className="ps-product__remove-item color-red"
                  onClick={(e) => handleRemoveItem(e, product.id)}
                >
                  <strong className="font-weight-100 removecart">Remove</strong>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    let productvariantjson = "";
    if (product.productvariantjson && product.productvariantjson !== null && product.productvariantjson !== "") {
      productvariantjson = JSON.parse(product.productvariantjson);
    }
    return (
      <div className={cartevent === "minicart" ? "ps-product--on-cart" : "ps-product--on-cart pt-15 pb-10"}>
        <div className="ps-product__thumbnail list-image">
          <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
          <ModuleProductImages product={product} />
        </div>
        <div className="ps-product__content">
          <div className="row">
            <div
              className={
                cartevent !== "minicart" && cartevent !== "varientproduct" ? "col-12 text-left" : "col-8 text-left"
              }
            >
              <h4 className="ps-product__title">
                <Link to={`/product/${product.uniqueid}`}>{product.productname}</Link>
              </h4>
              {price(product, currencyrate)}
              {children}
              <span>
                {productvariantjson && (
                  <>
                    {productvariantjson.Colour && <p className="font-size-11">Color : {productvariantjson.Colour}</p>}
                    {productvariantjson.Size && <p className="font-size-11">Size : {productvariantjson.Size}</p>}
                    {productvariantjson.Material && (
                      <p className="font-size-11">Material : {productvariantjson.Material}</p>
                    )}
                    {productvariantjson.Weight && <p className="font-size-11">Weight : {productvariantjson.Weight}</p>}
                    {productvariantjson.Flavour && (
                      <p className="font-size-11">Flavour : {productvariantjson.Flavour}</p>
                    )}
                  </>
                )}
              </span>
            </div>
            {varientobject && varientobject.displaystock === "Yes" && cartevent === "varientproduct" && (
              <div className="col-12 col-md-4 text-right ps-product--detail pl-0">
                {Number(product.stock) > 0 ? (
                  <p className="ps-product__log-status text-center">In stock</p>
                ) : (
                  <p className="ps-product__log-status text-center outstock">Sold Out</p>
                )}
              </div>
            )}

            {/* {varientobject && varientobject.varientdisable === "Yes" && !varientobject.displaystock && (
              <div className="col-12 col-md-4 text-right ps-product--detail pl-0">
                <p className="ps-product__log-status text-center outstock">Sold Out</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  }
};

export default connect((state) => state)(ProductOnCart);
