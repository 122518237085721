import { Modal } from "antd";
import { format } from "date-fns";
import { Field, Form } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import BreadCrumb from "../../components/elements/BreadCrumb";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import RadioButton from "../../components/elements/form/RadioButton";
import SelectBox from "../../components/elements/form/SelectBox";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To set local storage parameter in json
*/
var localparam = LocalstorageParam();

var countrycodearray = [];

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "My Account",
    url: "/profile",
  },
  {
    id: 3,
    text: "Profile",
  },
];

/*
  To set initial values
*/
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phonecountry: "",
  phonenumber: "",
  status: "1",
  dob: "",
  anniversary: "",
  wanumbercountry: "",
  gender: "",
  wanumber: "",
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("Please enter value for Firstname")
    .matches(/^[a-zA-Z]+$/, "Please enter valid Firstname"),
  lastname: Yup.string()
    .required("Please enter value for Lastname")
    .matches(/^[a-zA-Z]+$/, "Please enter valid Lastname"),
  email: Yup.string().email().required("Please enter value for Email"),
  phonenumber: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid mobile no")
    .min(10, "Please enter minimum 10 digits Mobile no")
    .required("Please enter value for Mobile No"),
  phonecountry: Yup.string().nullable().required("Please enter value for Country Code"),
  wanumber: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, "Please enter valid What’s App Number")
    .min(10, "Please enter valid What’s App Number"),
});

const MyProfile = (ecomerce) => {
  const [disable, setDisable] = useState(false);
  const [userData, setuserData] = useState([]);
  const [dateofbirth, SetDateOfBirth] = useState("");
  const [annivarsary, setAnnivarsary] = useState("");
  const { countrylist, getCountryList } = useEcomerce();
  const [gendertype, setgendertype] = useState("Male");
  const [wanumbercountry, setWanumbercountry] = useState();
  const [selectedcountry, setSelectedcountry] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getCountryList();

      setDisable(true);
      setTimeout(() => {
        getUserDetail();
        setDisable(false);
      }, 500);
    } else {
      navigate("/login");
    }
    window.scrollTo(0, 0);
  }, [ecomerce]);

  /*
    To set country in array
  */
  if (countrylist && countrylist.length > 0) {
    countrycodearray = [];
    countrylist.map((item) => {
      var countrycodeobject = new Object();
      countrycodeobject["value"] = item.extension;
      countrycodeobject["label"] = item.extension;
      countrycodearray.push(countrycodeobject);
    });
  }

  /*
    To  get user detail and set in json
  */
  const getUserDetail = async () => {
    const res = await user.getUserDetail();
    if (res.data.status === 200) {
      setuserData(res.data.payload.data[0]);
      initialValues.firstname = res.data.payload.data[0].firstname;
      initialValues.lastname = res.data.payload.data[0].lastname;
      initialValues.email = res.data.payload.data[0].email;
      initialValues.phonecountry = res.data.payload.data[0].phonecountry;

      var list = countrycodearray.find((item) => Number(item.value) === Number(res.data.payload.data[0].phonecountry));

      if (list) {
        setSelectedcountry(list);
      }

      initialValues.phonenumber = res.data.payload.data[0].phonenumber;
      initialValues.wanumber = res.data.payload.data[0].wanumber;

      initialValues.wanumbercountry = res.data.payload.data[0].wanumbercountry;
      initialValues.gender = res.data.payload.data[0].gender;

      if (res.data.payload.data[0].wanumbercountry && res.data.payload.data[0].wanumbercountry !== "0") {
        var list = countrycodearray.find(
          (item) => Number(item.value) === Number(res.data.payload.data[0].wanumbercountry)
        );
        if (list) {
          setWanumbercountry(list);
        }
      }

      if (res.data.payload.data[0].gender) {
        setgendertype(res.data.payload.data[0].gender);
      }

      if (res.data.payload.data[0].dob) {
        const [day, month, year] = res.data.payload.data[0].dob.split("-");
        let newDate = `${year}-${month}-${day}`;
        SetDateOfBirth(new Date(newDate));
      }

      if (res.data.payload.data[0].anniversary) {
        const [anniversaryday, anniversarymonth, anniversaryyear] = res.data.payload.data[0].anniversary.split("-");
        let newDateanniversary = `${anniversaryyear}-${anniversarymonth}-${anniversaryday}`;

        setAnnivarsary(new Date(newDateanniversary));
      }
    }
  };

  /*
    To  country list set in array
  */
  let country = [];
  let countrycode = [];
  if (countrylist && countrylist.length > 0) {
    for (var i = 0; i < countrylist.length; i++) {
      country.push(
        <option
          value={countrylist[i].extension}
          {...(countrylist[i].extension === userData.phonecountry ? "selected" : "")}
        >
          {countrylist[i].extension}
        </option>
      );

      countrycode.push(
        <option
          value={countrylist[i].extension}
          {...(countrylist[i].extension === userData.wanumbercountry ? "selected" : "")}
        >
          {countrylist[i].extension}
        </option>
      );
    }
  }

  /*
    To  update user 
  */
  const updateUser = async (data) => {
    setDisable(true);
    if (dateofbirth) {
      data.dob = format(new Date(dateofbirth), "dd-MM-yyyy");
    }
    if (annivarsary) {
      data.anniversary = format(new Date(annivarsary), "dd-MM-yyyy");
    }
    if (!data.wanumber) {
      data.wanumber = "0000000000";
    }
    if (gendertype) {
      data.gender = gendertype;
    }
    if (wanumbercountry) {
      data.wanumbercountry = wanumbercountry.value;
    }

    const res = await user.updateUser(data);
    if (res.data.status === 200) {
      setDisable(false);
      document.getElementById("errormsg").classList.add("hide");
      document.getElementById("errormsg").innerHTML = "";
      const modal = Modal.success({
        centered: true,
        title: "Success!",
        content: "User updated successfully",
      });
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = res.data.errors.message;
    }
  };

  /*
    To gender value set in state variable
  */
  const handleChangeGender = (val) => {
    initialValues.gender = val;
    setgendertype(val);
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content ">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Profile"} />
                </div>
                <div className="ps-layout__right ">
                  <div className="ps-page__content  ">
                    <div className="row">
                      <div className="col-12 col-md-12 ps-form--review ">
                        <h2 className="ps-form__title mb-20">My Profile</h2>
                        <FormikFrom
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values) => {
                            updateUser(values);
                          }}
                        >
                          <Form className="p-30 box-rounded width-100">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <InputField
                                  label={"First Name *"}
                                  name={"firstname"}
                                  type={"text"}
                                  placeholder={"First Name"}
                                  maxLength={"50"}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <InputField
                                  label={"Last Name *"}
                                  name={"lastname"}
                                  type={"text"}
                                  placeholder={"Last Name"}
                                  maxLength={"50"}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <InputField
                                  label={"Email *"}
                                  name={"email"}
                                  type={"email"}
                                  placeholder={"Email"}
                                  maxLength={"50"}
                                  id={"email"}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="row">
                                  <div className="col-12 col-md-4">
                                    <SelectBox
                                      label={"Country *"}
                                      name={"phonecountry"}
                                      options={countrycodearray}
                                      placeholder={"Country"}
                                      setSelectedCountry={setSelectedcountry}
                                      initialValues={initialValues}
                                      value={selectedcountry}
                                    />
                                  </div>
                                  <div className="col-12 col-md-8">
                                    <InputField
                                      label={"Mobile No *"}
                                      name={"phonenumber"}
                                      type={"text"}
                                      placeholder={"Mobile No"}
                                      maxLength={"10"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-3">
                                <div className="ps-form__group form-group">
                                  <label className="ps-form__label">Date Of Birth</label>
                                  <div className="input-group">
                                    <DatePicker
                                      onChange={SetDateOfBirth}
                                      value={dateofbirth}
                                      className="form-control ps-form__input"
                                      format={"dd-MM-yyyy"}
                                      clearIcon={null}
                                      dayPlaceholder={"dd"}
                                      monthPlaceholder={"mm"}
                                      yearPlaceholder={"yyyy"}
                                      name="dob"
                                      maxDate={new Date()}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="ps-form__group form-group">
                                  <label className="ps-form__label">Annivarsary</label>
                                  <div className="input-group">
                                    <DatePicker
                                      onChange={setAnnivarsary}
                                      value={annivarsary}
                                      className="form-control ps-form__input"
                                      format={"dd-MM-yyyy"}
                                      clearIcon={null}
                                      dayPlaceholder={"dd"}
                                      monthPlaceholder={"mm"}
                                      yearPlaceholder={"yyyy"}
                                      name="anniversary"
                                      maxDate={new Date()}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="row">
                                  <div className="col-12 col-md-4">
                                    <SelectBox
                                      label={"Country Code "}
                                      name={"wanumbercountry"}
                                      options={countrycodearray}
                                      placeholder={"Country"}
                                      setSelectedCountry={setWanumbercountry}
                                      initialValues={initialValues}
                                      value={wanumbercountry}
                                    />
                                  </div>
                                  <div className="col-12 col-md-8">
                                    <InputField
                                      label={"What’s App Number"}
                                      name={"wanumber"}
                                      type={"text"}
                                      placeholder={"What’s App Number"}
                                      maxLength={"10"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="ps-form__group form-group gender-radio">
                                  <label className="ps-form__label">Gender</label>
                                  <div className="input-group ">
                                    <Field
                                      component={RadioButton}
                                      name="gender"
                                      id="male"
                                      label="Male"
                                      checked={gendertype == "Male" ? true : false}
                                      value={"Male"}
                                      className="radio-button-gender "
                                      onChange={(e) => {
                                        handleChangeGender("Male");
                                      }}
                                    />
                                    <Field
                                      component={RadioButton}
                                      name="gender"
                                      id="female"
                                      label="Female"
                                      className="radio-button-gender"
                                      value={"Female"}
                                      checked={gendertype == "Female" ? true : false}
                                      onChange={(e) => {
                                        handleChangeGender("Female");
                                      }}
                                    />
                                    <Field
                                      component={RadioButton}
                                      name="gender"
                                      id="other"
                                      label="Other"
                                      className="radio-button-gender"
                                      value={"Other"}
                                      checked={gendertype == "Other" ? true : false}
                                      onChange={(e) => {
                                        handleChangeGender("Other");
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span className="color-red hide pt-10" id="errormsg"></span>
                            <div className="ps-form__submit mt-10">
                              <button
                                type="submit"
                                className={
                                  disable
                                    ? "ps-btn ps-btn--warning width-120 disabled"
                                    : "ps-btn ps-btn--warning width-120"
                                }
                              >
                                Update
                              </button>
                            </div>
                          </Form>
                        </FormikFrom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(MyProfile);
