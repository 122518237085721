import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import Shop from "../../components/partials/shop/Shop";
import SidebarShop from "../../components/shared/sidebar/SidebarShop";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import useEcomerce from "../../hooks/useEcomerce";
import useGetProducts from "../../hooks/useGetProducts";
import useProductGroup from "../../hooks/useProductGroup";
import UrlParameter from "../common/UrlParameter";
import { ProductJson } from "./LoadProduct";
import ProductTag from "./ProductTag";

/*
  To breadcrumb
*/
const breadcrumb = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Shop",
  },
  {
    id: 3,
    text: "",
  },
];

const ProductList = (ecomerce) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { loading, productItems, getProducts } = useGetProducts();
  const { filtergroup, getFilterGroup } = useGetProducts();
  const { category, getCategory } = useGetProducts();
  const { withGrid, withList } = useProductGroup();

  const { currencyrate, getcurrencyRate } = useEcomerce();

  const [isMenu, setIsMenu] = useState(false);
  /*
    To set url paramere in json
  */
  var searchparam = UrlParameter();

  useEffect(() => {
    getCategory(searchParams.get("parentcategory"));
    getFilterGroup();
    getcurrencyRate();
    getProducts(ProductJson(searchParams));
  }, [searchParams, ecomerce]);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header pb-0">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar">
                <div className="ps-layout__left margin-top-45">
                  {!isMenu && <SidebarShop filtergroup={filtergroup} currencyrate={currencyrate} category={category} />}
                </div>
                <div className="ps-layout__right">
                  <ProductTag filtergroup={filtergroup} currencyrate={currencyrate} category={category} />
                  <Shop
                    classes="ps-shop--grid"
                    products={productItems}
                    setIsMenu={setIsMenu}
                    isMenu={isMenu}
                    currencyrate={currencyrate}
                  >
                    {productItems && productItems.data && productItems.data.length > 0 ? (
                      <>
                        {searchparam.layout === "list"
                          ? withList(productItems.data, loading, searchparam.columns, currencyrate)
                          : withGrid(productItems.data, loading, searchparam.columns, currencyrate)}
                      </>
                    ) : (
                      <NoDataFound component={"product"} />
                    )}
                  </Shop>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(ProductList);
