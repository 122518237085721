import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const WhyUs = ({ homedetail, aboutus = false, title, subtitle }) => {
  const [show, setShow] = useState(false);

  /*
    To show video in model
  */
  const showVideo = () => {
    setShow(true);
  };

  /*
    To model close
  */
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {homedetail && Number(homedetail.displaywhyus) === 1 && (
        <>
          <div className="ps-page ps-page--product home-section-div">
            <div className="container">
              <div className="ps-page__content">
                <div className=" ps-reverse">
                  <div className="ps-layout__right">
                    <div className="row">
                      <div className="col-12 text-center">
                        <h3 className="sectionname">{subtitle ? subtitle : homedetail.displaywhyussubtitle}</h3>
                      </div>
                      <div className="col-12 text-center">
                        <h3 className="ps-section__title pb-20 selection-title">
                          {title ? title : homedetail.displaywhyustitle}
                        </h3>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-4 text-center">
                            <div className="ps-category__item m-10">
                              <div className="ps-layout__item card-background">
                                <div className="ps-product ps-product--grid ">
                                  <div className="ps-product__content">
                                    <p className="font-size-30 color-blue">
                                      <img src="/static/img/icon/premium.png" alt="premium" className="whyus-icon" />
                                    </p>
                                    <span className="whyus-title">Premium quality snacks</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 text-center">
                            <div className="ps-category__item m-10">
                              <div className="ps-layout__item card-background">
                                <div className="ps-product ps-product--grid ">
                                  <div className="ps-product__content">
                                    <p className="font-size-30 color-blue">
                                      <img
                                        src="/static/img/icon/handshake.png"
                                        alt="handshake"
                                        className="whyus-icon"
                                      />
                                    </p>
                                    <span className="whyus-title">Trusted name over the years</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 text-center">
                            <div className="ps-category__item m-10">
                              <div className="ps-layout__item card-background">
                                <div className="ps-product ps-product--grid ">
                                  <div className="ps-product__content">
                                    <p className="font-size-30 color-blue">
                                      <img src="/static/img/icon/energy-food.png" alt="cashew" className="whyus-icon" />
                                    </p>
                                    <span className="whyus-title">Healthiest snacks ever</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ps-page ps-page--product home-section-div our-story">
            {!aboutus && (
              <div>
                <img src="/static/img/static_image/Pista-1.png" className="cookie11-img"></img>
              </div>
            )}

            <div className="container">
              <div className="ps-page__content">
                <div className=" ps-reverse">
                  <div className="ps-layout__right">
                    <div className="row mt-80">
                      <div className="col-12 text-left">
                        <h3 className="ps-section__title pb-20 selection-title">Our Story</h3>
                      </div>
                    </div>

                    <div className={!aboutus ? "row" : "row mb-80"}>
                      <div className="col-12 col-md-6 pr-25 pb-20">
                        <p className="color-blue paragraph">
                          Today, we are one of the leading manufacturers and exporters of traditional Indian snacks
                          comprising of vast range of products. The secrets of our steady growth are our core values,
                          business ethics, simple work principles, quality ingredients and hygienic work culture that we
                          sincerely follow, have made our journey so far remarkable. Our journey began in 1992 when our
                          visionary founders Mr. Shantilal Kalyanji Savla & Mrs. Jayaben Shantilal Savla laid the
                          foundation of the brand “Charliee” Chikki & Snacks with a mission to serve globally
                          traditional Indian snacks loaded with nutrition and great taste. We take great pride in
                          enduring this beautiful journey & aim to even take it higher with adding more happy customers
                          to Charliee family.
                        </p>
                      </div>
                      <div className="col-12 col-md-6 storeimage">
                        <img src="/static/img/home/cover.jpg" alt="video" className="" onClick={showVideo} />
                        <i className="fa fa-play" onClick={showVideo}></i>
                      </div>
                    </div>
                    {!aboutus && (
                      <div className="row mt-50">
                        <div className="col-12 col-md-12 text-center">
                          <p>
                            <Link className="bordered-btn" to={"/aboutus"}>
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal show={show} onHide={handleClose} className="modal-width popup-model" size={"lg"}>
        <Modal.Body>
          <div className="ps-form--review">
            <div className="row">
              <div className="col-12 youtubevideo">
                <iframe
                  width="760"
                  height="400"
                  src="https://www.youtube.com/embed/qjw_i9OCBT8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default WhyUs;
