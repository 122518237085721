import React, { useEffect } from "react";

import BreadCrumb from "../../../components/elements/BreadCrumb";
import Container from "../../../components/layouts/Container";

const OrderSuccess = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shop",
      url: "/shop",
    },
    {
      text: "Order Success",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container title="Order Succe">
      <div className="ps-page ps-page--inner form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Order Success</h1>
          </div>
          <div className="ps-page__content ordersucess">
            <div className="row">
              <div className="col-md-6">
                <div data-show="true" className="ant-alert ant-alert-success" role="alert">
                  <span role="img" aria-label="check-circle" className="anticon anticon-check-circle ant-alert-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="check-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                    </svg>
                  </span>
                  <div className="ant-alert-content">
                    <div className="ant-alert-message">
                      Payment successful. Your order has been placed successfully. You can view order details{" "}
                      <span>
                        <a href="/order" className="text-decoration-underline">
                          here
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OrderSuccess;
