import React from "react";

const InputText = ({
  label,
  name,
  type,
  placeholder,
  maxLength,
  ref,
  returnKeyType,
  onChange,
  onKeyUp,
  blurOnSubmit,
  defaultValue,
}) => {
  return (
    <React.Fragment>
      <div className="ps-form__group form-group">
        {label ? <label className="ps-form__label">{label}</label> : ""}
        <div className="input-group">
          <input
            className={"form-control ps-form__input"}
            type={type}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            returnKeyType={returnKeyType}
            ref={ref}
            onChange={onChange}
            onKeyUp={onKeyUp}
            blurOnSubmit={blurOnSubmit}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default InputText;
