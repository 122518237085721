import { Slider } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { currencyPrice } from "../../../hooks/currencyCalculation";
import useGetProducts from "../../../hooks/useGetProducts";
import { UrlProduct } from "../../../pages/product/LoadProduct";

const WidgetShopFilterByPriceRange = ({ currencyrate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { productMaxMin, getProductMaxMin } = useGetProducts();

  let minimumprice = searchParams.get("minimumprice");
  let maximumprice = searchParams.get("maximumprice");

  const navigate = useNavigate();

  useEffect(() => {
    getProductMaxMin();
  }, [searchParams]);

  /*
    To set price range and redirect to shop url
  */
  function handleChangeRange(value) {
    navigate(UrlProduct(searchParams, "price") + "&minimumprice=" + value[0] + "&maximumprice=" + value[1]);
  }

  return (
    <aside className="widget widget_shop">
      {productMaxMin != null && (
        <figure>
          <h4 className="widget-title">By Price</h4>
          <Slider
            range
            value={
              !maximumprice ? [productMaxMin.minimumprice, productMaxMin.maximumprice] : [minimumprice, maximumprice]
            }
            defaultValue={
              !maximumprice ? [productMaxMin.minimumprice, productMaxMin.maximumprice] : [minimumprice, maximumprice]
            }
            max={productMaxMin.maximumprice}
            min={productMaxMin.minimumprice}
            onChange={(e) => handleChangeRange(e)}
          />
          <p id="filterprice" className="color-blue">
            <span className="title-font">Price:</span>
            {!maximumprice ? (
              <>{currencyPrice(productMaxMin.minimumprice, currencyrate)}</>
            ) : (
              <>{currencyPrice(minimumprice, currencyrate)}</>
            )}{" "}
            -{" "}
            {!maximumprice ? (
              <>{currencyPrice(productMaxMin.maximumprice, currencyrate)}</>
            ) : (
              <>{currencyPrice(maximumprice, currencyrate)}</>
            )}
          </p>
        </figure>
      )}
    </aside>
  );
};

export default WidgetShopFilterByPriceRange;
