import React, { useEffect } from "react";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";

const PrivacyPolicy = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Privacy Policy",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page  form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>

        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Privacy Policy</h1>
          </div>
          <div className="ps-page__content ps-account">
            <div className="row mb-100">
              <div className="col-12 pt-20">
                <span className="title-font">
                  At CDS Store, we are committed to protecting your privacy and ensuring the security of your personal
                  information. This Privacy Policy explains how we collect, use, and safeguard your information when you
                  visit our website, use our mobile applications or make a purchase from our store. By using our
                  website, mobile applications and services, you consent to the collection and use of your information
                  as described in this Privacy Policy.
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Data We Collect</h4>
                <span className="title-font">
                  <p>
                    <b>Personal Information:</b> When you place an order or create an account with us, we may collect
                    personal information such as your name, email address, shipping address, billing address, and
                    contact number.
                  </p>
                  <p>
                    {" "}
                    <b> Payment Information:</b> We collect payment information, such as credit card details or other
                    payment method details, necessary to process your transactions securely.
                  </p>
                  <p>
                    <b>Order Information:</b> We collect information related to your purchases, including the products
                    you have ordered, order history, and any communication or correspondence related to your orders.
                  </p>
                  <p>
                    <b> Website Usage Information:</b> We may collect information about your interactions with our
                    website, including your IP address, browser type, referring/exit pages, and timestamps. We use this
                    information for analytical purposes, to improve our website, and to enhance your user experience.
                  </p>
                  <p>
                    <b> Cookies and Similar Technologies:</b> We use cookies and similar tracking technologies to
                    personalize your experience, track your preferences, and gather information about your browsing
                    activities on our website. You can manage your cookie preferences through your browser settings.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Use of Data:</h4>
                <span className="title-font">
                  <p>
                    a. We use the collected information to process your orders, deliver products to you, and provide
                    customer support.
                  </p>
                  <p>
                    b. We may use your email address to send you order confirmations, shipping notifications, and
                    updates regarding your purchases.
                  </p>
                  <p>
                    c. With your consent, we may send you promotional emails, newsletters, or marketing communications
                    about our products, special offers, or upcoming events. You can unsubscribe from these
                    communications at any time.
                  </p>
                  <p>
                    d. We may use your information to improve our website, enhance our product offerings, and customize
                    your shopping experience.
                  </p>
                  <p>
                    d. We may use your information to improve our website, enhance our product offerings, and customize
                    your shopping experience.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Data Sharing:</h4>
                <span className="title-font">
                  <p>
                    a. We may share your personal information with trusted third-party service providers who assist us
                    in operating our business, such as shipping carriers, payment processors, and customer support
                    providers. These service providers are bound by confidentiality agreements and are required to
                    protect your information.
                  </p>
                  <p>
                    b. We may disclose your information when required by law, regulation, legal process, or governmental
                    request.
                  </p>
                  <p>
                    c. In the event of a merger, acquisition, or sale of our business assets, your information may be
                    transferred to the acquiring entity or third party involved in the transaction. We will notify you
                    of any such transfer and any changes to this Privacy Policy.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Data Security:</h4>
                <span className="title-font">
                  <p>
                    a. We implement reasonable security measures to protect your personal information from unauthorized
                    access, alteration, disclosure, or destruction.
                  </p>
                  <p>
                    b. We use secure socket layer technology (SSL) to encrypt sensitive payment information during
                    transmission.
                  </p>
                  <p>
                    c. While we strive to protect your personal information, please be aware that no method of
                    transmission over the Internet or electronic storage is 100% secure.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Third-Party Links:</h4>
                <span className="title-font">
                  <p>
                    Our website may contain links to third-party websites or services that are not owned or controlled
                    by us. This Privacy Policy applies only to our website and mobile application. We encourage you to
                    review the privacy policies of those third-party websites before providing any personal information.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Children's Privacy:</h4>
                <span className="title-font">
                  <p>
                    Our website,mobile applications and services are not directed to individuals under the age of 4. We
                    do not knowingly collect personal information from children. If we become aware that we have
                    collected personal information from a child without parental consent, we will take steps to delete
                    it.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Your Rights:</h4>
                <span className="title-font">
                  <p>
                    a. You have the right to access, update, or correct your personal information in our records. You
                    can do so by accessing your account settings or by contacting us directly.
                  </p>
                  <p>
                    b. You have the right to request the deletion of your personal information, subject to applicable
                    legal obligations and legitimate business purposes.
                  </p>
                  <p>
                    c. You have the right to opt out of receiving promotional communications from us. You can
                    unsubscribe from our marketing emails by following the instructions provided in the email or by
                    contacting us.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Changes to the Privacy Policy:</h4>
                <span className="title-font">
                  <p>
                    We reserve the right to modify or update this Privacy Policy at any time. Any changes will be
                    effective when posted on our website. Your continued use of our website, mobile application and
                    services after the posting of changes constitutes your acceptance of such changes.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4>Contact Us:</h4>
                <span className="title-font">
                  <p>
                    If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of
                    your personal information, please contact us at <b>info@baijay.com</b>
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <span className="title-font">
                  <p>
                    By using our website,mobile applications and services, you acknowledge that you have read and
                    understood this Privacy Policy and consent to the collection, use, and disclosure of your
                    information as described herein.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PrivacyPolicy;
