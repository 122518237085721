import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";

const InputField = ({
  label,
  name,
  type,
  placeholder,
  maxLength,
  onChange,
  onKeyUp,
  defaultValue,
  component,
  classname = "ps-form__input",
  id,
  rows,
  onScrollDown,
}) => {
  const { errors, touched, values } = useFormikContext();
  if ((touched.address || touched.addressphonenumber) && onScrollDown === "yes") {
    const element = document.getElementById("modelbody");
    element.scrollTop = 300;
  }

  return (
    <React.Fragment>
      <div className="ps-form__group form-group">
        {label ? <label className="ps-form__label">{label}</label> : ""}
        <div className="input-group">
          <Field
            className={
              errors.name && touched.name ? "input-error form-control " + classname : "form-control " + classname
            }
            type={type}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            {...onChange}
            onKeyUp={onKeyUp}
            defaultValue={defaultValue}
            component={component}
            rows={rows}
            id={id}
          />
        </div>

        <ErrorMessage name={name} component="span" className="color-red" />
      </div>
    </React.Fragment>
  );
};
export default InputField;
