import React from "react";

import config from "../../../../config/config.json";
const ModuleProductDetailSharing = ({ product }) => {
  return (
    <div className="ps-product__sharing pt-20">
      <ul className="ps-social ps-social--color">
        <li className="ml-0">
          <a
            className="ps-social__link facebook"
            href={"https://www.facebook.com/sharer.php?u=" + `${config.applicationUri}/product/${product.uniqueid}`}
            target="_blank">
            <i className="fa fa-facebook"> </i>
            <span className="ps-tooltip">Facebook</span>
          </a>
        </li>
        <li>
          <a
            className="ps-social__link twitter"
            href={
              "https://twitter.com/intent/tweet?text=" +
              `${product.productname}&url=${config.applicationUri}/product/${product.uniqueid}&related=`
            }
            target="_blank">
            <i className="fa fa-twitter"></i>
            <span className="ps-tooltip">Twitter</span>
          </a>
        </li>
        <li>
          <a
            className="ps-social__link pinterest"
            href={
              "https://in.pinterest.com/pin/create/bookmarklet/?url=" +
              `${config.applicationUri}/product/${product.uniqueid}`
            }
            target="_blank">
            <i className="fa fa-pinterest-p"></i>
            <span className="ps-tooltip">Pinterest</span>
          </a>
        </li>
        <li className="ps-social__linkedin">
          <a
            className="ps-social__link linkedin"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${config.applicationUri}/product/${product.uniqueid}`}
            target="_blank">
            <i className="fa fa-linkedin"></i>
            <span className="ps-tooltip">Linkedin</span>
          </a>
        </li>
        <li className="ps-social__email">
          <a className="ps-social__link envelope" href="#">
            <i className="fa fa-envelope-o"></i>
            <span className="ps-tooltip">Email</span>
          </a>
        </li>
        <li className="ps-social__whatsapp">
          <a className="ps-social__link whatsapp" href="#">
            <i className="fa fa-whatsapp"></i>
            <span className="ps-tooltip">WhatsApp</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ModuleProductDetailSharing;
