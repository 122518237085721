import React, { useEffect } from "react";

import useEcomerce from "../../../hooks/useEcomerce";
import MyAddress from "../../../pages/profile/MyAddress";

const FormCheckout = ({ sethandelisdefault, setAddressValues, sameasshipping }) => {
  const { countrylist, getCountryList } = useEcomerce();

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <>
      <div className="ps-form--checkout p-0 bn mb-0" id="myaddress">
        <div className="ps-form__billings">
          <MyAddress
            addressevent={"checkout"}
            sethandelisdefault={sethandelisdefault}
            countrylist={countrylist}
            setAddressValues={setAddressValues}
            displaydelete={false}
            sameasshipping={sameasshipping}
          />
        </div>
      </div>
    </>
  );
};

export default FormCheckout;
