import React, { useState } from "react";

const ModuleHeaderNotice = ({ classes, note }) => {
  const [showBar, setShowBar] = useState(true);

  /*
    To set value in state variable
  */
  const notedata = () => {
    setShowBar(false);
  };
  return (
    <>
      {note && note.topbartitle !== null && note.topbartitle !== "" && showBar ? (
        <div className={`ps-noti header__notice ${classes}`}>
          <div className="container">
            <p className="m-0">{note.topbartitle}</p>
          </div>
          <a className="ps-noti__close" onClick={notedata}>
            <i className="icon-cross"></i>
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ModuleHeaderNotice;
