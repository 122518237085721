import { Formik } from "formik";
import React from "react";

const FormikFrom = ({ initialValues, validationSchema, onSubmit, children, keys }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      key={keys ? JSON.stringify(keys) : ""}
      // enableReinitialize={true}
    >
      <>{children}</>
    </Formik>
  );
};
export default FormikFrom;
