/*
  To calculate discount
*/
export default function CaculateDiscount(products, object, discountdata) {
  let discount = 0;
  if (
    discountdata === "" ||
    (discountdata.discounttype !== "CART" &&
      discountdata.discounttype !== "SHIPPING" &&
      discountdata.discounttype !== "BUYXGETY")
  ) {
    if (products) {
      products.forEach((item) => {
        if (item.discount) {
          discount = Number(discount) + Number(item.discount);
        }
      });
    }
  } else {
    discount = discountdata.discount;
  }
  discount = Number(discount).toFixed(2);
  if (discount.indexOf(".") > -1) {
    if (discount.split(".")[1] === "00") {
      discount = discount.split(".")[0];
    }
  }
  object["discount"] = discount;
  return discount;
}
