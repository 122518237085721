import PropTypes from "prop-types";
import React, { Children } from "react";

const ActiveLink = ({ children, activeClassName = "active", ...props }) => {
  const child = Children.only(children);
  const childClassName = child.props.className || "";

  const className = childClassName;
  return (
    <a {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </a>
  );
};
ActiveLink.propTypes = {
  activeClassName: PropTypes.string.isRequired,
};

export default ActiveLink;
