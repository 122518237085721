import React from "react";

import config from "../../config/config.json";

const Banner = ({ offer }) => {
  /*
    To set offer image
  */
  let imagepath = config.apiUrl + config.image_path;
  if (offer && offer.offerimage !== null && offer.offerimage !== "" && offer.displayofferbanner === 1) {
    imagepath = imagepath + offer.offerimage;
  }

  return (
    <>
      {offer && offer.offerimage && offer.displayofferbanner === 1 && (
        <div className="ps-page ps-page--product home-section-div">
          <div className="ps-page__content">
            <div className="ps-reverse">
              <a href={offer.offerimageurl} target="_blank">
                <img
                  src={imagepath}
                  alt={offer.offerimagealt}
                  className="img-responsive review-user-img"
                  title={offer.offerimagetitle}
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Banner;
