const NoDataFound = ({ component }) => {
  return (
    <div className="col-12 col-md-12  mb-30 text-center">
      <div className="ant-result ant-result-warning">
        <div className="ant-result-icon">
          <span
            role="img"
            aria-label="warning"
            className={
              component === "productreview"
                ? "anticon anticon-warning color-blue font-size-25"
                : "anticon anticon-warning color-blue"
            }>
            {component === "address" && <i className="fa fa-building"></i>}
            {component === "product" && <i className="fa fa-cubes"></i>}
            {component === "cart" && <i className="fa fa-shopping-cart"></i>}
            {component === "order" && <i className="fa fa-shopping-bag"></i>}
            {component === "wishlist" && <i className="fa fa-heart"></i>}
            {component === "productreview" && (
              <>
                <i className="fa fa-star-o "></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i>{" "}
                <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i>
              </>
            )}
            {component === "Order History" && <i className="fa fa-history"></i>}
          </span>
        </div>
        <div className="ant-result-title">
          {component === "address" && "No address found."}
          {component === "product" && "No product found."}
          {component === "cart" && "No product in cart."}
          {component === "order" && "No order found."}
          {component === "wishlist" && "No product in wishlist."}
          {component === "productreview" && "No review found."}
          {component === "Order History" && "No order status history found"}
        </div>
      </div>
    </div>
  );
};
export default NoDataFound;
