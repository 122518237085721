import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useRef, useState } from "react";
import useRazorpay from "react-razorpay";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import cart from "../../../api/cart";
import order from "../../../api/order";
import user from "../../../api/user";
import config from "../../../config/config.json";
import { currencyPrice } from "../../../hooks/currencyCalculation";
import useEcomerce from "../../../hooks/useEcomerce";
import CaculateDiscount from "../../../pages/cart/CaculateDiscount";
import CartParameter from "../../../pages/cart/CartParameter";
import SpecialDiscountCalculation from "../../../pages/cart/SpecialDiscountCalculation";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import { calculateAmount } from "../../../utilities/ecomerce-helpers";

var object = new Object();
var couponobject = new Object();
var paymentobject = new Object();
var productobject = new Object();
var payumoney;
let couponproductlist = [];
/*
  To manage shipping json
*/
const shippingdata = {
  shippingstate: "",
  shippingcountry: "",
  subtotal: "",
  orderproductlist: [],
  shippingpincode: "",
};

const ModuleEcomerceCartSummary = ({
  ecomerce,
  cartItems,
  cartevent,
  sameshippingdata,
  setSpecialPrice,
  setapplyCoupon,
  currencyrate,
  isdefaultaddress,
  onSetCartlist,
  setHandelloader,
  proceedFlag,
}) => {
  const { removeItems } = useEcomerce();
  const { PaymentGateway, getpaymentgateway } = useEcomerce();
  const navigate = useNavigate();
  let Razorpay = useRazorpay();

  const [shipping, setShipping] = useState(0);
  const [disable, setDisable] = useState(false);
  const [payUData, setPayUData] = useState();
  const [paypalbtn, setPaypalBtn] = useState(false);
  const [discount, setDiscount] = useState("");
  const [coupontype, setCoupontype] = useState(localStorage.getItem("coupontype"));
  const [SubTotal, setSubTotal] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [couponcode, setCouponcode] = useState("");
  const [IsFlag, setIsFlag] = useState();
  const [ErrorMsg, setErrorMsg] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [request, setRequest] = useState("");
  const [pinfound, setPinFound] = useState(true);

  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  const [discountcoupon, setDiscountcoupon] = useState(searchparam.urlcoupon);
  const [validCoupon, setValidCoupon] = useState(searchparam.urlcoupon);

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  let billing;
  let amount_currency;
  var promise1;
  let totalView;

  useEffect(() => {
    object = new Object();
    getpaymentgateway();
    getAddressList("");
    setIsFlag(true);
    if (couponcode) {
      setDiscountcoupon(couponcode);
    } else {
      setDiscountcoupon(searchparam.urlcoupon);
    }
    if (ecomerce.cartItems) {
      /*
        To set billing data in json object
      */
      if (localparam && localparam.billingdata && localparam.billingdata !== null && localparam.billingdata !== "") {
        billing = JSON.parse(localparam.billingdata);
        if (localparam.sameshipping && localparam.sameshipping === "0") {
          object = billing;
        }
      }

      object["billingaddresschange"] = localparam.sameshipping;
      object["paymentstatus"] = "Pending";
      object["status"] = "Placed";
      if (localStorage.getItem("note")) {
        object["customernote"] = localStorage.getItem("note");
      }
    }

    discountCalculation();

    if (coupontype === "" || coupontype !== "SHIPPING") {
      if (discountcoupon && discountcoupon !== null && discountcoupon !== "") {
        applyCoupon("");
      }
    }
    /*
      To payumoney button hide /show using class add and remove
    */
    if (payumoney && payumoney !== null && payumoney !== "") {
      document.getElementById("paynowbtn").click();
      document.getElementById("commonpagediv").classList.add("hide");
      document.getElementById("payumoneydiv").classList.remove("hide");
      window.scrollTo(0, 0);
    }
    setCartListInJson();
    finalAmountCalculation();
    setCurrencyList();
  }, [ecomerce, payUData, discountcoupon, cartItems, searchparam.urlcoupon, isdefaultaddress, IsFlag]);

  /*
    To get address list by address id and set in object
  */
  const getAddressList = async (addressid) => {
    var addressobject = new Object();
    if (addressid) {
      addressobject["addressid"] = addressid;
    }

    addressobject["isdefaultaddress"] = "1";
    const res = await user.getAddress(addressobject);
    if (res.data.status === 200) {
      if (res.data.payload.length > 0) {
        shippingdata.shippingstate = res.data.payload[0].state;
        shippingdata.shippingcountry = res.data.payload[0].country;
        shippingdata.shippingpincode = res.data.payload[0].pincode;
        shippingCalculation();
        setDisable(false);
        setErrorMsg("");
        setIsFlag(true);
      } else {
        if (cartevent !== "cart") {
          setDisable(true);
          setIsFlag(false);
        }
      }
    }
  };

  /*
    To cart calculation for subtotal,total and set in json
  */
  const finalAmountCalculation = () => {
    if (cartItems) {
      let totalamount;

      if (cartItems && cartItems.length > 0) {
        totalView = calculateAmount(cartItems);
        object["subtotal"] = totalView;
        shippingdata.subtotal = totalView;

        totalamount = Number(totalView) + Number(shipping) - Number(CaculateDiscount(cartItems, object, discount));
        object["total"] = totalamount;

        amount_currency = Number(totalamount);
        if (currencyrate) {
          amount_currency = (Number(totalamount) * Number(currencyrate.multiplierrate).toFixed(5)).toFixed(2);
        }

        setTotalAmount(totalamount);
      } else {
        totalView = "0.00";
      }
      setSubTotal(totalView);
    }
  };

  /*
    To set data in cart json for shipping
  */
  const setCartListInJson = () => {
    var flag = true;
    if (cartItems && cartItems.length > 0) {
      let cart_list = [];
      let produlist = [];

      var i = 0;
      cartItems.forEach((element) => {
        element.producttitle = element.productname;
        let orderproductlist = {
          quantity: "",
          weight: "",
        };
        if (flag) {
          if (Number(element.stock) < Number(element.quantity)) {
            flag = false;
            setIsFlag(false);
          }
        }

        orderproductlist.quantity = element.quantity;
        orderproductlist.weight = Number(element.weight);

        cart_list.push(orderproductlist);
        cartItems[i].total = Number(element.quantity) * Number(element.price);
        if (Number(element.offerprice) > 0) {
          cartItems[i].total = Number(element.quantity) * Number(element.offerprice);
        }
        cartItems[i].productvariantdetailid = element.productvariantid;

        let requestBody = {
          productid: element.productid,
          productvariantid: element.productvariantid,
          quantity: cartItems[i].quantity,
        };
        produlist.push(requestBody);

        i++;
      });
      productobject["requestBody"] = produlist;

      shippingdata.orderproductlist = cart_list;
    }
  };

  /*
    To shipping calculation using address data and cart data
  */
  const shippingCalculation = async () => {
    localStorage.setItem("shipping", "0");
    setPinFound(true);
    if (cartItems && cartItems.length > 0) {
      let res = await cart.shippingCalculation(shippingdata);
      if (res.status === 200) {
        let shippingcalculation = res.data.payload;

        if (
          shippingcalculation &&
          shippingcalculation !== null &&
          shippingcalculation !== "" &&
          shippingcalculation.standardshipping
        ) {
          setShipping(shippingcalculation.standardshipping);
          object["shipping"] = shippingcalculation.standardshipping;
          localStorage.setItem("shipping", shippingcalculation.standardshipping);
          if (coupontype === "SHIPPING" && discountcoupon && discountcoupon !== null && discountcoupon !== "") {
            applyCoupon(shippingcalculation.standardshipping);
          }
        } else {
          setShipping(0);
          object["shipping"] = "0";
          localStorage.setItem("shipping", "0");

          if (shippingcalculation.pinfound === 0) {
            setPinFound(false);
          }
        }
      }
    }
  };

  /*
    To save order and check product stock
  */
  const saveOrder = async () => {
    /*
      To check product stock
    */
    localStorage.setItem("paymentmode", "");
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        setDisable(true);
        setCurrencyList();
        /*
          To save order 
        */
        localStorage.setItem("paymentmode", "Paypal");
        object["paymentmode"] = "Paypal";
        object["orderfrom"] = "WEBSITE";
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          paymentobject["orderid"] = res.data.payload.orderid;
        } else {
          setDisable(false);
        }
      }
    }
  };

  /*
    To update order status after transaction successfully complete
  */
  const updateStatus = async () => {
    paymentobject["paymentstatus"] = "Success";
    setDisable(true);
    const res = await cart.orderupdate(paymentobject);
    if (res.data.status === 200) {
    } else {
      setDisable(false);
    }
  };

  /*
    To razorPay integration , save order and check product stock
  */
  const razorPay = async () => {
    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }
    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "Razorpay");
        setDisable(true);
        setCurrencyList();
        /*
          To save order 
        */
        object["paymentmode"] = "Razorpay";
        object["orderfrom"] = "WEBSITE";
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          if (Number(res.data.payload.amount) > 0) {
            paymentobject["orderid"] = res.data.payload.orderid;
            paymentobject["paymentmode"] = "Razorpay";
            const options = {
              key: PaymentGateway.rpkeyid,
              amount: currencyPrice(Number(res.data.payload.amount), currencyrate),
              currency: currencyrate.currencycode,
              name: "Ecommerce",
              description: "Test Transaction",
              image: config.applicationUri + "/static/img/logo/CDS LOGO.png",
              order_id: res.data.payload.razorpayid,
              handler: function (response) {
                paymentobject["transactionid"] = response.razorpay_order_id;
                paymentobject["paymentid"] = response.razorpay_payment_id;
                setDisable(false);
                updateStatus();
                removeItems("cart");
                navigate("/ordersuccess");
                if (setHandelloader) {
                  setHandelloader(false);
                }
              },
              prefill: {
                name: res.data.payload.name,
                email: res.data.payload.email,
                contact: res.data.payload.contactno,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: function () {
                  setDisable(false);
                  if (setHandelloader) {
                    setHandelloader(false);
                  }
                },
              },
            };

            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              paymentobject["paymentstatus"] = "Failed";
              paymentobject["transactionid"] = response.error.metadata.payment_id;
              paymentobject["paymentid"] = response.error.metadata.order_id;
              updateStatus();
              navigate("/orderfailed");
              setDisable(false);
              if (setHandelloader) {
                setHandelloader(false);
              }
            });

            rzp1.open();
          }
        } else {
          setDisable(false);
          if (setHandelloader) {
            setHandelloader(false);
          }
        }
      }
    }
  };

  const payWithCCAvenue = async (event) => {
    event.preventDefault();

    localStorage.setItem("paymentmode", "");
    if (setHandelloader) {
      setHandelloader(true);
    }
    /*
      To check product stock
    */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "CCAvenue");
        setDisable(true);
        setCurrencyList();

        /*
          To save order
        */
        object["paymentmode"] = "CCAvenue";
        object["orderfrom"] = "WEBSITE";

        const res = await cart.saveorder(object);

        if (res.data.status === 200) {
          if (Number(res.data.payload.amount) > 0) {
            document.redirect.access_code.value = res.data.payload.access_code;
            document.redirect.encRequest.value = res.data.payload.encry_key;

            document.redirect.submit();
          }
        } else {
          setDisable(false);
          if (setHandelloader) {
            setHandelloader(false);
          }
        }
      }
    }
  };

  /*
    To redirect to summery after save billing data
  */
  const redirectToSummery = () => {
    if (document.getElementById("savebillingbtn")) {
      document.getElementById("savebillingbtn").click();
    }
  };

  /*
    To show paypal button
  */
  const payPal = () => {
    setPaypalBtn(true);
  };

  /*
    To save order by PayUMoney
  */
  const saveOrderPayUMoney = async () => {
    localStorage.setItem("paymentmode", "");
    /*
      To check product stock
   */
    const rescheckStock = await cart.checkStock(productobject);
    if (rescheckStock.data.status === 200) {
      if (rescheckStock.data.payload.stockstatus === "Out of Stock") {
        setTimeout(() => {
          setDisable(false);
          window.location.href = "/ordersummary";
        }, 500);
      } else {
        localStorage.setItem("paymentmode", "PayUMoney");
        setDisable(true);
        setCurrencyList();
        object["paymentmode"] = "PayUMoney";
        object["orderfrom"] = "WEBSITE";
        /*
          To save order 
        */
        const res = await cart.saveorder(object);
        if (res.data.status === 200) {
          paymentobject["orderid"] = res.data.payload.orderid;
          paymentobject["paymentmode"] = "PayUMoney";
          setPayUData(res.data.payload);
          setDisable(false);
        } else {
          setDisable(false);
        }
      }
    }
  };

  /*
    To delete coupon and reset cart list and redirect to cart
  */
  const deleteCoupon = () => {
    setDiscountcoupon("");
    setDiscount("");
    setapplyCoupon("");
    setCoupontype("");
    setCouponcode("");
    localStorage.removeItem("coupontype");
    document.getElementById("couponmsg").innerHTML = "";
    cartItems.forEach((item) => {
      if (Number(item.offerprice) > 0) {
        item.offerprice = item.productofferprice;
      } else {
        item.offerprice = 0;
      }
      item.isproductcoupon = "No";
    });
    navigate("/cart?sameshipping=" + sameshippingdata);
  };

  /*
    To set price
  */
  const setProductTotalPrice = (cartid) => {
    if (document.getElementById("price_" + cartid)) {
      document.getElementById("price_" + cartid).classList.remove("hide");
    }
    if (document.getElementById("after_discount_" + cartid)) {
      document.getElementById("after_discount_" + cartid).classList.add("hide");
    }
  };

  /*
    To apply coupon
  */
  const applyCoupon = async (shippingamount) => {
    couponproductlist = [];
    cartItems.forEach((element) => {
      let couponproductlistBody = {
        id: element.id,
        productid: element.productid,
        productvariantdetailid: element.productvariantid,
        categoryid: element.categoryid,
        taxpercentage: 0,
        quantity: element.quantity,
        price: element.price,
        offerprice: Number(element.offerprice).toFixed(2),
      };
      couponproductlist.push(couponproductlistBody);
    });
    var shippping_amount = shipping;

    couponobject["orderproductlist"] = couponproductlist;
    couponobject["discountcoupon"] = discountcoupon;
    couponobject["subtotal"] = calculateAmount(cartItems);
    couponobject["total"] =
      Number(calculateAmount(cartItems)) + Number(shipping) - Number(CaculateDiscount(cartItems, object, discount));
    if (shippingamount) {
      shippping_amount = shippingamount;
    }

    couponobject["shipping"] = shippping_amount;
    if (couponproductlist && couponproductlist.length > 0) {
      const res = await order.couponValidation(couponobject);
      if (res.data && res.data !== "") {
        if (res.data.status === 200) {
          setDiscount(res.data.payload.data[0]);
          setCoupontype(res.data.payload.data[0].discounttype);
          setDiscountcoupon(discountcoupon);

          localStorage.setItem("coupontype", res.data.payload.data[0].discounttype);
          object["discountcoupon"] = res.data.payload.data[0].discountcoupon;
          object["couponcode"] = discountcoupon;

          setProductDiscount(res.data.payload.data[0]);
          if (document.getElementById("couponerrormsg")) {
            document.getElementById("couponerrormsg").innerHTML = "";
          }
          if (document.getElementById("couponmsg")) {
            document.getElementById("couponmsg").innerHTML = "";
          }

          setValidCoupon(discountcoupon);

          var url = new URL(window.location.href);
          var search_params = url.searchParams;
          search_params.set("coupon", discountcoupon);

          url.search = search_params.toString();
          var new_url = url.toString();
          window.history.pushState(null, "", new_url);
        } else {
          if (
            "Invalid Coupon Code" === res.data.errors.message ||
            "Invalid Coupon code - This Coupon code limit is over." === res.data.errors.message ||
            "Invalid Coupon code - This coupon code is not valid for you." === res.data.errors.message ||
            "Invalid Coupon" === res.data.errors.message
          ) {
            if (document.getElementById("couponerrormsg")) {
              document.getElementById("couponerrormsg").innerHTML = res.data.errors.message;
            }
            if (document.getElementById("couponmsg")) {
              document.getElementById("couponmsg").innerHTML = "";
            }
          } else {
            if (document.getElementById("couponmsg")) {
              document.getElementById("couponmsg").innerHTML = res.data.errors.message;
            }
            if (document.getElementById("couponerrormsg")) {
              document.getElementById("couponerrormsg").innerHTML = "";
            }
          }
        }
      }
    }
  };

  /*
    To Product Discount set in cart list when apply product coupon
  */
  const setProductDiscount = (data) => {
    let productlist = data.orderproductlist;
    cartItems.forEach((item, index) => {
      if (data.discounttype === "PRODUCT") {
        if (Number(productlist[index].id) === Number(item.id)) {
          var totalprice = Number(productlist[index].price);
          var newprice = Number(totalprice) - Number(productlist[index].discount);
          if (Number(newprice) !== Number(totalprice)) {
            cartItems[index].discountedprice = newprice;
            cartItems[index].discount = productlist[index].discount * Number(item.quantity);
            item.discount = productlist[index].discount * Number(item.quantity);
            if (Number(productlist[index].discount) > 0) {
              if (productlist[index].discountapply === "Yes") {
                cartItems[index].isproductcoupon = "Yes";
                cartItems[index].offerprice = newprice;
              }
            }
          }
        }
      }
      if (data.discounttype === "CART" || data.discounttype === "SHIPPING" || data.discounttype === "BUYXGETY") {
        cartItems[index].isproductcoupon = "Yes";
        cartItems[index].offerprice = "0";
        setProductTotalPrice(item.id);
        if (onSetCartlist) {
          onSetCartlist(cartItems);
        }
      }
    });

    setapplyCoupon(data.discounttype);
    setSpecialPrice(data);
  };

  /*
    To set cart list for order
  */
  const setCurrencyList = () => {
    if (cartItems && cartItems.length > 0) {
      if (currencyrate) {
        var currencydata = {
          id: currencyrate.id,
          currencyname: currencyrate.currencyname,
          displayicon: currencyrate.displayicon,
          currencycode: currencyrate.currencycode,
          rate: currencyrate.rate,
          multiplier: currencyrate.multiplier,
          multiplierrate: Number(currencyrate.multiplierrate).toFixed(5),
        };
        object["currencyForm"] = currencydata;
      }
    }
  };

  /*
    To set coupon in state variable
  */
  const setCoupon = (e) => {
    e.preventDefault();
    localStorage.removeItem("coupontype");
    let coupon_code = document.getElementById("coupon").value;
    if (coupon_code) {
      setDiscountcoupon(coupon_code);
      setCouponcode(coupon_code);
    }
    document.getElementById("coupon").value = "";
  };

  /*
    To discount calculation
  */
  const discountCalculation = () => {
    /*
      To set offer and special discount 
    */
    if (
      discount === "" ||
      (discount.discounttype !== "PRODUCT" &&
        discount.discounttype !== "SHIPPING" &&
        discount.discounttype !== "CART" &&
        discount.discounttype !== "BUYXGETY")
    ) {
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach((element, index) => {
          let offer = 0;
          if (Number(element.productofferprice) > 0) {
            offer = Number(element.price) - Number(element.productofferprice);
            offer = Number(offer) * Number(element.quantity);
          }

          cartItems[index].isproductcoupon = "No";
          cartItems[index].discount = Number(offer);
          promise1 = SpecialDiscountCalculation.specialCalculation(element);
          Promise.all([promise1]).then(function (values) {
            if (values) {
              if (values[0].isproductcoupon === "No") {
                setSpecialPrice(values);
              }
            }
          });
        });
      }
    }
  };

  /*
    To show shipping error msg
  */
  const showError = () => {
    if (disable) {
      setErrorMsg("Please select shipping address");
      document.getElementById("shippingerrormsg").classList.remove("hide");
    }
  };
  return (
    <div className="ps-block--cart-summary">
      <h3>Cart Total</h3>

      <div className="ps-block__content bn card-box-shadow  p-20 mt-30">
        <div className="ps-block__records">
          <p>
            <span className="font-size-18">Subtotal</span>
            <strong className="cart-font-title">{currencyPrice(SubTotal, currencyrate)}</strong>
          </p>
          <p>
            <span className="font-size-18" style={{ flex: 1 }}>
              Shipping{" "}
              {cartevent === "cart" && (
                <>
                  <br></br>
                  <strong className="font-weight-300 font-size-12">[Based on your default shipping address]</strong>
                </>
              )}
            </span>
            <span>
              {shipping > 0 ? (
                <strong className="cart-font-title">{currencyPrice(shipping, currencyrate)}</strong>
              ) : (
                <>
                  {pinfound ? (
                    <strong className="cart-font-title">Free</strong>
                  ) : (
                    <>
                      <span className="" style={{ color: "red" }}>
                        Pincode is not serviceable
                      </span>
                    </>
                  )}
                </>
              )}
            </span>
          </p>
          <p>
            <span className="font-size-18">Discount</span>
            <strong className="cart-font-title">
              <span className="cart-font-title pl-0">
                {currencyPrice(CaculateDiscount(cartItems, object, discount), currencyrate)}
              </span>
            </strong>
          </p>
          {discount && discount !== null && discount !== "" && discount.couponcode && (
            <p>
              <span className="font-size-18">Coupon</span>
              <strong className="cart-font-title">
                <span>
                  <span className="cart-font-title">{discount.couponcode}</span>
                  {cartevent === "cart" && (
                    <span className="pl-10">
                      <a onClick={deleteCoupon}>
                        <i className="fa fa-trash color-red"></i>
                      </a>
                    </span>
                  )}
                </span>
              </strong>
            </p>
          )}
          <p className="total">
            <span className="font-size-18">
              Total <br></br>
              <strong className="font-weight-300 font-size-12">[Inclusive of all taxes]</strong>
            </span>

            <strong className="cart-font-title">
              <span className="cart-font-title pl-0">
                {currencyPrice(
                  Number(SubTotal) + Number(shipping) - Number(CaculateDiscount(cartItems, object, discount)),
                  currencyrate
                )}
              </span>
            </strong>
          </p>
        </div>
      </div>
      {cartevent === "cart" && (
        <div className="card-box-shadow p-20 mt-30">
          <div className="ps-cart__actions">
            <div className="ps-cart__coupon ps-cart-coupon">
              <input type="text" className="form-control" placeholder="Enter your coupon" id="coupon" />
              <button
                className="ps-btn ps-btn--primary"
                onClick={(e) => {
                  setCoupon(e);
                }}
              >
                Apply
              </button>
            </div>
            <p id="couponerrormsg" className="color-red"></p>
            <p id="couponmsg" className="color-green"></p>
          </div>
        </div>
      )}

      <div className="ps-block__bottom mt-30">
        {IsFlag ? (
          <>
            {cartevent === "payment" && pinfound && (
              <React.Fragment>
                {PaymentGateway.rpstatus === "Active" && (
                  <button
                    className={
                      disable
                        ? "ps-btn ps-btn--warning disabled disable-btn-color width-100-per mb-30 "
                        : "ps-btn ps-btn--warning mb-30 width-100-per"
                    }
                    onClick={razorPay}
                  >
                    Pay Now
                  </button>
                )}
                {PaymentGateway.paypalstatus === "Active" && (
                  <span>
                    {paypalbtn ? (
                      <PayPalScriptProvider
                        options={{
                          "client-id": PaymentGateway.paypalclientid,
                          currency: currencyrate.currencycode,
                        }}
                      >
                        <PayPalButtons
                          style={{ layout: "horizontal" }}
                          createOrder={(data, actions) => {
                            saveOrder();
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    value: amount_currency,
                                  },
                                },
                              ],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                              paymentobject["paymentmode"] = "Paypal";
                              if (details) {
                                paymentobject["transactionid"] = details.purchase_units[0].payments.captures[0].id;
                              }
                              updateStatus();
                              removeItems("cart");
                              navigate("/ordersuccess");
                            });
                          }}
                        />
                      </PayPalScriptProvider>
                    ) : (
                      <button
                        className={
                          disable
                            ? "ps-btn ps-btn--warning disabled disable-btn-color mb-30"
                            : "ps-btn ps-btn--warning mb-30 "
                        }
                        onClick={payPal}
                      >
                        Pay Now with PayPal
                      </button>
                    )}
                  </span>
                )}
                {PaymentGateway.payustatus === "Active" && (
                  <button
                    className={
                      disable || !proceedFlag
                        ? "ps-btn ps-btn--orange disabled disable-btn-color"
                        : "ps-btn ps-btn--warning"
                    }
                    onClick={saveOrderPayUMoney}
                  >
                    Pay Now with PayuMoney
                  </button>
                )}

                {PaymentGateway.ccastatus === "Active" && (
                  <>
                    <form
                      id="nonseamless"
                      method="post"
                      onSubmit={payWithCCAvenue}
                      name="redirect"
                      action={config.ccAvenueUri}
                    >
                      <>
                        <input type="hidden" id="encRequest" name="encRequest" value="" />
                        <input type="hidden" name="access_code" id="access_code" value="" />
                        <button
                          className={
                            disable || !proceedFlag
                              ? "ps-btn ps-btn--orange disabled disable-btn-color"
                              : "ps-btn ps-btn--warning"
                          }
                          type="submit"
                        >
                          Pay Now
                        </button>
                      </>
                    </form>
                  </>
                )}
              </React.Fragment>
            )}

            {cartevent === "cart" && (
              <Link
                to={
                  validCoupon
                    ? "/checkout?coupon=" + validCoupon + "&sameshipping=" + sameshippingdata
                    : "/checkout?sameshipping=" + sameshippingdata
                }
                className={
                  disable || !proceedFlag
                    ? "warningbtn disabled disable-btn width-100-per max-width-unset"
                    : "warningbtn width-100-per max-width-unset"
                }
              >
                Proceed to checkout
              </Link>
            )}

            {cartevent === "summery" && pinfound && (
              <button
                className={
                  disable || !proceedFlag
                    ? "warningbtn disabled disable-btn width-100-per max-width-unset"
                    : "warningbtn width-100-per max-width-unset"
                }
                onClick={redirectToSummery}
              >
                Proceed to checkout
              </button>
            )}
          </>
        ) : (
          <>
            <a className="warningbtn  width-100-per max-width-unset" href="javascript:;" onClick={showError}>
              Proceed to checkout
            </a>
            <p id="shippingerrormsg" className={ErrorMsg ? "color-red pt-10" : "hide pt-10"}>
              {disable && ErrorMsg}
            </p>
          </>
        )}
        {payUData && payUData !== null && payUData !== "" && (
          <div>
            <form name="ordernowform" id="ordernowform" action={payUData.paymentvalues.action} method="post">
              <div className="hide">
                <input type="hidden" name="firstname" value={payUData.paymentvalues.firstname} />
                <input type="hidden" name="lastname" value={payUData.paymentvalues.lastname} />
                <input type="hidden" name="email" value={payUData.paymentvalues.email} />
                <input type="hidden" name="phone" value={payUData.paymentvalues.phone} />
                <input type="hidden" name="key" value={payUData.paymentvalues.key} />
                <input type="hidden" name="hash" value={payUData.paymentvalues.hash} />
                <input type="hidden" name="txnid" value={payUData.paymentvalues.txnid} />
                <input type="hidden" name="productinfo" value={payUData.paymentvalues.productinfo} />
                <input name="surl" type="hidden" value={payUData.paymentvalues.surl} />
                <input name="furl" type="hidden" value={payUData.paymentvalues.furl} />
                <input name="amount" type="hidden" value={payUData.paymentvalues.amount} />
                <input type="hidden" name="service_provider" value="payu_paisa" />
                <input name="udf1" type="hidden" value={payUData.paymentvalues.udf1} />
                <input name="udf2" type="hidden" value={payUData.paymentvalues.udf2} />
                <input name="udf3" type="hidden" value={payUData.paymentvalues.udf3} />
                <input name="udf4" type="hidden" value={payUData.paymentvalues.udf4} />
                <input name="udf5" type="hidden" value={payUData.paymentvalues.udf5} />
                <input type="submit" id="paynowbtn" value="Pay Now"></input>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((state) => state)(ModuleEcomerceCartSummary);
