import React from "react";
import { Link } from "react-router-dom";

import ModuleProductImages from "../../../components/elements/products/modules/ModuleProductImages";
import ModuleProductRating from "../../../components/elements/products/modules/ModuleProductRating";
import useProduct from "../../../hooks/useProduct";

const ProductGridWithDetail = ({ product }) => {
  const { price, badges } = useProduct();

  return (
    <div className="ps-product ps-product--standard ps-product--grid-with-detail">
      <div className="ps-product__thumbnail">
        <ModuleProductImages product={product} />
        {badges(product)}
      </div>
      <div className="ps-product__content">
        <div className="ps-product__categories">
          <Link to={"/shop"}>Thermometer Brand</Link>
        </div>
        <h4 className="ps-product__title">
          <Link to={`/product/${product.id}`}>{product.name}</Link>
        </h4>
        {price(product)}
        <ModuleProductRating />
        <ul className="ps-product__short-desc">
          <li>Study history up to 30 days</li>
          <li>Up to 5 users simultaneously</li>
          <li>Has HEALTH certificate</li>
        </ul>
      </div>
    </div>
  );
};

export default ProductGridWithDetail;
