import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useState } from "react";
import Error404 from "../../components/common/Error404";
import BreadCrumb from "../../components/elements/BreadCrumb";
import DetailDefault from "../../components/elements/detail/DetailDefault";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import SkeletonProductDetail from "../../components/elements/skeletons/SkeletonProductDetail";
import Container from "../../components/layouts/Container";
import useEcomerce from "../../hooks/useEcomerce";
import useGetProducts from "../../hooks/useGetProducts";

const ProductDetailPage = ({ ecomerce }) => {
  const { id } = useParams();

  const { loading, likeproduct, product, comboproduct, getProductById, setProduct, setComboProduct } = useGetProducts();
  const { currencyrate, getcurrencyRate } = useEcomerce();
  const { productvariant, getProductVariant } = useGetProducts();

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setFlag(false);
    getcurrencyRate();
    getProductById(id);
    getProductVariant(id);
    window.scrollTo(0, 0);
    setFlag(true);
  }, [id, ecomerce]);

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Shop",
      url: "/shop",
    },
    {
      id: 3,
      text: product && product.productname,
    },
  ];
  return (
    <Container title="Product" product={product}>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>

        <div className="ps-page ps-page--product">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className=" ps-reverse">
                <div className="ps-layout__right">
                  {loading ? (
                    <div className="container">
                      <SkeletonProductDetail />
                    </div>
                  ) : (
                    <>
                      {product && (
                        <DetailDefault
                          product={product}
                          comboproduct={comboproduct}
                          likeproduct={likeproduct}
                          currencyrate={currencyrate}
                          productvariant={productvariant}
                          setProduct={setProduct}
                        />
                      )}

                      {!product && flag && (
                        <div className="container">
                          <Error404 />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state) => state)(ProductDetailPage);
