import React from "react";
import MetaTags from "react-meta-tags";

import FooterDefault from "../../components/shared/footers/FooterDefault";
import HeaderDefault from "../../components/shared/headers/HeaderDefault";
import HeaderMobile from "../../components/shared/mobiles/HeaderMobile";
import PreFooter from "../common/PreFooter";

const Container = ({ children, title = "Just a awesome website", footer = <FooterDefault />, product, note }) => {
  /*
    To view
  */
  let titleView;
  if (title !== undefined) {
    titleView = process.env.title + " | " + title;
  } else {
    titleView = process.env.title + " | " + process.env.titleDescription;
  }

  return (
    <div className="ps-root" id="commonpagediv">
      <MetaTags>
        {product && (
          <>
            <title>{product.pagetitle ? product.pagetitle : title}</title>
            {product.pagetitle && <meta name="title" content={`${product.pagetitle}`}></meta>}
            {product.metadescription && <meta name="description" content={`${product.metadescription}`}></meta>}
            {product.metakeywords && <meta name="keyword" content={`${product.metakeywords}`}></meta>}
          </>
        )}
      </MetaTags>
      <HeaderDefault note={note} />
      <HeaderMobile />
      {children}
      <PreFooter />
      {footer}
    </div>
  );
};

export default Container;
