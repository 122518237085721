import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import FormButton from "../../components/elements/form/FormButton";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import Container from "../../components/layouts/Container";

/*
  To initial form value
*/
const initialValues = {
  email: "",
  contactno: "",
  username: "",
};

/*
  To form validation using yup
*/
const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter value for User Name"),
});

/*
  To reset error
*/
const reseterror = () => {
  document.getElementById("errormsg").classList.add("hide");
  document.getElementById("errormsg").innerHTML = "";
};

const ForgotPassword = () => {
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 500);
    window.scrollTo(0, 0);
  }, []);

  /*
    To forgot password and set needed data in localstorage
  */
  const forgotPasswordUser = async (data) => {
    setDisable(true);
    var username = data.username;
    localStorage.setItem("userid", "");
    localStorage.setItem("event", "");
    localStorage.setItem("SendOtp", "emailid");
    let regMobile = /^[0-9]{7,10}$/;

    if (regMobile.test(data.username)) {
      data.contactno = data.username;
      data.email = null;
      localStorage.setItem("SendOtp", "contactno");
    } else {
      data.email = data.username;
      data.contactno = null;
    }

    var userdata = new Object();
    if (data.email) {
      userdata["email"] = data.email;
      userdata["contactno"] = null;
    }
    if (data.contactno) {
      userdata["contactno"] = data.contactno;
      userdata["email"] = null;
    }

    const res = await user.forgetpassword(userdata);
    if (res.data.status === 200) {
      localStorage.setItem("userid", res.data.payload);
      localStorage.setItem("event", "Forget Password OTP");

      localStorage.setItem("SendOtpOn", username);

      setTimeout(() => {
        setDisable(false);
        window.location.href = "/otpverification";
      }, 500);
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = res.data.errors.message
        ? res.data.errors.message
        : "Please enter valid Email/Mobile Number";
    }
  };

  /*
    To redirect to login page
  */
  const backlogin = () => {
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  return (
    <Container>
      <div className="ps-page form-loader">
        {disable ? (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        ) : (
          ""
        )}

        <GoogleCaptcha />
        <div className="container">
          <div className="ps-page__content ps-account ">
            <div className="row  ml-0 mr-0 justify-content-center">
              <div className="content-card mt-50 mb-50 box-rounded-login login align-self-center">
                <div className="ps-form--review form-padding">
                  <h2 className="ps-form__title text-center">Reset Password</h2>
                  <FormikFrom
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      forgotPasswordUser(values);
                    }}
                  >
                    <Form>
                      <InputField
                        label={"Enter Email / Mobile Number *"}
                        name={"username"}
                        type={"text"}
                        placeholder={"Username"}
                        maxLength={"50"}
                        id={"username"}
                        onKeyUp={reseterror}
                      />
                      <span className="color-red hide pt-10" id="errormsg"></span>
                      <div className="ps-form__submit" style={{ marginTop: "10px" }}>
                        <FormButton
                          name={"Continue"}
                          type="submit"
                          className={
                            disable ? "ps-btn ps-btn--warning width-100 disabled" : "ps-btn ps-btn--warning width-100"
                          }
                        />
                        <FormButton
                          name={"Back"}
                          type="button"
                          className={"ps-btn ps-btn--warning width-100 mt-10"}
                          onClick={backlogin}
                        />
                      </div>
                      <div className="row mt-10">
                        <div className="col-12 col-md-12 text-center">
                          <span className="linktext">Already have an account ?</span>
                          <Link to={"/login"}>
                            <span className="ps-account__link">Login</span>
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </FormikFrom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
