import React from "react";
import Slider from "react-slick";

import useProduct from "../../../../hooks/useProduct";
import ComboProduct from "../../products/ComboProduct";

const ModuleComboProduct = ({ comboproduct, currencyrate }) => {
  const { price, badges } = useProduct();

  let carouselitem = [];
  if (comboproduct && comboproduct.length > 0) {
    comboproduct.map((item) => {
      carouselitem.push(<ComboProduct product={item} currencyrate={currencyrate} />);
    });
  }

  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {comboproduct && comboproduct.length > 0 && (
        <>
          <div className="row mt-30">
            <div className="col-12">
              <label className="ps-product__label font-size-16 mb-20">Suggested Products</label>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...carouselSetting} className="ps-carousel home-slider">
                {carouselitem}
              </Slider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModuleComboProduct;
