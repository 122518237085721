/*
  To currency calculation 
*/
export function currencyPrice(price, currencyrate) {
  if (currencyrate) {
    var currencyprice = Number(price).toFixed(2);
    if (Number(currencyrate.id) > 1) {
      currencyprice = (Number(price) * Number(currencyrate.multiplierrate).toFixed(5)).toFixed(2);
    }
    if (Number(currencyprice) > 0 && currencyprice.indexOf(".") > -1) {
      if (currencyprice.split(".")[1] === "00") {
        currencyprice = currencyprice.split(".")[0];
      }
    }
    return currencyrate.displayicon + " " + currencyprice;
  }
}

/*
  To currency calculation for orde
*/
export function currencyOrdePrice(price, currencyrate) {
  if (currencyrate) {
    var currencyprice = Number(price).toFixed(2);
    if (currencyrate.currencyname !== "Rupee") {
      currencyprice = (Number(price) * Number(currencyrate.multiplierrate).toFixed(5)).toFixed(2);
    }
    if (Number(currencyprice) > 0 && currencyprice.indexOf(".") > -1) {
      if (currencyprice.split(".")[1] === "00") {
        currencyprice = currencyprice.split(".")[0];
      }
    }
    return currencyrate.displayicon + " " + currencyprice;
  }
}
