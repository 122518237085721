import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import NoDataFound from "../../components/common/NoDataFound";
import ModulEcomerceOrderSummary from "../../components/ecomerce/modules/ModulEcomerceOrderSummary";
import ModuleEcomerceCartSummary from "../../components/ecomerce/modules/ModuleEcomerceCartSummary";
import SkeletonTable from "../../components/elements/skeletons/SkeletonTable";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";
import CartParameter from "./CartParameter";
import OrderAddress from "./OrderAddress";

const OrderSummary = ({ ecomerce, cartdata, setCartlist, loading, billingaddress = {}, setHandelloader }) => {
  const { currencyrate, getcurrencyRate } = useEcomerce();

  const [applyCoupon, setapplyCoupon] = useState("");
  const [pecialPrice, setSpecialPrice] = useState(0);
  const [note, setNote] = useState(localStorage.getItem("note"));
  const [cartdatalist, setcartdata] = useState();
  const [proceedFlag, setProceedFlag] = useState(true);
  const [billingaddressdata, setBillingAddressData] = useState();

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();

  useEffect(() => {
    setBillingAddressData(billingaddress);
    setcartdata(cartdata);
    getcurrencyRate();
  }, [ecomerce, cartdata, cartdatalist, billingaddress]);

  useEffect(() => {
    setProceedFlag(true);
    var count = 0;
    if (cartdata && cartdata.length > 0) {
      cartdata.forEach((item) => {
        if (Number(item.stock) < Number(item.quantity)) {
          count = count + 1;
        }
      });
    }
    if (count > 0) {
      setProceedFlag(false);
    }
  }, [cartdata]);

  return (
    <div className="ps-page__content">
      <GoogleCaptcha />
      {cartdata && cartdata.length > 0 ? (
        <>
          <div className="ps-cart--primary">
            <div className="ps-cart__content">
              <div className="ps-cart__items card-box-shadow bn p-20 card-mobile-pending mb-30">
                <ModulEcomerceOrderSummary
                  cartItems={cartdata}
                  cartevent="checkout"
                  pecialPrice={pecialPrice}
                  handelcoupon={applyCoupon}
                  currencyrate={currencyrate}
                />
              </div>
              <div className="row ps-form--review" id="myaddress">
                <div className="col-12 col-md-6">
                  <h2 className="ps-form__title mb-30">Shipping Address</h2>
                  <OrderAddress />
                </div>
                <div className="col-12 col-md-6">
                  <h2 className="ps-form__title mb-30">Billing Address</h2>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-30">
                      <div className="card card-box-shadow bn">
                        <div className="card-header background-color-white">
                          <div className="">
                            <div className="row address-card">
                              <div className={"col-12 col-md-6"}>
                                <span>{billingaddressdata.billingname}</span>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <p className="title-font">
                              {billingaddressdata.billingaddress} {billingaddressdata.billingcity}{" "}
                              {billingaddressdata.billingstatename} {billingaddressdata.billingcountryname}
                              {" - "}
                              {billingaddressdata.billingpincode}
                            </p>
                            <p>
                              <span className="dark-blue">Mobile No : </span>
                              <span className="title-font">
                                {billingaddressdata.billingmobilecountry} {billingaddressdata.billingmobile}
                              </span>
                            </p>

                            <p>
                              <span className="dark-blue">Company Name :</span>
                              <span className="title-font">{billingaddressdata.billingcompanyname}</span>
                            </p>
                            <p>
                              <span className="dark-blue">GST :</span>
                              <span className="title-font">{billingaddressdata.billinggst}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {note && (
                  <div className="col-12 mb-30">
                    <h3>Customer Note</h3>
                    <div className="title-font word-break-all">{note}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="ps-cart__order-summary">
              <ModuleEcomerceCartSummary
                cartItems={cartdata}
                cartevent={"payment"}
                sameshippingdata={searchparam.urlsameshipping}
                setapplyCoupon={setapplyCoupon}
                setSpecialPrice={setSpecialPrice}
                currencyrate={currencyrate}
                onSetCartlist={setCartlist}
                setHandelloader={setHandelloader}
                proceedFlag={proceedFlag}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <div className="ps-cart--primary">
              <div className="ps-cart__content">
                <div className="ps-cart__items">
                  <SkeletonTable rows={2} />
                </div>
              </div>
              <div className="ps-cart__order-summary">
                <ModuleEcomerceCartSummary
                  cartItems={cartdata}
                  cartevent={"payment"}
                  sameshippingdata={searchparam.urlsameshipping}
                  setapplyCoupon={setapplyCoupon}
                  setSpecialPrice={setSpecialPrice}
                  currencyrate={currencyrate}
                  onSetCartlist={setCartlist}
                  setHandelloader={setHandelloader}
                />
              </div>
            </div>
          ) : (
            <NoDataFound component={"cart"} />
          )}
        </>
      )}
    </div>
  );
};

export default connect((state) => state)(OrderSummary);
