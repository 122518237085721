import { connect } from "react-redux";
import Slider from "react-slick";

import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import Product from "../../components/elements/products/Product";

const SimilarProduct = ({ likeproduct, currencyrate }) => {
  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    arrows: false,
    dots: false,
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <h3>Products you may like</h3>
      <div
        className="ps-layout--grid ps-shop-items bn shop-items-list similar-product mt-30 mobile-hide"
        data-columns={5}>
        {likeproduct &&
          likeproduct.length > 0 &&
          likeproduct.map((item) => (
            <div className="ps-layout__item card-box-shadow bn " key={item.id}>
              <Product product={item} currencyrate={currencyrate} />
            </div>
          ))}
      </div>
      <div className="mobile-show">
        <Slider {...carouselSetting} className="ps-carousel similarproduct">
          {likeproduct &&
            likeproduct.length > 0 &&
            likeproduct.map((item) => (
              <div
                className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list similar-product"
                key={item.id}>
                <div className="ps-category__item m-5">
                  <div className="ps-layout__item card-box-shadow bn ">
                    <Product product={item} currencyrate={currencyrate} />
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
};
export default connect((state) => state)(SimilarProduct);
