import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import ProductRepository from "../../../api/ProductRepository";
import ProductOnCart from "../../../components/elements/products/ProductOnCart";
import useEcomerce from "../../../hooks/useEcomerce";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import { VarientKeyName, VarientName } from "../../../pages/product/VariantKey";
import ModuleDetailMaterial from "../../elements/detail/modules/ModuleDetailMaterial";

const ModuleEcomerceWishlist = ({ ecomerce, source, wishlistevent = "", currencyrate }) => {
  var varientobject = new Object();

  const { addItem, removeItem } = useEcomerce();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [productItem, setProductItem] = useState("");
  const [productvariant, setProductvariant] = useState(null);

  const [colorname, setColorname] = useState("");
  const [sizename, setSizename] = useState("");
  const [materialname, setMaterialname] = useState("");
  const [weightname, setWeightname] = useState("");
  const [flavourname, setFlavourName] = useState("");

  const [isColor, setColor] = useState([]);
  const [isSize, setSize] = useState([]);
  const [isMaterial, setMaterial] = useState([]);
  const [isWeight, setWeight] = useState([]);
  const [isflavour, setFlavour] = useState([]);

  const [iskeyarray, setKeyArray] = useState([]);
  const [isvariantname, setVariantname] = useState([]);
  const [isVarientobject, setVarientobject] = useState();
  let color = [];
  let size = [];
  let material = [];
  let weight = [];
  let flavour = [];

  let variantname = "";

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set json in array 
  */

  const setVariantKey = () => {
    if (variantname === "") {
      if (productvariant && productvariant.length > 0) {
        setKeyArray(VarientKeyName(productvariant));
      }
    }
  };

  /*
    To make variant name by selected variant  
  */
  const setVariantName = () => {
    if (iskeyarray && iskeyarray.length > 0) {
      variantname = VarientName(iskeyarray, colorname, sizename, materialname, weightname, flavourname);
      setVariantname(variantname);
    }
    let result = "";
    var flag = false;
    if (variantname && variantname !== null && variantname !== "") {
      result = variantname.slice(0, -1);
      var j = 0;
      productvariant.map((item, index) => {
        if (item.variantname === result) {
          j = index;
          flag = true;
        }
      });

      if (flag) {
        varientobject["varientdisable"] = "Yes";
        productItem["price"] = productvariant[j].price;
        productItem["stock"] = productvariant[j].stock;
        productItem["offerprice"] = productvariant[j].offerprice;
        productItem["offertype"] = productvariant[j].offertype;
        if (Number(productvariant[j].stock) > 0) {
          varientobject["productid"] = productvariant[j].productid;
          varientobject["productvariantid"] = productvariant[j].id;
          varientobject["quantity"] = 1;
          varientobject["wishlistid"] = productItem.id;
          varientobject["varientdisable"] = "No";
        }
        varientobject["displaystock"] = "Yes";
        setVarientobject(varientobject);
      }
    }
  };

  /*
    To remove wishlist item
  */
  function handleRemoveItem(e, wishlistid) {
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      removeItem({ wishlistid: wishlistid }, ecomerce.wishlistItems, "wishlist");
    } else {
      navigate("/login");
    }
  }

  /*
    To add to cart 
  */
  async function handleAddItemToCart(e, product) {
    varientobject = new Object();
    setVarientobject("");
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      if (product.hasvariant !== null && product.hasvariant !== "" && product.hasvariant === "1") {
        setColorname("");
        setSizename("");
        setMaterialname("");
        setProductItem(product);
        const responseData = await ProductRepository.getProductVariant(product.uniqueid);
        if (responseData) {
          setProductvariant(responseData);
          setVariantKey();
          setVarient(responseData);
        }

        setShow(true);
        document.body.classList.remove("overflow-auto");
      } else {
        addItem(
          {
            productid: product.productid,
            quantity: 1,
            productvariantid: 0,
            wishlistid: product.id,
          },
          ecomerce.cartItems,
          "cart"
        );
      }
    } else {
      navigate("/login");
    }
  }

  /*
    To close model
  */
  const handleClose = () => setShow(false);

  useEffect(() => {
    varientobject["varientdisable"] = "Yes";
    setVariantKey();
    setVariantName();
    setVarientobject(varientobject);
  }, [isvariantname, productvariant, colorname, sizename, materialname, weightname, flavourname]);

  /*
    To variants set in array 
  */
  const setVarient = (productvariantlist) => {
    if (productvariantlist && productvariantlist.length > 0) {
      productvariantlist.map((item) => {
        var variantjson = JSON.parse(item.variantjson);
        if (variantjson && variantjson !== null && variantjson !== "") {
          if (variantjson.Colour && variantjson.Colour !== null && variantjson.Colour !== "") {
            color.push(variantjson.Colour);
          }
          if (variantjson.Size && variantjson.Size !== null && variantjson.Size !== "") {
            size.push(variantjson.Size);
          }
          if (variantjson.Material && variantjson.Material !== null && variantjson.Material !== "") {
            material.push(variantjson.Material);
          }
          if (variantjson.Weight && variantjson.Weight !== null && variantjson.Weight !== "") {
            weight.push(variantjson.Weight);
          }
          if (variantjson.Flavour && variantjson.Flavour !== null && variantjson.Flavour !== "") {
            flavour.push(variantjson.Flavour);
          }
        }
      });

      if (color && color.length > 0) {
        const setData = new Set(color);
        color = Array.from(setData);
        setColor(color);
      }

      if (size && size.length > 0) {
        const setData = new Set(size);
        size = Array.from(setData);
        setSize(size);
      }

      if (material && material.length > 0) {
        const setData = new Set(material);
        material = Array.from(setData);
        setMaterial(material);
      }
      if (weight && weight.length > 0) {
        const setData = new Set(weight);
        weight = Array.from(setData);
        setWeight(weight);
      }
      if (flavour && flavour.length > 0) {
        const setData = new Set(flavour);
        flavour = Array.from(setData);
        setFlavour(flavour);
      }
    }
  };

  /*
    To add to cart with variant product
  */
  function addToCart(e) {
    e.preventDefault();

    if (isVarientobject.varientdisable === "No") {
      addItem(
        {
          productid: isVarientobject.productid,
          quantity: 1,
          productvariantid: isVarientobject.productvariantid,
          wishlistid: isVarientobject.wishlistid,
        },
        ecomerce.cartItems,
        "cart"
      );
      setShow(false);
      handleClose();
    }
  }

  return (
    <>
      <table id="tablewishlist" className="table ps-table ps-table--shopping-cart ps-table--responsive">
        {source && source.length > 0 ? (
          <tbody>
            {source.map((item) => (
              <tr key={item.id}>
                <td className="wishlist-td-productname text-left">
                  <ProductOnCart product={item} currencyrate={currencyrate} />
                </td>
                <td className="wishlisttd text-center align-self-center order-space-mobile">
                  {item.productstatus === "unpublished" ? (
                    <div className="ps-status outstock">
                      <i className="fa fa-close mr-2"></i>
                      Not available
                    </div>
                  ) : (
                    <>
                      {item.productstockavailable === "YES" ? (
                        <div className="ps-status instock">
                          <i className="fa fa-check mr-2"></i>
                          In Stock
                        </div>
                      ) : (
                        <div className="ps-status outstock">
                          <i className="fa fa-close mr-2"></i>
                          Sold Out
                        </div>
                      )}
                    </>
                  )}
                </td>
                <td
                  className={
                    wishlistevent === "mywishlist"
                      ? "wishlisttd text-center align-self-center"
                      : "vertical-align-middle text-center align-self-center"
                  }
                >
                  <div className="row">
                    <div className="col-12">
                      {item.productstockavailable === "YES" && item.productstatus === "published" ? (
                        <a
                          className="ps-btn ps-btn--orange ps-btn--sm font-size-16"
                          href="#"
                          onClick={(e) => handleAddItemToCart(e, item)}
                        >
                          Move to cart
                        </a>
                      ) : (
                        <a
                          className="ps-btn ps-btn--orange ps-btn--sm disabled disable-btn-color font-size-16"
                          href="#"
                        >
                          Move to cart
                        </a>
                      )}
                    </div>
                  </div>
                </td>
                <td className="wishlisttd text-center align-self-center">
                  <a className="ps-icon ps-cart-item__remove" href="#" onClick={(e) => handleRemoveItem(e, item.id)}>
                    <i className="fa fa-trash mr-2 color-red"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={5} className="text-left">
                No product found.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div>
        <Modal show={show} onHide={handleClose} className="modal-width" id="productform">
          <Modal.Header className="pt-15 pb-15" closeButton>
            <h2 className="ps-form__title font-size-24 mb-0">Choose Product Variant</h2>
          </Modal.Header>
          <Formik>
            {(formik) => {
              const { errors, touched, values } = formik;
              return (
                <Form>
                  <Modal.Body>
                    <div className="ps-form--review">
                      <div className="col-12">
                        <div className="row mt-20">
                          <div className="col-12">
                            <ProductOnCart
                              product={productItem}
                              varientobject={isVarientobject}
                              cartevent={"varientproduct"}
                              currencyrate={currencyrate}
                            />
                          </div>
                        </div>
                        <div className="row mt-20 mb-20">
                          <div className="col-12 pl-40">
                            {iskeyarray &&
                              iskeyarray.length > 0 &&
                              iskeyarray.map((item) => (
                                <div className="ps-product__variants">
                                  {item === "Colour" && (
                                    <ModuleDetailMaterial
                                      array={isColor}
                                      setname={setColorname}
                                      name="color"
                                      lable="Color"
                                    />
                                  )}
                                  {item === "Size" && (
                                    <ModuleDetailMaterial
                                      array={isSize}
                                      setname={setSizename}
                                      name="size"
                                      lable="Size"
                                    />
                                  )}
                                  {item === "Material" && (
                                    <ModuleDetailMaterial
                                      array={isMaterial}
                                      setname={setMaterialname}
                                      name="material"
                                      lable="Material"
                                    />
                                  )}
                                  {item === "Weight" && (
                                    <ModuleDetailMaterial
                                      array={isWeight}
                                      setname={setWeightname}
                                      name="weight"
                                      lable="Weight"
                                    />
                                  )}
                                  {item === "Flavour" && (
                                    <ModuleDetailMaterial
                                      array={isflavour}
                                      setname={setFlavourName}
                                      name="flavour"
                                      lable="Flavour"
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className={
                        isVarientobject.varientdisable === "Yes"
                          ? "ps-btn ps-btn--warning disabled disable-btn-color"
                          : "ps-btn ps-btn--warning "
                      }
                      onClick={(e) => {
                        if (isVarientobject.varientdisable !== "Yes") {
                          addToCart(e);
                        }
                      }}
                    >
                      Choose
                    </button>
                    <Button variant="secondary" className="ps-btn ps-btn--warning" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default connect((state) => state)(ModuleEcomerceWishlist);
