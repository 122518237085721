import config from "../../config/config.json";

/*
  To set url parameter in json for get product
*/
export function ProductJson(searchParams) {
  let page_no = searchParams.get("pageno");
  let sortby = searchParams.get("sortby");

  let minimumprice = searchParams.get("minimumprice");
  let maximumprice = searchParams.get("maximumprice");
  let category = searchParams.get("category");
  let filters = searchParams.get("filters");
  let rating = searchParams.get("rating");
  let upperlimit = searchParams.get("upperlimit");
  let searchkey = searchParams.get("searchkey");
  let parentcategory = searchParams.get("parentcategory");
  let sale = searchParams.get("sale");
  let tags = searchParams.get("tags");

  var productdata = new Object();

  if (!page_no) {
    page_no = 1;
  }
  productdata["pageno"] = page_no;
  if (sortby) {
    productdata["sortby"] = sortby;
  }
  if (minimumprice) {
    productdata["minimumprice"] = minimumprice;
  }
  if (maximumprice) {
    productdata["maximumprice"] = maximumprice;
  }

  if (category === null || category === "") {
    if (parentcategory) {
      category = parentcategory;
    }
  }
  if (category) {
    productdata["category"] = category;
  }
  if (filters) {
    productdata["filters"] = filters;
  }
  if (rating) {
    productdata["rating"] = rating;
  }
  if (!upperlimit) {
    upperlimit = config.upperlimit;
  }
  productdata["upperlimit"] = upperlimit;

  if (searchkey) {
    productdata["searchkey"] = searchkey;
  }
  if (!sale) {
    sale = "0";
  }
  if (tags) {
    productdata["tags"] = tags;
  }
  productdata["displayonlysale"] = sale;
  return productdata;
}

/*
  To set url parameter set for display in browser
*/
export function UrlProduct(searchParams, urlevent) {
  let sortby = searchParams.get("sortby");

  let minimumprice = searchParams.get("minimumprice");
  let maximumprice = searchParams.get("maximumprice");
  let category = searchParams.get("category");
  let parentcategory = searchParams.get("parentcategory");
  let filters = searchParams.get("filters");
  let rating = searchParams.get("rating");
  let upperlimit = searchParams.get("upperlimit");
  let searchkey = searchParams.get("searchkey");
  let layout = searchParams.get("layout");
  let columns = searchParams.get("columns");
  var url = "/shop?";

  let sale = searchParams.get("sale");

  if (urlevent !== "pagination") {
    url = url + "&page_no=1";
  }

  if (urlevent !== "sortby") {
    if (sortby && sortby !== null && sortby !== "") {
      url = url + "&sortby=" + sortby;
    }
  }

  if (urlevent !== "layout") {
    if (columns && columns !== null && columns !== "") {
      url = url + "&columns=" + columns;
    }

    if (layout && layout !== null && layout !== "") {
      url = url + "&layout=" + layout;
    }
  }

  if (urlevent !== "price") {
    if (minimumprice && minimumprice !== null && minimumprice !== "") {
      url = url + "&minimumprice=" + minimumprice;
    }

    if (maximumprice && maximumprice !== null && maximumprice !== "") {
      url = url + "&maximumprice=" + maximumprice;
    }
  }

  if (urlevent !== "category") {
    if (category && category !== null && category !== "") {
      url = url + "&category=" + category;
    }
  }
  if (urlevent !== "parentcategory") {
    if (parentcategory && parentcategory !== null && parentcategory !== "") {
      url = url + "&parentcategory=" + parentcategory;
    }
  }
  if (urlevent !== "filter") {
    if (filters && filters !== null && filters !== "") {
      url = url + "&filters=" + filters;
    }
  }

  if (urlevent !== "rating") {
    if (rating && rating !== null && rating !== "") {
      url = url + "&rating=" + rating;
    }
  }

  if (urlevent !== "upperlimit") {
    if (!upperlimit) {
      url = url + "&upperlimit=" + config.upperlimit;
    } else {
      url = url + "&upperlimit=" + upperlimit;
    }
  }

  if (urlevent !== "search") {
    if (searchkey && searchkey !== null && searchkey !== "") {
      url = url + "&searchkey=" + searchkey;
    }
  }
  if (urlevent !== "sale") {
    if (sale) {
      url = url + "&sale=" + sale;
    }
  }
  return url;
}
