import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoDataFound from "../../components/common/NoDataFound";
import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import NavigationLeft from "../../components/shared/navigations/NavigationLeft";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import useEcomerce from "../../hooks/useEcomerce";
import LocalstorageParam from "../common/LocalstorageParam";
import OrderItemList from "./OrderItemList";
import PastOrderItemList from "./PastOrderItemList";

const OrderList = ({ ecomerce }) => {
  const { loading, orderlist, getOrderlist } = useEcomerce();
  const [selected, setSelected] = useState("current");

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const navigate = useNavigate();

  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "My Account",
      url: "/profile",
    },
    {
      id: 3,
      text: "Order",
    },
  ];

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      getOrderlist();
      window.scrollTo(0, 0);
    } else {
      navigate("/login");
    }
  }, [ecomerce]);

  /*
    To order list views
  */
  let orderContentView;
  if (orderlist && orderlist.length > 0) {
    orderContentView = <OrderItemList orderItem={orderlist} />;
  } else {
    orderContentView = <NoDataFound component={"order"} />;
  }

  /*
    To select current and past order
  */
  const handleChangeTab = (event) => {
    setSelected(event);
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="ps-page ps-page--shopping">
          <div className="container">
            <div className="ps-page__header">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="ps-page__content">
              <div className="ps-layout--with-sidebar myaccount">
                <div className="ps-layout__left">
                  <NavigationLeft menuname={"Orders"} />
                </div>
                <div className="ps-layout__right width-100">
                  <div className="row">
                    <div className="col-12 col-md-12 ps-form--review orderlist">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <h2 className="ps-form__title">My Orders</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-5"></div>
                        <div className="text-center ordertab">
                          <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className={selected === "current" && "active"}>
                              <a
                                href="javascript:;"
                                aria-controls="home"
                                role="tab"
                                data-toggle="tab"
                                onClick={(e) => {
                                  handleChangeTab("current");
                                }}
                              >
                                Current Orders
                              </a>
                            </li>
                            <li role="presentation" className={selected === "past" && "active"}>
                              <a
                                href="javascript:;"
                                aria-controls="profile"
                                role="tab"
                                data-toggle="tab"
                                onClick={(e) => {
                                  handleChangeTab("past");
                                }}
                              >
                                Past Orders
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-5"></div>
                      </div>
                      <div className="tab-content">
                        <div
                          role="tabpanel"
                          className={selected === "current" ? "tab-pane active" : "tab-pane "}
                          id="home"
                        >
                          <div className="ps-cart__content">
                            <div className="ps-cart__items">{orderContentView}</div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className={selected === "past" ? "tab-pane active" : "tab-pane "}
                          id="profile"
                        >
                          <div className="ps-cart__content">
                            <PastOrderItemList orderItem={orderlist} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default connect((state) => state)(OrderList);
