import React from "react";

import Logo from "../../../../components/elements/basic/Logo";
import shopcofig from "../../../../config/shopconfig.json";
const ModuleFooterAddress = () => {
  return (
    <div className="ps-footer--address">
      <Logo />
      <div className="ps-footer__title">Our store</div>
      <h4>CDS Valsad</h4>
      <p className="footer-font">
        {shopcofig.shop_address}
        {shopcofig.shop_city} {shopcofig.shop_pincode} {shopcofig.shop_state}, {shopcofig.shop_country}
      </p>
      <p>
        <a href={shopcofig.maplink} target="_blank" className="footer-font">
          Show on map
        </a>
      </p>
      <h4 className="mt-30">CDS Vapi</h4>
      <p className="footer-font footer-new-add" style={{ marginTop: 20 }}>
        CDS - Charliee Day To Day Stores, Shop No. 1, 2, 3, 4, Opp. Ashadham School, Koparli Road, Vapi Station Road, .
        Vapi (East), Gujarat, India
      </p>
      <p>
        <a href={"https://maps.app.goo.gl/qJTcFSr5ZFnkSVnn9"} target="_blank" className="footer-font">
          Show on map
        </a>
      </p>
      <ul className="ps-social">
        {shopcofig.facebook ? (
          <li>
            <a className="ps-social__link facebook footer-icon" href={shopcofig.facebook}>
              <i className="fa fa-facebook"> </i>
              <span className="ps-tooltip">Facebook</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.instagram ? (
          <li>
            <a className="ps-social__link instagram footer-icon" href={shopcofig.instagram}>
              <i className="fa fa-instagram"></i>
              <span className="ps-tooltip">Instagram</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.youtube ? (
          <li>
            <a className="ps-social__link youtube footer-icon" href={shopcofig.youtube}>
              <i className="fa fa-youtube-play"></i>
              <span className="ps-tooltip">Youtube</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.pinterest ? (
          <li>
            <a className="ps-social__link pinterest footer-icon" href={shopcofig.pinterest}>
              <i className="fa fa-pinterest-p"></i>
              <span className="ps-tooltip">Pinterest</span>
            </a>
          </li>
        ) : (
          ""
        )}
        {shopcofig.linkedin ? (
          <li>
            <a className="ps-social__link linkedin footer-font" href={shopcofig.linkedin}>
              <i className="fa fa-linkedin"></i>
              <span className="ps-tooltip">Linkedin</span>
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
      <div className={"appstore"} style={{ display: "flex", flexDirection: "row", flex: 1, marginTop: 20 }}>
        <a href="https://apps.apple.com/in/app/cds-stores/id6459021003">
          <img src="/static/img/Apple_store.png" alt="" height={50} style={{ marginRight: 10 }} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ispl.ecomm&hl=en">
          <img src="/static/img/google_play.png" alt="" height={50} />
        </a>
      </div>
    </div>
  );
};

export default ModuleFooterAddress;
