import React from "react";

const FormButton = ({ name, type, className, onClick }) => {
  return (
    <React.Fragment>
      <button type={type} className={className} onClick={onClick}>
        {name}
      </button>
    </React.Fragment>
  );
};
export default FormButton;
