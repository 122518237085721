import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";

import ProductRepository from "../../../../api/ProductRepository";
import config from "../../../../config/config.json";
import LocalstorageParam from "../../../../pages/common/LocalstorageParam";
import NoDataFound from "../../../common/NoDataFound";
import FormikFrom from "../../form/FormikFrom";

const ModuleDetailReviews = ({ productReview, product, productview }) => {
  const [rating, setRating] = useState(0);
  const [disable, setDisable] = useState(false);
  const [decription, setDecription] = useState("");

  /*
    To set rating in state variable
  */
  const handleRating = (rate) => {
    setRating(rate);
    document.getElementById("errormsg").innerHTML = "";
  };

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  useEffect(() => {}, [rating]);

  /*
    To save product review
  */
  var reviewobject = new Object();
  const saveRating = async () => {
    document.getElementById("successmsg").classList.add("hide");
    setDisable(true);
    if (Number(rating) > 0) {
      document.getElementById("errormsg").innerHTML = "";

      reviewobject["productid"] = product.id;
      reviewobject["rating"] = rating;
      if (decription) {
        reviewobject["review"] = decription;
      }
      const res = await ProductRepository.saveProductReview(reviewobject);
      if (res.data.status === 200) {
        document.getElementById("successmsg").classList.remove("hide");
        setDecription("");
        handleRating(0);

        setTimeout(() => {
          setDisable(false);
        }, 500);
      }
    } else {
      document.getElementById("errormsg").innerHTML = "Please select rating";
    }
  };

  return (
    <div className="tab-pane fade active show" id="reviews-content" role="tabpanel" aria-labelledby="reviews-tab">
      <div className="row form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}
        <div className="col-12 col-md-7">
          <div className="ps-product__tabreview card-box-shadow">
            {productReview.productreviewlist && productReview.productreviewlist.length > 0 ? (
              productReview.productreviewlist.map((item) => (
                <div className="ps-review--product productreview">
                  <div className="row">
                    <div className="col-3 col-md-2 pr-0">
                      <div className="product-review-image">
                        <img
                          src={
                            item.imagename !== null && item.imagename !== ""
                              ? config.apiUrl + config.image_path + item.imagename
                              : "/static/img/static_image/user_image.png"
                          }
                          alt="alt"
                          className="img-responsive review-user-img"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-10 pl-0">
                      <div className="ps-review__info pr-0">
                        <div className="row">
                          <div className="col-12 product-review">
                            <span className="ps-review__name font-size-16 ">
                              {item.firstname} {item.lastname}
                            </span>
                            <span className="ps-review__rating reviewstar">
                              <Rating allowFraction initialValue={item.rating} readonly size={25} />
                            </span>
                          </div>
                        </div>
                      </div>

                      <p className="ps-review__date ps-review__desc mb-10">{item.reviewdate}</p>
                      <div className="ps-review__desc">
                        <p className="title-font">{item.review}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoDataFound component={"productreview"} />
            )}
          </div>
        </div>
        <div className="col-12 col-md-5">
          {productview === "detail" &&
            localparam !== "" &&
            localparam.decoded !== "" &&
            localparam.decoded.username !== null &&
            localparam.decoded.username !== "" && (
              <div className="ps-form--review reviewform card-box-shadow bn">
                <div className="row">
                  <div className="col-12 color-green hide font-size-14 mb-10" id="successmsg">
                    Your review has been submitted successfully
                  </div>
                </div>
                <div className="ps-form__title">Write a review</div>
                <FormikFrom>
                  <Form>
                    <div className="row mt-30">
                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <label className="ps-form__label mr-2">Your rating *</label>
                          <Rating initialValue={rating} allowFraction size={25} onClick={handleRating} />
                        </div>
                        <span id="errormsg" className="color-red"></span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="ps-form__label">Your review </label>
                          <textarea
                            className="form-control ps-form__textarea"
                            placeholder="Your review"
                            value={decription}
                            onChange={(e) => {
                              setDecription(e.target.value);
                            }}
                            maxLength="500"></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 text-center mt-20">
                      <button
                        type="button"
                        className="btn ps-btn ps-btn--warning"
                        onClick={(e) => {
                          saveRating();
                        }}>
                        Add Review
                      </button>
                    </div>
                  </Form>
                </FormikFrom>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ModuleDetailReviews;
