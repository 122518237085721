import axios from "axios";
import { encode as base64_encode } from "base-64";

import config from "../config/config.json";

/*
   To authenticate user login
*/
const login = async (logindata) => {
  const data = new FormData();
  data.append("username", logindata.username);
  data.append("password", logindata.password);
  data.append("grant_type", "password");

  let encoded = base64_encode(config.oauth_username + ":" + config.oauth_password);
  const headers = {
    Accept: "*/*",
    Authorization: "Basic " + encoded,
  };

  const url = config.apiUrl + config.apiurlname + "/oauth/token";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
   To refresh authenticate token
*/
const refreshtoken = async () => {
  const data = new FormData();
  data.append("refresh_token", localStorage.getItem("refresh_token"));
  data.append("grant_type", "refresh_token");

  let encoded = base64_encode(config.oauth_username + ":" + config.oauth_password);
  const headers = {
    Accept: "*/*",
    Authorization: "Basic " + encoded,
  };

  const url = config.apiUrl + config.apiurlname + "/oauth/token";
  const resp = await axios
    .post(url, data, { headers })
    .then((response) => {
      localStorage.setItem("authToken", response.data.access_token);
      localStorage.setItem("logindate", new Date());
      localStorage.setItem("expires_in", response.data.expires_in);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

/*
   To google login
*/
const googlelogin = async (access_token) => {
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: "application/json",
  };

  const url = "https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + access_token;
  const resp = await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return resp;
};

export default {
  login,
  refreshtoken,
  googlelogin,
};
