import React from "react";

const ModuleDetailMeta = ({ product }) => {
  return (
    <div className="ps-product__specification">
      {product && product.tagslist && product.tagslist.length > 0 && (
        <strong className="title-font">
          <figure className="ps-product__sizes">
            <figcaption className="ps-product__label font-size-16">Tags:</figcaption>
            {product.tagslist.map((item) => (
              <div className="radio_container">
                <label className="title-font cursor-alias">{item.tagsname}</label>
              </div>
            ))}
          </figure>
        </strong>
      )}
      {product.hsn && (
        <p>
          <strong>HSN: </strong>
          <span className="title-font">{product.hsn}</span>
        </p>
      )}
    </div>
  );
};

export default ModuleDetailMeta;
