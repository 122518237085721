import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button, Form } from "antd";
import { Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import ProductRepository from "../../../api/ProductRepository";
import useEcomerce from "../../../hooks/useEcomerce";
import useProduct from "../../../hooks/useProduct";
import LocalstorageParam from "../../../pages/common/LocalstorageParam";
import { VarientKeyName, VarientName } from "../../../pages/product/VariantKey";
import ModuleDetailMaterial from "../detail/modules/ModuleDetailMaterial";
import ProductOnCart from "./ProductOnCart";
import ModuleProductActions from "./modules/ModuleProductActions";
import ModuleProductImages from "./modules/ModuleProductImages";

const ComboProduct = ({ product, currencyrate, ecomerce }) => {
  var varientobject = new Object();
  const { price, badges } = useProduct();
  const [quantity, setQuantity] = useState(1);
  const { addItem } = useEcomerce();
  const [disable, setDisable] = useState(false);
  const [productvariant, setProductvariant] = useState();
  const [isVarientobject, setVarientobject] = useState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [productItem, setProductItem] = useState("");

  const [colorname, setColorname] = useState("");
  const [sizename, setSizename] = useState("");
  const [materialname, setMaterialname] = useState("");
  const [weightname, setWeightname] = useState("");
  const [flavourname, setFlavourName] = useState("");

  const [isColor, setColor] = useState([]);
  const [isSize, setSize] = useState([]);
  const [isMaterial, setMaterial] = useState([]);
  const [isWeight, setWeight] = useState([]);
  const [isflavour, setFlavour] = useState([]);
  const [productStock, setProductStock] = useState(product ? product.stock : 0);

  const [iskeyarray, setKeyArray] = useState([]);
  const [isvariantname, setVariantname] = useState([]);

  let color = [];
  let size = [];
  let material = [];
  let weight = [];
  let flavour = [];

  let variantname = "";

  useEffect(() => {
    varientobject["varientdisable"] = "Yes";
    setVariantKey();
    setVariantName();
    setVarientobject(varientobject);
  }, [isvariantname, productvariant, colorname, sizename, materialname, weightname, flavourname]);

  /*
    To close model
  */
  const handleClose = () => {
    setShow(false);
  };

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  const setVariantKey = () => {
    if (variantname === "") {
      if (productvariant && productvariant.length > 0) {
        setKeyArray(VarientKeyName(productvariant));
      }
    }
  };

  const setVariantName = () => {
    if (iskeyarray && iskeyarray.length > 0) {
      variantname = VarientName(iskeyarray, colorname, sizename, materialname, weightname, flavourname);
      setVariantname(variantname);
    }
    let result = "";
    var flag = false;
    if (variantname && variantname !== null && variantname !== "") {
      result = variantname.slice(0, -1);
      var j = 0;
      productvariant.map((item, index) => {
        if (item.variantname === result) {
          j = index;
          flag = true;
        }
      });

      if (flag) {
        varientobject["varientdisable"] = "Yes";
        productItem["price"] = productvariant[j].price;
        productItem["stock"] = productvariant[j].stock;
        productItem["offerprice"] = productvariant[j].offerprice;
        productItem["offertype"] = productvariant[j].offertype;
        if (Number(productvariant[j].stock) > 0) {
          varientobject["productid"] = productvariant[j].productid;
          varientobject["productvariantid"] = productvariant[j].id;
          varientobject["quantity"] = 1;
          varientobject["wishlistid"] = productItem.id;
          varientobject["varientdisable"] = "No";
        }
        varientobject["displaystock"] = "Yes";
        setVarientobject(varientobject);
      }
    }
  };

  /*
    To add item to cart
  */
  const handleAddItemToCart = async (e) => {
    varientobject = new Object();
    setVarientobject("");
    e.preventDefault();
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.username !== null &&
      localparam.decoded.username !== ""
    ) {
      const responseData = await ProductRepository.getProductVariant(product.uniqueid);
      if (responseData.length > 0) {
        setColorname("");
        setSizename("");
        setMaterialname("");
        setProductvariant(responseData);
        setVariantKey();
        setVarient(responseData);
        setProductItem(product);

        setShow(true);
        document.body.classList.remove("overflow-auto");
      } else {
        if (!disable) {
          await addItem(
            {
              productid: product.id,
              quantity: quantity,
              // productvariantid: product.variantid,
            },
            ecomerce.cartItems,
            "cart"
          );
        }
      }
    } else {
      navigate("/login");
    }
  };

  /*
    To variants set in array 
  */
  const setVarient = (productvariantlist) => {
    if (productvariantlist && productvariantlist.length > 0) {
      productvariantlist.map((item) => {
        var variantjson = JSON.parse(item.variantjson);
        if (variantjson && variantjson !== null && variantjson !== "") {
          if (variantjson.Colour && variantjson.Colour !== null && variantjson.Colour !== "") {
            color.push(variantjson.Colour);
          }
          if (variantjson.Size && variantjson.Size !== null && variantjson.Size !== "") {
            size.push(variantjson.Size);
          }
          if (variantjson.Material && variantjson.Material !== null && variantjson.Material !== "") {
            material.push(variantjson.Material);
          }
          if (variantjson.Weight && variantjson.Weight !== null && variantjson.Weight !== "") {
            weight.push(variantjson.Weight);
          }
          if (variantjson.Flavour && variantjson.Flavour !== null && variantjson.Flavour !== "") {
            flavour.push(variantjson.Flavour);
          }
        }
      });

      if (color && color.length > 0) {
        const setData = new Set(color);
        color = Array.from(setData);
        setColor(color);
      }

      if (size && size.length > 0) {
        const setData = new Set(size);
        size = Array.from(setData);
        setSize(size);
      }

      if (material && material.length > 0) {
        const setData = new Set(material);
        material = Array.from(setData);
        setMaterial(material);
      }
      if (weight && weight.length > 0) {
        const setData = new Set(weight);
        weight = Array.from(setData);
        setWeight(weight);
      }
      if (flavour && flavour.length > 0) {
        const setData = new Set(flavour);
        flavour = Array.from(setData);
        setFlavour(flavour);
      }
    }
  };

  /*
    To increase item qty
  */
  const handleIncreaseItemQty = (e) => {
    e.preventDefault();
    let qty = Number(quantity) + 1;
    setQuantity(qty);
    if (Number(product.stock) >= qty) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  /*
      To decrease item qty
    */
  const handleDecreaseItemQty = (e) => {
    e.preventDefault();
    let qty;
    if (quantity > 1) {
      qty = Number(quantity) - 1;
      setQuantity(qty);
    }
    if (qty >= 1) {
      if (Number(product.stock) >= qty) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  };

  /*
    To add to cart with variant product
  */
  function addToCart(e) {
    e.preventDefault();

    if (isVarientobject.varientdisable === "No") {
      addItem(
        {
          productid: isVarientobject.productid,
          quantity: 1,
          productvariantid: isVarientobject.productvariantid,
          wishlistid: isVarientobject.wishlistid,
        },
        ecomerce.cartItems,
        "cart"
      );
      setShow(false);
      handleClose();
    }
  }

  return (
    <>
      <div className="ps-layout__item bn mt-10 p-1" key={product.id}>
        <div className="ps-product ps-product--grid card-box-shadow">
          <div className="ps-product ps-product--grid">
            <div className="ps-product__thumbnail p-0 product-combo-image">
              <Link to={`/product/${product.uniqueid}`} className="ps-product__overlay"></Link>
              <ModuleProductImages product={product} />
              <ModuleProductActions product={product} />
              {badges(product)}
            </div>
            <div className="text-center product-combo">
              <h4 className="ps-product__title title-font">
                <Link to={`/product/${product.uniqueid}`} className="producttitle combo-product-font p-1">
                  {product.productname}
                </Link>
              </h4>
              {price(product, currencyrate)}
            </div>

            <div className="col-md-12 pl-0 pr-0 addto-cart-section">
              {productStock > 0 ? (
                <div className="row mb-25 text-center margin-horizontal-10 combo-add">
                  <div className="col-md-12 col-lg-12 col-12 text-center mt-10">
                    <div className="form-group--number qty-btn">
                      <button className="up" onClick={(e) => handleIncreaseItemQty(e)}></button>
                      <button id="decrease-btn" className="down" onClick={(e) => handleDecreaseItemQty(e)}></button>
                      <input className="form-control" type="text" placeholder={quantity} disabled />
                    </div>
                  </div>
                  {!disable && productStock > 0 && (
                    <div className="col-md-12 col-lg-12 col-12 text-center mt-10 mb-10">
                      <div>
                        <a className="shop-btn shop-btn-fill-right pro-add-btn" onClick={(e) => handleAddItemToCart(e)}>
                          Add
                        </a>
                      </div>
                    </div>
                  )}
                  {(disable || productStock < 1) && (
                    <div className="col-md-12 col-lg-12 col-12 mt-10 mb-10 ht-84">
                      <p className="color-red outstock pt-10 pb-10 text-center">Sold Out</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-md-12 col-lg-12 col-12 mt-10 mb-10 ht-84">
                  <p className="color-red outstock pt-5 pb-5 text-center">Sold Out</p>
                </div>
              )}
            </div>
            {/* <div className="text-center mt-10">
                     <Link to={`/product/${item.uniqueid}`} className="shop-combo-btn shop-btn-fill-right">
                       <i className="fa fa-shopping-cart"></i> Buy Now
                     </Link>
                   </div> */}
          </div>
        </div>
      </div>
      <div>
        <Modal show={show} onHide={handleClose} className="modal-width mt-50">
          <Modal.Header className="pt-15 pb-15" closeButton>
            <h2 className="ps-form__title font-size-24 mb-0">Choose Product Variant</h2>
          </Modal.Header>
          <Formik>
            {(formik) => {
              const { errors, touched, values } = formik;
              return (
                <Form>
                  <Modal.Body>
                    <div className="ps-form--review">
                      <div className="col-12">
                        <div className="row mt-20">
                          <div className="col-12">
                            <ProductOnCart
                              product={productItem}
                              varientobject={isVarientobject}
                              cartevent={"varientproduct"}
                              currencyrate={currencyrate}
                            />
                          </div>
                        </div>
                        <div className="row mt-20 mb-20">
                          <div className="col-12 pl-40">
                            {iskeyarray &&
                              iskeyarray.length > 0 &&
                              iskeyarray.map((item) => (
                                <div className="ps-product__variants">
                                  {item === "Colour" && (
                                    <ModuleDetailMaterial
                                      array={isColor}
                                      setname={setColorname}
                                      name="color"
                                      lable="Color"
                                    />
                                  )}
                                  {item === "Size" && (
                                    <ModuleDetailMaterial
                                      array={isSize}
                                      setname={setSizename}
                                      name="size"
                                      lable="Size"
                                    />
                                  )}
                                  {item === "Material" && (
                                    <ModuleDetailMaterial
                                      array={isMaterial}
                                      setname={setMaterialname}
                                      name="material"
                                      lable="Material"
                                    />
                                  )}
                                  {item === "Weight" && (
                                    <ModuleDetailMaterial
                                      array={isWeight}
                                      setname={setWeightname}
                                      name="weight"
                                      lable="Weight"
                                    />
                                  )}
                                  {item === "Flavour" && (
                                    <ModuleDetailMaterial
                                      array={isflavour}
                                      setname={setFlavourName}
                                      name="flavour"
                                      lable="Flavour"
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className={
                        isVarientobject.varientdisable === "Yes"
                          ? "ps-btn ps-btn--warning disabled disable-btn-color"
                          : "ps-btn ps-btn--warning "
                      }
                      onClick={(e) => {
                        if (isVarientobject.varientdisable !== "Yes") {
                          addToCart(e);
                        }
                      }}
                    >
                      Choose
                    </button>
                    <Button variant="secondary" className="ps-btn ps-btn--warning close-btn" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default connect((state) => state)(ComboProduct);
