import React, { useState } from "react";

const ModuleDetailSizes = ({ simple = false, sizeArray, setSizename }) => {
  const [size, setSize] = useState("");

  function handleSetActiveSize(payload) {
    setSize(payload);
    setSizename(payload);
  }

  const sizesView = sizeArray.map((item) => (
    <>
      <input type="radio" name="size" id={item} value={item} onClick={(e) => handleSetActiveSize(item)} />
      <label for={item} className="title-font">
        {item}
      </label>
    </>
  ));

  return (
    <figure className="ps-product__sizes">
      <figcaption className="ps-product__label font-size-16">Sizes:</figcaption>
      <div className="radio_container">{sizesView}</div>
    </figure>
  );
};

export default ModuleDetailSizes;
