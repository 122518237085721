import parse from "html-react-parser";
import React, { useEffect } from "react";

const ModuleProductDetailDescription = (product) => {
  let isOpen = false;
  useEffect(() => {
    if (document.getElementById("shortdescription")) {
      var text = document.getElementById("shortdescription").clientHeight;

      if (Number(text) >= 75) {
        document.getElementById("shortdescriptionbtn").style.display = "block";
      } else {
        document.getElementById("shortdescriptionbtn").style.display = "none";
      }
    }
  });

  /*
    To open review tab
  */
  const showMore = () => {
    const box = document.querySelector(".box");
    isOpen = !isOpen;
    isOpen ? box.classList.add("open") : box.classList.remove("open");
  };

  return (
    <div className="productshortdescription">
      {product.product.shortdescription && (
        <div className="box">
          <p className="text title-font shortdescription word-break-all" id="shortdescription">
            {parse(product.product.shortdescription)}
          </p>
          <a href="javascript:;" onClick={showMore} id="shortdescriptionbtn">
            <i className="arrow"></i>
          </a>
        </div>
      )}
    </div>
  );
};

export default ModuleProductDetailDescription;
