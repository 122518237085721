import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import user from "../../api/user";
import AddressList from "../address/AddressList";

const OrderAddress = ({ ecomerce }) => {
  const [address, setAddress] = useState([]);
  const [addressValues, setAddressValues] = useState([]);

  useEffect(() => {
    if (ecomerce.cartItems) {
      getAddressList("");
    }
  }, [ecomerce]);

  /*
    To address list by address id and set data in state variable
  */
  const getAddressList = async (addressid) => {
    var addressobject = new Object();
    if (addressid) {
      addressobject["addressid"] = addressid;
    }

    addressobject["isdefaultaddress"] = "1";
    const res = await user.getAddress(addressobject);
    if (res.data.status === 200) {
      setAddress(res.data.payload);
    }
  };

  return <AddressList address={address} addressevent={"orderaddress"} setAddressValues={() => setAddressValues()} />;
};
export default connect((state) => state)(OrderAddress);
