import React from "react";

import ModuleFooterAddress from "../../../components/shared/footers/modules/ModuleFooterAddress";
import ModuleFooterBottom from "../../../components/shared/footers/modules/ModuleFooterBottom";
import ModuleFooterContact from "../../../components/shared/footers/modules/ModuleFooterContact";
import WidgetFooterLinks from "../../../components/shared/widgets/footer/WidgetFooterLinks";
import FooterLinks from "../../../public/static/data/footer.json";

const FooterDefault = () => {
  return (
    <footer className="ps-footer ps-footer--1" id="footerdiv">
      <div className="container footer-padding">
        <div className="row">
          <div className="col-12">
            <div className="ps-footer__middle">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="row ps-footer__information">
                    <div className="col-12">
                      <ModuleFooterAddress />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="row ps-footer--1 ps-footer--address">
                    <div className="col-6 col-md-6">
                      <WidgetFooterLinks source={FooterLinks.information} />
                    </div>
                    <div className="col-6 col-md-6">
                      <WidgetFooterLinks source={FooterLinks.account} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="row ps-footer__information">
                    <div className="col-12">
                      <ModuleFooterContact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ModuleFooterBottom />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterDefault;
