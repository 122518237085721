/*
 * React template helpers
 * Author: Nouthemes
 * Developed: diaryforlife

 * */

export function calculateAmount(obj) {
  return Object.values(obj)
    .reduce(
      (acc, { quantity, price }) => acc + Number(quantity) * Number(price),
      0
    )
    .toFixed(2);
}

export function calculateCartQuantity(obj) {
  return Object.values(obj).reduce(
    (acc, { quantity }) => acc + Number(quantity),
    0
  );
}

export function caculateArrayQuantity(obj) {
  return Object.values(obj).reduce((acc) => acc + 1, 0);
}

export function calculateTotalOrderQuantity(obj) {
  return Object.values(obj).reduce(
    (acc, { quantity }) => acc + Number(quantity),
    0
  );
}
