import React, { useEffect } from "react";
import { connect } from "react-redux";

import ProductOnCart from "../../../components/elements/products/ProductOnCart";
import { currencyPrice } from "../../../hooks/currencyCalculation";
import NoDataFound from "../../common/NoDataFound";

const ModulEcomerceOrderSummary = ({ ecomerce, cartItems, handelcoupon, pecialPrice, currencyrate }) => {
  useEffect(() => {}, [ecomerce, cartItems, handelcoupon]);
  return (
    <>
      {cartItems.length > 0 ? (
        <table className="table ps-table ps-table--shopping-cart ps-table--responsive cartlist" id="cartlist">
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody className="carttable">
            {cartItems.map((item) => (
              <>
                <tr key={item.id}>
                  <td className="wishlist-td-productname text-left">
                    <ProductOnCart
                      product={item}
                      handelcoupon={handelcoupon}
                      pecialPrice={pecialPrice}
                      currencyrate={currencyrate}
                    />
                  </td>
                  <td className="text-center vertical-align-middle color-blue align-self-center quantity-td">
                    <span className="form-group--number">{item.quantity}</span>
                    {Number(item.stock) < Number(item.quantity) && (
                      <p className="color-red outstock text-center">Sold Out</p>
                    )}
                  </td>
                  <td className="text-center vertical-align-middle color-blue align-self-center order-space-mobile">
                    <strong className="text-center color-blue">
                      <span id={`productmain_price_${item.id}`}>
                        {Number(item.offerprice) > 0 ? (
                          <> {currencyPrice(Number(item.offerprice) * Number(item.quantity), currencyrate)}</>
                        ) : (
                          <> {currencyPrice(Number(item.price) * Number(item.quantity), currencyrate)}</>
                        )}
                      </span>
                    </strong>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      ) : (
        <NoDataFound component={"cart"} />
      )}
    </>
  );
};

export default connect((state) => state)(ModulEcomerceOrderSummary);
