import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ModuleDetailMeta from "../../../components/elements/detail/modules/ModuleDetailMeta";
import ModuleDetailShoppingActions from "../../../components/elements/detail/modules/ModuleDetailShoppingActions";
import ModuleProductDetailSharing from "../../../components/elements/detail/modules/ModuleProductDetailSharing";
import config from "../../../config/config.json";
import useGetProducts from "../../../hooks/useGetProducts";
import useProduct from "../../../hooks/useProduct";
import SimilarProduct from "../../../pages/product/SimilarProduct";
import { VarientKeyName, VarientName } from "../../../pages/product/VariantKey";
import ModuleComboProduct from "./modules/ModuleComboProduct";
import ModuleDetailMaterial from "./modules/ModuleDetailMaterial";
import ModuleDetailTabs from "./modules/ModuleDetailTabs";
import ModuleDetailThumbnail from "./modules/ModuleDetailThumbnail";
import ModuleDetailTopInformation from "./modules/ModuleDetailTopInformation";
import ModuleProductDetailDescription from "./modules/ModuleProductDetailDescription";

const DetailDefault = ({
  product,
  comboproduct,
  likeproduct,
  currencyrate,
  productview = "detail",
  productvariant,
}) => {
  const { price } = useProduct();
  const { id } = useParams();

  const [colorname, setColorname] = useState("");
  const [sizename, setSizename] = useState("");
  const [materialname, setMaterialname] = useState("");
  const [weightname, setWeightname] = useState("");
  const [flavourname, setFlavourName] = useState("");
  const [isColor, setColor] = useState([]);
  const [isSize, setSize] = useState([]);
  const [isMaterial, setMaterial] = useState([]);
  const [isWeight, setWeight] = useState([]);
  const [iskeyarray, setKeyArray] = useState([]);
  const [isflavour, setFlavour] = useState([]);
  const [isVariantflag, setVariantflag] = useState(false);

  const { productReview, getProductReviewById } = useGetProducts();

  const [isProductStatus, setProductStatus] = useState("out-stock");
  const [isProductStatusView, setProductStatusView] = useState(
    <p className="ps-product__log-status outstock">Sold Out</p>
  );

  let variantname = "";

  let color = [];
  let size = [];
  let material = [];
  let weight = [];
  let flavour = [];

  /*
    To set product variant array
  */
  const setVariantKey = () => {
    if (variantname === "") {
      if (productvariant && productvariant.length > 0) {
        setKeyArray(VarientKeyName(productvariant));
      }
    }
  };

  /*
    To make variant name by selected variant  
  */
  const setVariantName = () => {
    if (iskeyarray && iskeyarray.length > 0) {
      variantname = VarientName(iskeyarray, colorname, sizename, materialname, weightname, flavourname);
    }

    let result = "";
    var flag = false;
    if (variantname && variantname !== null && variantname !== "") {
      result = variantname.slice(0, -1);

      var j = 0;
      productvariant.map((item, index) => {
        if (item.variantname === result) {
          j = index;
          flag = true;
          setVariantflag(true);
        }
      });

      if (flag) {
        // UNCOMMENT IN NEXT RELEASE
        // product["productname"] = productvariant[j].varianttitle;
        // product["description"] = productvariant[j].description;
        // product["shortdescription"] = productvariant[j].shortdescription;
        product["price"] = productvariant[j].price;
        product["offerprice"] = productvariant[j].offerprice;
        product["offertype"] = productvariant[j].offertype;
        product["variantid"] = productvariant[j].id;
        product["stock"] = productvariant[j].stock;
        product["hsn"] = productvariant[j].hsn;
        product["productvarientimagelist"] = productvariant[j].productimagelist;
        product["alreadyincart"] = productvariant[j].alreadyincart;
      } else {
        product["stock"] = 0;
      }
      setStatus();
    }
  };

  useEffect(() => {
    setVariantKey();
    getProductReviewById(id);
    setStatus();
    setVarient();
    setVariantName();
  }, [id, productvariant, colorname, sizename, materialname, weightname, flavourname]);

  /*
    To set stock status 
  */
  const setStatus = () => {
    if (product && product.status === "unpublished") {
      setProductStatus("not-available");
      setProductStatusView(<p className="ps-product__log-status outstock">Not available</p>);
    } else if (product && Number(product.stock) > 0) {
      setProductStatus("in-stock");
      setProductStatusView(<p className="ps-product__log-status">in stock</p>);
    } else {
      setProductStatus("out-stock");
      setProductStatusView(<p className="ps-product__log-status outstock">Sold Out</p>);
    }
  };

  /*
    To set variant array for display
  */
  const setVarient = () => {
    if (productvariant && productvariant.length > 0) {
      productvariant.map((item) => {
        var variantjson = JSON.parse(item.variantjson);
        if (variantjson && variantjson !== null && variantjson !== "") {
          if (variantjson.Colour && variantjson.Colour !== null && variantjson.Colour !== "") {
            color.push(variantjson.Colour);
          }
          if (variantjson.Size && variantjson.Size !== null && variantjson.Size !== "") {
            size.push(variantjson.Size);
          }
          if (variantjson.Material && variantjson.Material !== null && variantjson.Material !== "") {
            material.push(variantjson.Material);
          }
          if (variantjson.Weight && variantjson.Weight !== null && variantjson.Weight !== "") {
            weight.push(variantjson.Weight);
          }
          if (variantjson.Flavour && variantjson.Flavour !== null && variantjson.Flavour !== "") {
            flavour.push(variantjson.Flavour);
          }
        }
      });

      if (color && color.length > 0) {
        const setData = new Set(color);
        color = Array.from(setData);
        setColor(color);
      }

      if (size && size.length > 0) {
        const setData = new Set(size);
        size = Array.from(setData);
        setSize(size);
      }

      if (material && material.length > 0) {
        const setData = new Set(material);
        material = Array.from(setData);
        setMaterial(material);
      }
      if (weight && weight.length > 0) {
        const setData = new Set(weight);
        weight = Array.from(setData);
        setWeight(weight);
      }
      if (flavour && flavour.length > 0) {
        const setData = new Set(flavour);
        flavour = Array.from(setData);
        setFlavour(flavour);
      }
    } else {
      setVariantflag(true);
    }
  };
  return (
    <>
      {product && (
        <>
          <div className="product--detail ps-product--detail mb-100">
            <div
              className={
                config.isslider === "false"
                  ? "ps-product__header product-padding"
                  : "ps-product__header product-padding image-gap"
              }
            >
              <ModuleDetailThumbnail product={product} productflag={isVariantflag} vertical={false} />
              <div className="ps-product__info">
                {isProductStatusView}
                <ModuleDetailTopInformation product={product} productReview={productReview} />
                {price(product, currencyrate, "productdetail")}
                <ModuleProductDetailDescription product={product} />
                <div className="mt-30">
                  {iskeyarray &&
                    iskeyarray.length > 0 &&
                    iskeyarray.map((item) => (
                      <div className="ps-product__variants">
                        {item === "Colour" && (
                          <ModuleDetailMaterial array={isColor} setname={setColorname} name="color" lable="Color" />
                        )}
                        {item === "Size" && (
                          <ModuleDetailMaterial array={isSize} setname={setSizename} name="size" lable="Size" />
                        )}
                        {item === "Material" && (
                          <ModuleDetailMaterial
                            array={isMaterial}
                            setname={setMaterialname}
                            name="material"
                            lable="Material"
                          />
                        )}
                        {item === "Weight" && (
                          <ModuleDetailMaterial array={isWeight} setname={setWeightname} name="weight" lable="Weight" />
                        )}
                        {item === "Flavour" && (
                          <ModuleDetailMaterial
                            array={isflavour}
                            setname={setFlavourName}
                            name="flavour"
                            lable="Flavour"
                          />
                        )}
                      </div>
                    ))}
                </div>
                {productview === "detail" && isProductStatus !== "out-stock" && isProductStatus !== "not-available" && (
                  <ModuleDetailShoppingActions
                    product={product}
                    productflag={isVariantflag}
                    productvariant={productvariant}
                  />
                )}
                {productview === "preview" && (
                  <ModuleDetailShoppingActions
                    product={product}
                    productflag={isVariantflag}
                    productvariant={productvariant}
                    productview={productview}
                  />
                )}
                <ModuleDetailMeta product={product} />
                <ModuleProductDetailSharing product={product} />
                <ModuleComboProduct comboproduct={comboproduct} currencyrate={currencyrate} />
              </div>
            </div>
            <div className="ps-product__content ">
              <ModuleDetailTabs product={product} productReview={productReview} productview={productview} />
            </div>
          </div>
          {productview === "detail" && likeproduct && likeproduct !== null && likeproduct.length > 0 && (
            <div className="ps-product__content mb-100">
              <SimilarProduct likeproduct={likeproduct} currencyrate={currencyrate} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DetailDefault;
