import React from "react";

const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
  return (
    <React.Fragment>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </React.Fragment>
  );
};
export default RadioButton;
