import axios from "axios";

import config from "../config/config.json";
import LocalstorageParam from "../pages/common/LocalstorageParam";

const headers = {
  Accept: "*/*",
};

/*
  To set local storage parameter in json
*/
var localparam = LocalstorageParam();

/*
   To get home data 
*/
const getHomeData = async () => {
  var data = new Object();
  if (localparam !== "" && localparam.decoded !== "" && localparam.decoded.userid) {
    data["userid"] = localparam.decoded.userid;
  }

  const url = config.apiUrl + config.apiurlname + "/home";
  const res = await axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return res;
};

export default {
  getHomeData,
};
