import React from "react";
import { Link } from "react-router-dom";

const WidgetFooterLinks = ({ source, classes = "" }) => {
  /*
    To view
  */
  const linksView = source.items.map((item, index) => (
    <li key={index}>
      <Link to={item.url} className="footer-font">
        {item.text}
      </Link>
    </li>
  ));
  return (
    <>
      <div className={`ps-footer--block widget--footer widget--footer-links ${classes}`}>
        <h5 className="ps-block__title">{source.title}</h5>
        <ul className="ps-block__list">{linksView}</ul>
      </div>
    </>
  );
};

export default WidgetFooterLinks;
